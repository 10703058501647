import { Button, Col } from "react-bootstrap";
import styled from "styled-components";

export const InputContainer = styled(Col)`
  width: 20%;
  display: grid;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin-right: 15px;
    }
  }
`;

export const CustomButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary.main};
  border: 1px solid ${({ theme }) => theme.colors.primary.main};
  border-radius: 0.5rem;
  align-self: center;
  margin-bottom: 1.7rem;
  width: 110px;
  height: 36px;
  font-weight: 600;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.hover};
  }
`;

export const FormContainer = styled.div`

`;

interface IInputTitle {
  desativado?: boolean;
}

export const InputTitle = styled.span<IInputTitle>`
  color: ${({ desativado }) => (desativado ? "#9f9f9f" : "")};
`;

export const InfoContainer = styled.div`
  width: 100%;
  background-color: #F5F5F5;
  display: flex;
  padding: 10px 20px;
  border-radius: 0.5rem;
  align-items: center;
`;

export const InfoSwitchContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;