import { formatarDinheiro } from "_utils/Money";
import { DropdownSelect, IOptions } from "components/DropdownSelect";
import { ContainerSelecionarBanco } from "../../styles";
import { IDadosBancosGerarCnab } from "../../Interfaces/IDadosBancosGerarCnab";

interface ITabelaSelecaoBancoManual {
  dadosBancos: IDadosBancosGerarCnab[];
  setBancoSelecionado: (banco: string | number) => void;
  bancoSelecionado: string | number;
  bancosOptions: IOptions[];
}

export function TabelaSelecaoBancoManual({
  dadosBancos,
  bancoSelecionado,
  setBancoSelecionado,
  bancosOptions,
}: ITabelaSelecaoBancoManual) {
  return (
    <div>
      <ContainerSelecionarBanco>
        <DropdownSelect
          options={bancosOptions}
          title="Banco:"
          selectedValue={bancoSelecionado}
          onSelect={setBancoSelecionado}
          backgroundColor="white"
        />
      </ContainerSelecionarBanco>
      <table width="100%">
        <thead>
          <td width="34%">
            <strong>Banco selecionado</strong>
          </td>
          <td width="33%">
            <strong>Qtd de débitos</strong>
          </td>
          <td width="33%">
            <strong>Valor</strong>
          </td>
        </thead>
        <tbody>
          <tr>
            <td>
              {bancoSelecionado
                ? bancosOptions
                    .filter((item) => item.value == bancoSelecionado)
                    .map((item) => item.label)[0] ?? "-"
                : "-"}
            </td>
            <td>
              {dadosBancos.reduce(
                (previous, current) => previous + current.quantidadeDebitos,
                0
              )}
            </td>
            <td>
              {formatarDinheiro(
                (dadosBancos.reduce(
                  (previous, current) => previous + current.montanteDebitos,
                  0
                ) ?? 0) / 100
              )}
            </td>
          </tr>
        </tbody>
        <tr>
          <td>
            <strong>Totais</strong>
          </td>
          <td>
            <strong>
              {dadosBancos.reduce(
                (previous, current) => previous + current.quantidadeDebitos,
                0
              )}
            </strong>
          </td>
          <td>
            <strong>
              {formatarDinheiro(
                (dadosBancos.reduce(
                  (previous, current) => previous + current.montanteDebitos,
                  0
                ) ?? 0) / 100
              )}
            </strong>
          </td>
        </tr>
      </table>
    </div>
  );
}
