import { FaDownload, FaEye, FaInfoCircle } from "react-icons/fa";
import {
  ButtonIcon,
  Container,
  IconeLink,
  TooltipBodyContainer,
} from "./style";
import { StatusExtratoVeicular } from "../StatusConsultaExtratoVeicular/style";
import { useDetalhesExtratoVeicular } from "../../hooks/useDetalhesExtratoVeicular";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PdfExtratoVeicular } from "../../../ExtratoVeicularDetalhado/containers/PdfExtratoVeicular";
import { LoadingCirculo } from "components/LoadingCirculo";
import { LoadingScreen } from "containers/Page/style";
import { useContext, useEffect, useState } from "react";
import { ModalResumoLote } from "./containers/ModalResumoLote";
import { Tooltip } from "components/Tooltip";
import { ContextPermissao } from "hooks/ContextPermissao";

interface Props {
  idLoteExtratoVeicular: number;
  idExtratoVeicular: number;
  statusExtratoVeicular: string;
  resumoLote?: boolean;
  mensagemErro?: string;
}

export function AcoesExtratoVeicular({
  idExtratoVeicular,
  statusExtratoVeicular,
  idLoteExtratoVeicular,
  resumoLote,
  mensagemErro,
}: Readonly<Props>) {
  const [idLoteResumo, setIdLoteResumo] = useState<number | null>(null);
  const {
    handleExportarPdfExtratoVeicular,
    renderPdf,
    dadosExtratoVeicular,
    loadingDownloadPdf,
  } = useDetalhesExtratoVeicular();
  const { verificarUsuarioAwLw } = useContext(ContextPermissao);

  const statusNaoDesativarDetalhes: string[] = [
    StatusExtratoVeicular.CONSULTANDO,
    StatusExtratoVeicular.FALHA,
    StatusExtratoVeicular.DADOS_INCONSISTENTES,
  ];

  const bottomTooltipReconsultar = -17.5;
  const rightTooltipReconsultar = -350;
  const widthTooltipReconsultar = 800;
  const bottomTooltipReconsultarErro = -2.1;
  const rightTooltipReconsultarErro = -25;
  const widthTooltipReconsultarErro = 210;

  return (
    <Container>
      {resumoLote && (
        <Tooltip
          bottom={bottomTooltipReconsultar}
          right={rightTooltipReconsultar}
          width={widthTooltipReconsultar}
          positionRelative={false}
          trigger={
            <ButtonIcon
              onMouseEnter={() => setIdLoteResumo(idLoteExtratoVeicular)}
            >
              <FaInfoCircle />
            </ButtonIcon>
          }
        >
          <ModalResumoLote idLoteExtratoVeicular={idLoteResumo} />
        </Tooltip>
      )}
      <ButtonIcon
        title="Exportar"
        disabled={statusNaoDesativarDetalhes.includes(
          `${statusExtratoVeicular}`
        )}
        onClick={() =>
          handleExportarPdfExtratoVeicular(
            idExtratoVeicular,
            idLoteExtratoVeicular
          )
        }
      >
        <FaDownload />
      </ButtonIcon>
      {renderPdf && (
        <PDFDownloadLink
          style={{ display: "none" }}
          id={`downloadPdf${idExtratoVeicular}`}
          document={<PdfExtratoVeicular dadosPDF={dadosExtratoVeicular} />}
          fileName={`Extrato Veicular - ${
            dadosExtratoVeicular.dadosVeiculo[0].placa ?? "-"
          }`}
        />
      )}
      {loadingDownloadPdf && (
        <LoadingScreen>
          <LoadingCirculo mostrar={loadingDownloadPdf} />
        </LoadingScreen>
      )}
      {statusNaoDesativarDetalhes.includes(`${statusExtratoVeicular}`) ? (
        <ButtonIcon disabled={true}>
          <FaEye />
        </ButtonIcon>
      ) : (
        <IconeLink
          title="Visualizar"
          to={`/veiculos/extrato/consultas/${idLoteExtratoVeicular}/detalhes/${idExtratoVeicular}`}
        >
          <FaEye />
        </IconeLink>
      )}
    </Container>
  );
}
