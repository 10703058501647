import { converterDataHoraBr } from "_utils/Data";
import { IconeStatusCNAB } from "../../../IconeStatusCNAB";
import { CnabVinculado } from "../../Interfaces/DadosInfoCnab";
import {
  ContainerTabelaCnabsVinculados,
  TituloInfoCnabsVinculados,
} from "../../style";
import { handleStatusCnab } from "../../../../Constants/handleStatusCnab";

interface Props {
  dados: CnabVinculado[];
}
export function InfoCnabsVinculados({ dados }: Props) {
  return (
    <>
      <TituloInfoCnabsVinculados>CNABs vinculados</TituloInfoCnabsVinculados>
      <ContainerTabelaCnabsVinculados>
        <table width="100%">
          <thead>
            <tr>
              <td width="29%">
                <strong>CNAB</strong>
              </td>
              <td width="18%">
                <strong>Banco CNAB</strong>
              </td>
              <td width="23%">
                <strong>Data/hora de envio</strong>
              </td>
              <td width="30%">
                <strong>Usuário solicitante</strong>
              </td>
            </tr>
          </thead>
          <tbody>
            {dados
              .filter((cnab, index) => index)
              .map((cnab) => {
                return (
                  <tr>
                    <td style={{ paddingTop: "10px" }}>
                      <IconeStatusCNAB
                        tamanho={"8px"}
                        status={handleStatusCnab(cnab.status)}
                      />
                      {cnab.nome}
                    </td>
                    <td style={{ paddingTop: "10px" }}>
                      {cnab.nomeBanco ?? "-"}
                    </td>
                    <td style={{ paddingTop: "10px" }}>
                      {cnab.dataHora
                        ? converterDataHoraBr(new Date(cnab.dataHora))
                        : "-"}
                    </td>
                    <td style={{ paddingTop: "10px" }}>
                      {cnab.emailUsuarioSolicitante}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </ContainerTabelaCnabsVinculados>
    </>
  );
}
