export const OptionsBancos = [
  {
    label: "Banco do Brasil",
    value: "001",
  },
  {
    label: "Santander",
    value: "033",
  },
  {
    label: "Itaú",
    value: "341",
  },
  {
    label: "Bradesco",
    value: "237",
  },
  {
    label: "Caixa Econômica Federal",
    value: "104",
  },
];
