import styled from "styled-components";

interface ExportButtonProps {
  variant: "primary" | "secondary";
  active: boolean;
}

const BtnContainer = styled.div<ExportButtonProps>`
  background-color: ${({ theme, variant, active }) => (variant === "primary" ? theme.colors.primary.main : active ? theme.colors.white : theme.colors.neutral.neutral10 )};
  width: 200px;
  padding: 0.4rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  border-radius: 0.5rem;
  color: ${({ theme, variant, active }) => (variant === "primary" ? theme.colors.white : active ? theme.colors.primary.main : theme.colors.neutral.neutral30 )};
  cursor: ${({ active }) => (active ? 'pointer' : 'not-allowed')};
  font-weight: bold;
  ${({ variant, theme, active }) => variant === "secondary" && active ? `border: 1px solid ${theme.colors.primary.main}` : `border: 1px solid ${theme.colors.neutral.neutral50}`};

  &:hover {
    color: ${({ theme, variant, active }) =>
      variant === "primary" ? theme.colors.neutral.neutral10 : active ? theme.colors.primary.hover : ''};
    ${({ variant, theme, active }) => variant === "secondary" &&  active && `border: 1px solid ${theme.colors.primary.hover}`};
    transition: all ease-in-out 0.25s;
  }
`;

const ContainerBtnCNAB = styled.div`
  display: flex;
  justify-content: end;
  gap: 1rem;
  width: 100%;
`;

export { BtnContainer, ContainerBtnCNAB };

