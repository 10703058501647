import { Informacao } from "components/Informacao";
import { ContainerAvisoHaVinculoArquivo } from "../../style";

export function AvisoHaVinculoArquivo() {
  return (
    <ContainerAvisoHaVinculoArquivo>
      <Informacao
        mensagem={
          <span>
            Um ou mais débitos selecionados já estão vinculados em outros
            arquivos CNAB <strong>podendo gerar pagamentos duplicados</strong>.
            Deseja prosseguir com a solicitação?
          </span>
        }
        showBotaoFechar={false}
        showLabel={false}
        type="atencao"
        className="informacao"
        style={{ boxShadow: "none", margin: "10px auto", width: "100%" }}
        fontSize="14px"
      />
    </ContainerAvisoHaVinculoArquivo>
  );
}
