import { ModalCustom } from "components/ModalCustom";
import { DadosVisualizarCnabs } from "./Interfaces/DadosVisualizarCnabs";
import { InfosGerais } from "./Containers/InfosGerais";
import { InfosArquivoCnab } from "./Containers/InfosArquivoCnab";
import { ContainerListaArquivos } from "./style";
import { DivPesquisar } from "./Containers/DivPesquisar";

interface Props {
  handleClose: () => void;
  show: boolean;
  dados: DadosVisualizarCnabs;
  reenviar: (id: number, nome: string) => void;
  reprocessar: (id: number) => void;
  onChange: (valor: string) => void;
}

export function ModalVisualizarCNABs({
  handleClose,
  show,
  dados,
  reenviar,
  reprocessar,
  onChange,
}: Readonly<Props>) {
  return (
    <ModalCustom
      title={"Exportações CNAB"}
      size="xl"
      handleClose={handleClose}
      show={show}
      centered
    >
      <InfosGerais dados={dados} />
      <DivPesquisar onChange={onChange} />
      <ContainerListaArquivos>
        {!!dados.cnabsFormatados.length ? (
          dados.cnabsFormatados.map((arquivo) => {
            return (
              <InfosArquivoCnab
                dados={arquivo}
                reenviar={reenviar}
                reprocessar={reprocessar}
              />
            );
          })
        ) : (
          <div style={{ textAlign: "center" }}>
            Nenhum arquivo encontrado para esta pesquisa
          </div>
        )}
      </ContainerListaArquivos>
    </ModalCustom>
  );
}
