import {
  DivPesquisarContainer,
  DivPesquisarInputContainer,
  DivPesquisarTitulo,
} from "../../style";
import { FaSearch } from "react-icons/fa";

interface Props {
  onChange: (valor: string) => void;
}

export function DivPesquisar({ onChange }: Props) {
  return (
    <DivPesquisarContainer>
      <DivPesquisarTitulo>Arquivos gerados</DivPesquisarTitulo>
      <DivPesquisarInputContainer>
        <FaSearch />
        <input
          type="text"
          placeholder="Arquivo CNAB ou placa"
          onChange={(e) => onChange(e.target.value)}
        />
      </DivPesquisarInputContainer>
    </DivPesquisarContainer>
  );
}
