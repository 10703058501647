import { ModalCustom } from "components/ModalCustom";
import { HeaderModal } from "./containers/HeaderModal";
import { useCallback, useContext, useEffect, useState } from "react";
import { BodyIndividual } from "./containers/BodyIndividual";
import { BodyLote } from "./containers/BodyLote";
import { IOptions } from "components/DropdownSelect";
import { Controller } from "react-hook-form";
import { SelecionarEmpresaConsultaVeicular } from "./containers/SelecionarEmpresaConsultaVeicular";
import { PrimaryButton } from "components/Button/style";
import { FaDownload } from "react-icons/fa";
import { ButtonsContainer } from "./style";
import { loteSchema } from "./validators/loteSchema";
import { individualSchema } from "./validators/individualSchema";
import { Form } from "react-bootstrap";
import {
  ISolicitarConsultaExtratoVeicularLote,
  useSolicitarExtratoVeicular,
} from "../../hooks/useExtratoVeicular";
import { LoadingCirculo } from "components/LoadingCirculo";
import { UploadContext } from "contexts/UploadContext";
import { useSocket } from "hooks/useSocket";
import { ContextPermissao } from "hooks/ContextPermissao";
import { CobrarExtratoVeicular } from "features/extrato-veicular/enuns/CobrarExtratoVeicular.enum";
import { getEmpresa } from "_services/auth";
import { useFinanceiro } from "hooks/useFinanceiro";
import { toastErro } from "components/Toast";
import { TipoReconsulta } from "contexts/UploadContext/enuns/tipoReconsulta.enum";
import { Informacao } from "components/Informacao";
import { optionsAdicionais } from "./constants/optionsAdicionais";
import { tiposConsulta } from "./constants/tiposConsulta";
import { individualSchemaAwLw } from "./validators/individualSchemaAwLw";

interface Props {
  handleClose: () => void;
  show: boolean;
  agrupamentoOptions: IOptions[];
  combosConsulta: IOptions[];
  listarConsultas: (filtros?: any) => void;
  showConfirmacao: () => void;
  setIdConsulta: (idConsulta: number) => void;
}

export function ModalSolicitarConsultaExtratoVeicular({
  handleClose,
  show,
  combosConsulta,
  agrupamentoOptions,
  listarConsultas,
  setIdConsulta,
  showConfirmacao,
}: Readonly<Props>) {
  const [body, setBody] = useState<"LOTE" | "INDIVIDUAL">("INDIVIDUAL");
  const [cobranca, setCobranca] = useState<"SIM" | "NAO" | null>(null);
  const [combosConsultaHabilitados, setCombosConsultaHabilitados] = useState<
    IOptions[]
  >([]);
  const [adicionaisHabilitados, setAdicionaisHabilitados] = useState<
    IMultiselectOptions[]
  >([]);
  const {
    adicionarArquivo,
    atualizarProgressoUpload,
    showBox,
    toggleShowBox,
    setErrosUpload,
    setSucessoUpload,
    setPlanilhaCorrompida,
    setAvisosUpload,
    setAviso,
    setShowModalAvisos,
  } = useContext(UploadContext);
  const { socket } = useSocket();
  const { verificarUsuarioAwLw } = useContext(ContextPermissao);
  const {
    configServicos,
    setConfigServicos,
    listarConfigServicosPorIdCliente,
  } = useFinanceiro();
  const schemas = {
    LOTE: loteSchema,
    INDIVIDUAL: verificarUsuarioAwLw()
      ? individualSchemaAwLw
      : individualSchema,
  };

  const {
    form: {
      control,
      handleSubmit,
      formState: { errors, isSubmitted },
      reset,
      setValue,
    },
    solicitarLote,
    solicitarUnitaria,
    loading,
  } = useSolicitarExtratoVeicular(schemas[body]);

  function handleBodyOnClose() {
    handleClose();
    setBody("INDIVIDUAL");
    setCobranca(null);
    setConfigServicos([]);
  }

  async function handleSubmitLote(
    dados: ISolicitarConsultaExtratoVeicularLote
  ) {
    socket?.on(`${dados.nomeArquivo}:corrompida`, () => {
      atualizarProgressoUpload({
        nomeArquivo: `${dados.nomeArquivo}`,
        progressoAtual: 100,
        temErro: true,
      });
      setPlanilhaCorrompida(dados.nomeArquivo);
    });

    socket?.on(`progresso_validacao:${dados.nomeArquivo}`, (data) => {
      atualizarProgressoUpload({
        nomeArquivo: `${dados.nomeArquivo}`,
        progressoAtual: ((data.atual / data.total) * 100) / 2,
        temErro: false,
      });
    });

    socket?.on(`progresso_insercao:${dados.nomeArquivo}`, (data) => {
      atualizarProgressoUpload({
        nomeArquivo: `${dados.nomeArquivo}`,
        progressoAtual: 50 + ((data.atual / data.total) * 100) / 2,
        temErro: false,
      });
    });

    socket?.on(`${dados.nomeArquivo}:concluida`, (data) => {
      setSucessoUpload(
        dados.nomeArquivo,
        `/veiculos/extrato/consultas/${data.idSolicitacao}`
      );
      atualizarProgressoUpload({
        nomeArquivo: dados.nomeArquivo,
        progressoAtual: 100,
        temErro: false,
      });
    });

    socket?.on(`${dados.nomeArquivo}:invalida`, (data) => {
      setErrosUpload(dados.nomeArquivo, data);
      atualizarProgressoUpload({
        nomeArquivo: `${dados.nomeArquivo}`,
        progressoAtual: 100,
        temErro: true,
      });
    });

    socket?.on(`${dados.nomeArquivo}:concluidaParcial`, (data) => {
      setAvisosUpload(dados.nomeArquivo, {
        dados: data.dados,
        tokenConsultarPlacas: data.tokenConsultarPlacas,
        strategy: TipoReconsulta.EXTRATO_VEICULAR,
      });
      setAviso("parcial");
      atualizarProgressoUpload({
        nomeArquivo: dados.nomeArquivo,
        progressoAtual: 100,
        temErro: false,
        temAviso: true,
      });
    });

    socket?.on(`${dados.nomeArquivo}:falhaParcial`, (data) => {
      setAvisosUpload(dados.nomeArquivo, {
        dados: data.dados,
        tokenConsultarPlacas: data.tokenConsultarPlacas,
        strategy: TipoReconsulta.EXTRATO_VEICULAR,
      });
      setAviso("total");
      atualizarProgressoUpload({
        nomeArquivo: dados.nomeArquivo,
        progressoAtual: 100,
        temErro: false,
        temAviso: true,
      });
    });

    const { hasErro, data } = await solicitarLote(dados);

    if (hasErro) {
      toastErro(data.message);
      return;
    }

    handleBodyOnClose();
    handleResetForm();
    adicionarArquivo([
      {
        nomeArquivo: `${dados.nomeArquivo}`,
        progressoUpload: 0,
        temErro: false,
        uploadFinalizado: false,
      },
    ]);

    if (!showBox) {
      toggleShowBox();
    }
  }

  async function handleSubmitUnitaria(dados: any) {
    setAviso("total");
    const { data, hasErro } = await solicitarUnitaria(dados);

    if (hasErro) {
      if (data?.error?.token) {
        setAvisosUpload("unitario", {
          tokenConsultarPlacas: data.error.token,
          strategy: TipoReconsulta.EXTRATO_VEICULAR,
          dados: {
            ...data.body,
            jaConsultados: [
              {
                uf: data.body.veiculo.uf,
                placa: data.body.veiculo.placa,
                chassi: data.body.veiculo.chassi,
                renavam: data.body.veiculo.renavam,
              },
            ],
          },
        });
        setShowModalAvisos(true);
        handleBodyOnClose();
        handleResetForm();
        return;
      }
      toastErro(data.message);
      return;
    }

    handleBodyOnClose();
    handleResetForm();
    handleShowModalConfirmacao(data.idSolicitacao);
    listarConsultas();
  }

  function handleShowModalConfirmacao(idConsulta: number) {
    showConfirmacao();
    setIdConsulta(idConsulta);
  }

  const handleResetForm = useCallback(() => {
    reset({
      tipoConsulta: null,
      placa: null,
      renavam: null,
      chassi: null,
      empresa: null,
      cobrar: null,
      motivoIsencao: null,
      adicionais: null,
      uf: null,
    });
  }, [reset]);

  function handleChangeEmpresa(idEmpresa: number | string) {
    if (idEmpresa) {
      listarConfigServicosPorIdCliente(idEmpresa);
      return;
    }
    setConfigServicos([]);
  }

  useEffect(() => {
    if (!configServicos || !configServicos.length) {
      setCombosConsultaHabilitados([]);
      setAdicionaisHabilitados([]);
      setValue("tipoConsulta", "");
      return;
    }

    const combosHabilitados = combosConsulta.filter((item) => {
      return configServicos.some((servico) =>
        servico.descricao.includes(`${item.label}`)
      );
    });

    const optionsAdicionaisHabilitados = optionsAdicionais.filter((option) =>
      combosHabilitados.some((combo) => `${combo.label}`.includes(option.label))
    );

    setCombosConsultaHabilitados(
      tiposConsulta
        .filter((tipo) =>
          tipo.adicionais.every((adicional) =>
            optionsAdicionaisHabilitados.some(
              (option) => option.label === adicional
            )
          )
        )
        .map((tiposConsulta) => {
          return {
            label: tiposConsulta.descricao,
            value: tiposConsulta.descricao,
          };
        })
    );

    setAdicionaisHabilitados(optionsAdicionaisHabilitados);
    setValue("tipoConsulta", tiposConsulta[0].descricao);
  }, [configServicos, combosConsulta]);

  const handleChangeAdicionais = (adicional: string[]) => {
    if (!adicional.length) {
      setValue("tipoConsulta", tiposConsulta[0].descricao);
      return;
    }
    const tiposConsultaPossiveis = combosConsultaHabilitados.filter((combo) =>
      adicional.every((add) => `${combo.label}`.includes(add))
    );

    if (tiposConsultaPossiveis && tiposConsultaPossiveis.length) {
      setValue("tipoConsulta", tiposConsultaPossiveis[0].value);
      return;
    }
    setValue("tipoConsulta", "");
  };

  const bodyModal = {
    LOTE: (
      <BodyLote
        combosConsulta={combosConsultaHabilitados}
        errors={errors}
        controller={Controller}
        control={control}
        isSubmitted={isSubmitted}
        adicionaisHabilitados={adicionaisHabilitados}
        handleChangeAdicionais={handleChangeAdicionais}
      />
    ),
    INDIVIDUAL: (
      <BodyIndividual
        combosConsulta={combosConsultaHabilitados}
        errors={errors}
        controller={Controller}
        control={control}
        isSubmitted={isSubmitted}
        adicionaisHabilitados={adicionaisHabilitados}
        handleChangeAdicionais={handleChangeAdicionais}
      />
    ),
  };

  useEffect(() => {
    handleResetForm();
  }, [body, reset, show, handleResetForm]);

  function baixarModeloConsultaExtratoVeicular() {
    const link = document.createElement("a");
    link.download = "Modelo_Consultar_Extrato_Veicular.xlsx";
    link.href = "/files/Modelo_Consultar_Extrato_Veicular.xlsx";
    link.click();
  }

  function gerarNumeroAleatorio() {
    return Math.floor(Math.random() * 10000000);
  }

  return (
    <ModalCustom
      handleClose={() => {
        handleClose();
        setBody("INDIVIDUAL");
        setCobranca(null);
        reset();
        setConfigServicos([]);
      }}
      show={show}
      centered
      size={"xl"}
      title="Consultar veículo"
    >
      {loading ? (
        <LoadingCirculo mostrar={loading} />
      ) : (
        <>
          <HeaderModal
            handleChange={(body) => {
              setBody(body);
              setCobranca(null);
            }}
          />
          <Form
            onSubmit={handleSubmit((dados) => {
              if (!verificarUsuarioAwLw()) {
                dados.cobrar = CobrarExtratoVeicular.SIM;
                dados.empresa = getEmpresa();
              }
              if (body === "LOTE") {
                return handleSubmitLote({
                  arquivo: dados.arquivo,
                  cobrar: dados.cobrar,
                  empresa: dados.empresa,
                  tipoConsulta: dados.tipoConsulta,
                  motivoIsencao: dados.motivoIsencao,
                  nomeArquivo: `${
                    dados.arquivo.name
                  }:${gerarNumeroAleatorio()}`,
                });
              }

              return handleSubmitUnitaria(dados);
            })}
          >
            <span>
              Selecione a empresa para a qual deseja realizar a consulta.
            </span>
            <SelecionarEmpresaConsultaVeicular
              agrupamentoOptions={agrupamentoOptions}
              control={control}
              controller={Controller}
              errors={errors}
              cobranca={cobranca}
              setCobranca={setCobranca}
              onChangeEmpresa={handleChangeEmpresa}
            />
            <div style={{ marginTop: cobranca === "NAO" ? "20px" : "0" }}>
              Preencha as informações abaixo para consultar o extrato do veículo
            </div>
            {bodyModal[body]}
            {!!adicionaisHabilitados.length && (
              <Informacao
                mensagem="Caso nenhuma opção de Adicional seja selecionada, será realizada a consulta padrão"
                type="atencao"
                showBotaoFechar={false}
                showLabel={false}
                style={{ boxShadow: "none" }}
              />
            )}
            <ButtonsContainer>
              {body === "LOTE" && (
                <PrimaryButton
                  onClick={baixarModeloConsultaExtratoVeicular}
                  outline
                  variante="primary"
                  type="button"
                >
                  <FaDownload />
                  Baixar modelo
                </PrimaryButton>
              )}
              <PrimaryButton>Consultar</PrimaryButton>
            </ButtonsContainer>
          </Form>
        </>
      )}
    </ModalCustom>
  );
}
