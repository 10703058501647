import { PrimaryButton } from "components/Button/style";
import { ButtonHTMLAttributes } from "react";
import { Spinner } from "react-bootstrap";

interface ButtonCustonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  disabled?: boolean;
  variant?: "primary" | "black" | undefined;
  outline?: boolean;
}
function ButtonCuston({
  children,
  variant = "primary",
  outline,
  loading,
  disabled,
  ...props
}: ButtonCustonProps) {
  return (
    <>
      <PrimaryButton
        {...props}
        variante={variant}
        disabled={disabled || loading}
        outline={outline}
      >
        {loading && (
          <>
            <Spinner
              animation="border"
              size="sm"
              style={{ marginRight: "5px" }}
            />{" "}
          </>
        )}
        {children}
      </PrimaryButton>
    </>
  );
}
export { ButtonCuston };

/*
// ) : (
      //   <PrimaryButton {...props} variante={variant} disabled={disabled} >
      //     {children}
      //   </PrimaryButton>
      // )}
*/
