export const ufs = [
  { abreviacao: "AC", nome: "Acre" },
  { abreviacao: "AL", nome: "Alagoas" },
  { abreviacao: "AM", nome: "Amazonas" },
  { abreviacao: "AP", nome: "Amapá" },
  { abreviacao: "BA", nome: "Bahia" },
  { abreviacao: "CE", nome: "Ceará" },
  { abreviacao: "DF", nome: "Distrito Federal" },
  { abreviacao: "ES", nome: "Espírito Santo" },
  { abreviacao: "GO", nome: "Goiás" },
  { abreviacao: "MA", nome: "Maranhão" },
  { abreviacao: "MG", nome: "Minas Gerais" },
  { abreviacao: "MS", nome: "Mato Grosso do Sul" },
  { abreviacao: "MT", nome: "Mato Grosso" },
  { abreviacao: "PA", nome: "Pará" },
  { abreviacao: "PB", nome: "Paraíba" },
  { abreviacao: "PE", nome: "Pernambuco" },
  { abreviacao: "PI", nome: "Piauí" },
  { abreviacao: "PR", nome: "Paraná" },
  { abreviacao: "RJ", nome: "Rio de Janeiro" },
  { abreviacao: "RN", nome: "Rio Grande do Norte" },
  { abreviacao: "RO", nome: "Rondônia" },
  { abreviacao: "RR", nome: "Roraima" },
  { abreviacao: "RS", nome: "Rio Grande do Sul" },
  { abreviacao: "SC", nome: "Santa Catarina" },
  { abreviacao: "SE", nome: "Sergipe" },
  { abreviacao: "SP", nome: "São Paulo" },
  { abreviacao: "TO", nome: "Tocantins" },
];
