import { Form, Row } from "react-bootstrap";
import { FaCheckCircle, FaTrash } from "react-icons/fa";
import styled from "styled-components";

export const ModalTituloPrincipal = styled.p`
  color: #7F7F7F;
  font-size: 16px;
  line-height: 16px;
  font-family: 'Roboto';
`;

export const ModalContainerRadio = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const ModalContainerRadioLabel = styled.label`
  color: #4D4D4D;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Roboto';
`;

export const ModalContainerInfo = styled.div`
  display: flex;
  width: 97.5%;
  background-color: #F5F5F5;
  border-radius: 10px;
  padding: 16px;
  gap: 16px;
  margin-bottom: 20px;
`;

export const ModalContainerInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const ModalContainerInfoItemTitulo = styled.span`
  color: #1A1A1A;
  font-size: 14px;
  font-weight: 400;
`;

export const ModalContainerInfoItemValor = styled.span`
  color: #1A1A1A;
  font-size: 14px;
  font-weight: 600;
`;

export const ModalContainerInfoRow = styled(Row)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ModalDatePickerFormLabel = styled(Form.Label)`
  color: #1A1A1A;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Roboto';
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface ConfirmarProps {
  ativo: boolean;
}

export const ModalFooterConfirmar = styled.div<ConfirmarProps>`
  padding: 8px 16px;
  background-color: ${({ ativo }) => (ativo ? '#333333' : '#7F7F7F')};
  gap: 4px;
  border-radius: 5px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  margin-left: 8px;
`;

export const ModalFooterCancelar = styled.div`
  border: 1px solid #333333;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  gap: 4px;
  radius: 5px;
  color: #333333;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Roboto';
`;