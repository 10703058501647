import { yupResolver } from "@hookform/resolvers/yup";
import { CobrarExtratoVeicular } from "features/extrato-veicular/enuns/CobrarExtratoVeicular.enum";
import { useEmpresas } from "hooks/useEmpresas";
import { useRequest } from "hooks/useRequest";
import { useForm } from "react-hook-form";
import { individualSchema } from "../../containers/ModalSolicitarConsultaExtratoVeicular/validators/individualSchema";

export interface ISolicitarConsultaExtratoVeicularUnitaria {
  tipoConsulta: string;
  placa: string;
  renavam: string;
  chassi: string;
  uf: string;
  empresa: string;
  cobrar: CobrarExtratoVeicular;
  motivoIsencao?: string;
  adicionais?: string[];
}

interface IDadosVeiculo {
  placa: string | null;
  chassi?: string | null;
  renavam: string | null;
  uf?: NullableString;
}

export interface ISolicitarConsultaExtratoVeicularLote {
  tipoConsulta: string;
  arquivo: File;
  nomeArquivo: string;
  empresa: string;
  cobrar: CobrarExtratoVeicular;
  motivoIsencao?: string;
  adicionais?: string[];
}

interface IBodySolicitacaoUnitaria {
  veiculo: IDadosVeiculo;
  tipoConsulta: string | null;
  idCliente: string | null;
  nomeCliente: string | null;
  cobrar: string | null;
  motivoIsencao?: string | null;
  adicionais?: string | null;
}

interface IReconsultarPlacasExtratoVeicular {
  dados: {
    jaConsultados: IDadosVeiculo[];
    uf: string | null;
    tipoConsulta: string | null;
    idCliente: string | null;
    nomeCliente: string | null;
    cobrar: string | null;
    motivoIsencao?: string | null;
  };
  tokenConsultarPlacas: string;
}

interface IBodySolicitarEmLote {
  veiculos: IDadosVeiculo[];
  uf: string | null;
  tipoConsulta: string | null;
  idCliente: string | null;
  nomeCliente: string | null;
  cobrar: string | null;
  motivoIsencao?: string | null;
  tokenConsultarPlacas: string;
}

export function useSolicitarExtratoVeicular(
  schema: any = individualSchema,
  buscarEmpresas = true
) {
  const { post, loading } = useRequest("consulta");
  const { buscarNomeEmpresa } = useEmpresas(buscarEmpresas);

  async function solicitarLote(dados: ISolicitarConsultaExtratoVeicularLote) {
    const nomeEmpresa = buscarNomeEmpresa(dados.empresa);

    const formData = new FormData();
    formData.append("arquivo", dados.arquivo);
    formData.append("nomeArquivo", dados.nomeArquivo);
    formData.append("tipoConsulta", dados.tipoConsulta);
    formData.append("cobrar", dados.cobrar);
    formData.append("idCliente", dados.empresa);
    formData.append("nomeCliente", nomeEmpresa);
    formData.append("motivoIsencao", dados.motivoIsencao!);

    if (dados.adicionais) {
      formData.append("adicionais", dados.adicionais.join(","));
    }

    return post<FormData, any>("/veiculo/extrato/planilha", formData);
  }

  async function solicitarUnitaria(
    dados: ISolicitarConsultaExtratoVeicularUnitaria
  ) {
    const nomeEmpresa = buscarNomeEmpresa(dados.empresa);
    const body: IBodySolicitacaoUnitaria = {
      veiculo: {
        placa: dados.placa,
        renavam: dados.renavam,
        uf: dados.uf,
      },
      tipoConsulta: dados.tipoConsulta,
      idCliente: dados.empresa,
      nomeCliente: nomeEmpresa,
      cobrar: dados.cobrar,
      motivoIsencao: dados.motivoIsencao,
    };

    if (dados.adicionais) {
      body.adicionais = dados.adicionais.join(",")
    }

    const { data, hasErro } = await post<IBodySolicitacaoUnitaria, any>(
      "/veiculo/extrato",
      body,
      {},
      false
    );

    return {
      hasErro,
      data: {
        ...data,
        body,
      },
    };
  }

  async function reconsultarPlacasExtratoVeicular({
    dados,
    tokenConsultarPlacas,
  }: IReconsultarPlacasExtratoVeicular) {
    const { jaConsultados, ...rest } = dados;

    const response = await post<IBodySolicitarEmLote, any>(
      `/veiculo/extrato/lote`,
      {
        veiculos: jaConsultados,
        ...rest,
        uf: jaConsultados[0].uf!,
        tokenConsultarPlacas,
      }
    );

    return {
      response,
      baseUrlRedirect: `/veiculos/extrato/consultas`,
    };
  }

  const form = useForm({
    resolver: yupResolver(schema),
  });

  return {
    form,
    solicitarLote,
    solicitarUnitaria,
    loading,
    reconsultarPlacasExtratoVeicular,
  };
}
