import { Page } from "containers/Page";
import { CompartimentoUpload } from "./containers/CompartimentoUpload";
import { ConfiguracoesArquivos } from "./containers/ConfiguracoesArquivos";
import { useEffect, useRef, useState } from "react";
import { Cobrar } from "features/licenciamento/emissao/constants";

export function UploadArquivos() {
  const [tipoArquivo, setTipoArquivo] = useState<string>("");
  const [idCliente, setIdCliente] = useState<string>("");
  const [razaoCliente, setRazaoCliente] = useState<string>("");
  const [cobranca, setCobranca] = useState<Cobrar | null>(Cobrar.SIM);
  const [uf, setUf] = useState<string>("");
  const [uploadsComecaram, setUploadsComecaram] = useState<boolean>(false);
  const [motivoIsencao, setMotivoIsencao] = useState<string>("");
  const [identificadorLote, setIdentificadorLote] = useState<string>("");

  const identificadoresLote = useRef<Map<string, string>>(new Map());

  useEffect(() => {
    if (!idCliente || !uf) return;

    const posicao = JSON.stringify({
      idCliente,
      motivoIsencao,
      uf,
    });

    if (identificadoresLote.current.has(posicao)) {
      setIdentificadorLote(String(identificadoresLote.current.get(posicao)));
      return;
    }

    const identificador = Math.random()
    .toString(32)
    .slice(2);

    identificadoresLote.current.set(posicao, identificador);

    setIdentificadorLote(identificador);

  }, [idCliente, motivoIsencao, uf]);

  return (
    <Page title="Upload de arquivos">
      <ConfiguracoesArquivos
        setTipoArquivo={setTipoArquivo}
        setIdCliente={setIdCliente}
        setRazaoCliente={setRazaoCliente}
        cobranca={cobranca}
        setCobranca={setCobranca}
        uploadsComecaram={uploadsComecaram}
        setMotivoIsencao={setMotivoIsencao}
        setUf={setUf}
        idCliente={idCliente}
        identificadorLote={identificadorLote}
      />
      <CompartimentoUpload
        identificadorLote={identificadorLote}
        tipoArquivo={tipoArquivo}
        idCliente={idCliente}
        razaoCliente={razaoCliente}
        setUploadsComecaram={setUploadsComecaram}
        motivoIsencao={motivoIsencao}
        uf={uf}
        cobrar={cobranca}
      />
    </Page>
  );
}
