import { HttpError } from "_services/api/HttpError";
import { IData, IHttpError } from "_services/api/interface/HttpResponse";
import { Data } from "_utils/Data";
import { ICadastrarDebitos } from "features/debito/consulta/interfaces/ICadastrarDebitos";
import { useRequest } from "hooks/useRequest";
import { useState } from "react";

export function useDebitos() {
  const [loading, setLoading] = useState(false);
  const { patch } = useRequest("consulta");

  async function cadastroManual({
    vencimento,
    consultaId,
    debitosId,
    valor,
    ...restante
  }: ICadastrarDebitos) {
    setLoading(true);
    try {
      const dados = {
        ...restante,
        valor: valor.toString().replace(/[-R$\s,.]/g, ""),
        vencimento: new Data(vencimento).getDataEua(),
      };

      return await patch(
        `consultas/${consultaId}/debito/${debitosId}/manual`,
        dados
      );
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);

      return response;
    } finally {
      setLoading(false);
    }
  }

  return {
    loading,
    cadastroManual,
  };
}
