import { Question } from "components/Icons/Question";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/types";
import { Circle, CustomQuestion, CustomTooltip } from "./styled";

interface Props {
    texto: string;
    posicao?: Placement;
}

export function AlertaToolTip({ texto, posicao = 'top' }: Props) {
    return (
        <OverlayTrigger
            placement='bottom-start'
            overlay={<CustomTooltip style={{
                width: '400px',
            }} id={`tooltip-${posicao}`}>{texto}</CustomTooltip>}
        >
            <Circle>
                <CustomQuestion />
            </Circle>
      </OverlayTrigger>
    );
}