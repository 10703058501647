import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { UseFormRegisterReturn } from "react-hook-form";
import {
  BotaoFechar,
  DivErro,
  DivInput,
  InputNovoTexto,
  TextoSpan,
} from "./style";
import temaPadrao from "_config/temas/estilo/base";

interface InputMultiTextosProps {
  value?: string[];
  onChange: (textos: string[]) => void;
  onKeyDown?: (texto: string) => string;
  mensagemErro?: string;
  register?: UseFormRegisterReturn;
  disabled?: boolean;
  size?: "sm" | "lg";
  placeholder?: string;
}

const InputMultiTextos = ({
  value = [],
  onChange,
  mensagemErro,
  register,
  onKeyDown,
  disabled,
  size = "lg",
  placeholder = "",
}: InputMultiTextosProps) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [editandoIndex, setEditandoIndex] = useState<number | null>(null);
  const [editandoValue, setEditandoValue] = useState<string>("");

  const handleAdicionarTexto = (texto: string) => {
    if (texto && !value.includes(texto)) {
      const novosTextos = [...value, texto];
      onChange(novosTextos);
    }
    setInputValue("");
  };

  const handleRemoveTexto = (index: number) => {
    const novosTextos = value.filter((_, i) => i !== index);
    onChange(novosTextos);
  };

  const handleEnterEspaco = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (editandoIndex !== null) {
        handleSalvarEdicao();
      } else {
        handleAdicionarTexto(inputValue.trim());
      }
    }
  };

  const handleIniciarEdicao = (index: number) => {
    setEditandoIndex(index);
    setEditandoValue(value[index]);
  };

  const handleSalvarEdicao = () => {
    if (editandoIndex !== null && editandoValue.trim()) {
      const novosTextos = [...value];
      novosTextos[editandoIndex] = editandoValue.trim();
      onChange(novosTextos);
    }
    setEditandoIndex(null);
    setEditandoValue("");
  };

  return (
    <InputGroup className="mb-3">
      <DivInput size={size} style={{
        backgroundColor: disabled ? '#efefef' : "white"
      }}>
        {value.map((texto, index) => (
          <React.Fragment key={index}>
            {editandoIndex === index ? (
              <InputNovoTexto
                disabled={disabled}
                type="text"
                value={editandoValue}
                onChange={(e) => {
                  if (onKeyDown) {
                    setEditandoValue(onKeyDown(e.target.value));
                  } else {
                    setEditandoValue(e.target.value);
                  }
                }}
                onKeyDown={handleEnterEspaco}
                onBlur={handleSalvarEdicao}
                placeholder={placeholder}
                autoFocus
              />
            ) : (
              <TextoSpan size={size} onClick={() => handleIniciarEdicao(index)}>
                {texto}
                <BotaoFechar
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveTexto(index);
                  }}
                >
                  x
                </BotaoFechar>
              </TextoSpan>
            )}
          </React.Fragment>
        ))}
        <InputNovoTexto
          disabled={disabled}
          type="text"
          value={inputValue}
          onChange={(e) => {
            if (onKeyDown) {
              setInputValue(onKeyDown(e.target.value));
            } else {
              setInputValue(e.target.value);
            }
          }}
          onKeyDown={handleEnterEspaco}
          {...register}
        />
      </DivInput>
      {mensagemErro && <DivErro>{mensagemErro}</DivErro>}
    </InputGroup>
  );
};

export default InputMultiTextos;
