import { IBodySolicitarUpload } from "features/arquivos/interfaces";
import { useRequest } from "hooks/useRequest";
import { useCallback } from "react";

export interface IInformacoesUploadArquivo {
  status?: number;
  message?: string;
  nomeArquivo: string;
  sucesso: boolean;
}

export function useSolicitacaoUpload() {
  const { postWithProgress, get } = useRequest("consulta");

  const uparDocumento = useCallback(
    async ({
      arquivo,
      usuario,
      handleProgressoDeDownload,
      id,
      idCliente,
      razaoCliente,
      cobrar,
      uf,
      motivoIsencao,
      identificadorLote,
    }: IBodySolicitarUpload): Promise<IInformacoesUploadArquivo> => {
      const formData = new FormData();

      formData.append("arquivo", arquivo);
      formData.append(
        "metadata",
        JSON.stringify({ usuario, idCliente, razaoCliente, cobrar, uf, motivoIsencao, identificadorLote })
      );

      const { data, hasErro } = await postWithProgress(
        "/licenciamento/consultas/upload",
        formData,
        {
          "Content-Type": "multipart/form-data",
        },
        false,
        handleProgressoDeDownload,
        id
      );

      if (hasErro) {
        return data as IInformacoesUploadArquivo;
      }

      return data as IInformacoesUploadArquivo;
    },
    [postWithProgress]
  );

  async function registrarContador(identificador: string): Promise<void> {
    await get(`/licenciamento/consultas/contador/${identificador}`);
  }

  return {
    uparDocumento,
    registrarContador,
  };
}
