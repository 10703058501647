import { yupResolver } from "@hookform/resolvers/yup";
import { CompositeContainer } from "components/CompositeContainer";
import { DropdownSelect, IOptions } from "components/DropdownSelect";
import {
  Cobrar,
  MotivosIsencaoOptions,
} from "features/licenciamento/emissao/constants";
import { useEmpresas } from "hooks/useEmpresas";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { RadioContainer, RadioCustomCol } from "./style";
import { useFinanceiro } from "hooks/useFinanceiro";
import { Servicos } from "_utils/enuns/Servicos.enum";
import { filtrarDetalhesEmissaoSchema } from "../../validate/validator";
import { IFiltrosUploadArquivo } from "../CompartimentoUpload/types/filtrosUpload.type";

interface Props {
  setTipoArquivo: (tipoArquivo: string) => void;
  setIdCliente: (idCliente: string) => void;
  setRazaoCliente: (razaoCliente: string) => void;
  cobranca: Cobrar | null;
  setCobranca: (statusCobranca: Cobrar | null) => void;
  setMotivoIsencao: (motivoIsencao: string) => void;
  setUf: (uf: string) => void;
  uploadsComecaram: boolean;
  idCliente: string;
  identificadorLote: string;
}

export function ConfiguracoesArquivos({
  setTipoArquivo,
  setIdCliente,
  setRazaoCliente,
  cobranca,
  setCobranca,
  setMotivoIsencao,
  setUf,
  uploadsComecaram,
  idCliente,
}: Props) {
  const {
    control,
    trigger,
    clearErrors,
    formState: { errors },
  } = useForm<IFiltrosUploadArquivo>({
    resolver: yupResolver(filtrarDetalhesEmissaoSchema),
  });
  const maxHeightListaEmpresas = 300;
  const { criarOptionsAgrupamento, agrupamentoOptions, empresasAgrupamento } =
    useEmpresas();

  const { configServicos, listarConfigServicosPorIdCliente } = useFinanceiro();
  const [optionsUf, setOptionsUf] = useState<IOptions[]>([]);

  useEffect(() => {
    criarOptionsAgrupamento();
  }, [criarOptionsAgrupamento]);

  useEffect(() => {
    if (!idCliente) return;

    listarConfigServicosPorIdCliente(idCliente);
    setUf("");
  }, [idCliente]);

  useEffect(() => {
    if (!configServicos) {
      setOptionsUf([]);
      return;
    }

    if (!Array.isArray(configServicos)) {
      setOptionsUf([]);
      return;
    }

    const options = configServicos
      .filter((config) => {
        return config.idServico === Servicos.EMISSAO_CRLV_LW_DEBITOS;
      })
      .map((config) => {
        return {
          label: config.uf,
          value: config.uf,
        };
      });

    setOptionsUf(options);
  }, [configServicos]);

  useEffect(() => {
    const validarCampos = async () => {
      await trigger();
    };

    if (uploadsComecaram) {
      validarCampos();
    }
  }, [uploadsComecaram, trigger]);

  return (
    <CompositeContainer.Root>
      <CompositeContainer.Body>
        <Form noValidate>
          <Row>
            <Col lg="2">
              <Form.Group>
                <Controller
                  control={control}
                  name="tipoDeArquivo"
                  render={({ field: { onChange, value } }) => (
                    <DropdownSelect
                      options={[{ label: "Licenciamento", value: 1 }]}
                      title="Tipo de Arquivo"
                      selectedValue={value}
                      onSelect={(event) => {
                        clearErrors("tipoDeArquivo");
                        onChange(event);
                        setTipoArquivo(event);
                        trigger("tipoDeArquivo");
                      }}
                      required
                      mensagemErro={errors.tipoDeArquivo?.message}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col lg="4">
              <Form.Group>
                <Controller
                  name="empresa"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DropdownSelect
                      onSelect={(e) => {
                        onChange(e);
                        setIdCliente(e);
                        const razaoCliente = empresasAgrupamento.find(
                          (item) => item.idClienteAgrupamento === Number(e)
                        )?.razaoSocial;
                        setRazaoCliente(razaoCliente);
                        clearErrors("empresa");
                        trigger("empresa");
                      }}
                      options={agrupamentoOptions}
                      searchable={true}
                      selectedValue={value}
                      title="Empresa"
                      required
                      mensagemErro={errors.empresa?.message}
                      maxHeight={maxHeightListaEmpresas}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col lg="2">
              <Controller
                control={control}
                name="uf"
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    options={optionsUf}
                    maxHeight={maxHeightListaEmpresas}
                    onSelect={(event) => {
                      onChange(event);
                      setUf(event);
                      clearErrors("uf");
                      trigger("uf");
                    }}
                    selectedValue={value}
                    title="UF"
                    required
                    disabled={optionsUf.length === 0 || !idCliente}
                    mensagemErro={errors.uf?.message}
                  />
                )}
              />
            </Col>
            <RadioCustomCol>
              <RadioContainer>
                <span>Gerar recibo de cobrança</span>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div>
                    <Controller
                      name="cobrar"
                      control={control}
                      render={({ field: { onChange } }) => (
                        <div
                          style={{
                            display: "flex",
                            minHeight: "35px",
                            alignItems: "center",
                          }}
                        >
                          <input
                            id="radioSim"
                            type="radio"
                            value={Cobrar.SIM}
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "5px",
                              cursor: "pointer",
                            }}
                            onChange={(event: any) => {
                              onChange(event);
                              setCobranca(Cobrar.SIM);
                              setMotivoIsencao("");
                            }}
                            checked={cobranca === Cobrar.SIM}
                          />
                          <label htmlFor="radioSim">Sim</label>
                        </div>
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name="cobrar"
                      control={control}
                      render={({ field: { onChange } }) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "12px",
                            height: "100%",
                            minHeight: "35px",
                          }}
                        >
                          <input
                            id="radioNao"
                            type="radio"
                            value={Cobrar.NAO}
                            style={{
                              width: "20px",
                              height: "20px",
                              border: "1px solid blue",
                              marginRight: "5px",
                              cursor: "pointer",
                            }}
                            onChange={(event: any) => {
                              onChange(event);
                              setCobranca(Cobrar.NAO);
                            }}
                            checked={cobranca === Cobrar.NAO}
                          />
                          <label htmlFor="radioNao">Não</label>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </RadioContainer>
            </RadioCustomCol>
            {cobranca === Cobrar.NAO && (
              <Col lg="2">
                <Controller
                  control={control}
                  name="motivoIsencao"
                  render={({ field: { onChange, value } }) => (
                    <DropdownSelect
                      onSelect={(e) => {
                        onChange(e);
                        setMotivoIsencao(e);
                        trigger("motivoIsencao");
                        clearErrors("motivoIsencao");
                      }}
                      options={MotivosIsencaoOptions}
                      selectedValue={value}
                      title="Motivo da isenção da cobrança"
                      mensagemErro={errors.motivoIsencao?.message}
                      required={cobranca === Cobrar.NAO}
                    />
                  )}
                />
              </Col>
            )}
          </Row>
        </Form>
      </CompositeContainer.Body>
    </CompositeContainer.Root>
  );
}
