import { TipoDebitos } from "features/debito/consulta/enum/TipoDebitos";
import { useCallback, useState } from "react";
import api from "_services/api";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import {
  IData,
  IHttpError,
  IHttpResponse,
} from "_services/api/interface/HttpResponse";
import { formataData } from "_utils/Data";
import { IFiltros } from "../interfaces/Filtros";
import { IFiltrosDetalhes } from "../interfaces/FiltrosDetalhesPagamento";
import { IRelatorioPagamentos } from "../interfaces/RelatorioPagamento";
import { useRequest } from "hooks/useRequest";
import { HttpStatusCode } from "axios";
import msConsultaIntegracao from "_services/api/ms_consulta_integracao";

interface ITotais {
  aPagar: number;
  valorPago: number;
  totalJuros: number;
}

export function useRelatorioPagamentos() {
  const msConsulta = useRequest("consulta");
  const [dados, setDados] = useState<any[]>([]);
  const [loadingDados, setLoadingDados] = useState<boolean>(false);
  const [loadingTotal, setLoadingTotal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDadosPlanilha, setLoadingDadosPlanilha] =
    useState<boolean>(false);
  const [totais, setTotais] = useState<ITotais>({
    aPagar: 0,
    valorPago: 0,
    totalJuros: 0,
  });
  const [error, setError] = useState<IHttpResponse<IData | any>>();
  const [queryParams, setQueryParams] = useState<string | null>(null);
  const [filtros, setFiltros] = useState<IFiltros>();
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [limitDetalhes, setLimitDetalhes] = useState<number>(100);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [statusPagamento, setStatusPagamento] = useState<string | null>(null);
  const [filtrosDetalhes, setFiltrosDetalhes] = useState<IFiltrosDetalhes>();
  const [dadosRelatorio, setDadosRelatorio] = useState<any[]>([]);
  const [loadingComprovantes, setLoadingComprovantes] =
    useState<boolean>(false);
  const [downloadIniciado, setDownloadIniciado] = useState<boolean>(false);
  const [qtdComprovantes, setQtdComprovantes] = useState<number>(0);
  const [qtdDebitos, setQtdDebitos] = useState<number>(0);
  const [resumoSolicitacaoPagamento, setResumoSolicitacaoPagamento] =
    useState<IRelatorioPagamentos | null>(null);
  const [placasSemComprovante, setPlacasSemComprovante] = useState<string[]>([]);

  const buscarTotais = useCallback(async () => {
    try {
      const ajuste = [
        filtros?.idEmpresa ? `empresaId=${filtros.idEmpresa}` : null,
        filtros?.placa ? `placa=${filtros.placa}` : null,
        filtros?.idPagamento
          ? `idSolicitacao=${filtros.idPagamento}`
          : null,
        filtros?.dataSolicitacao
          ? `dataInicio=${formataData(
            filtros.dataSolicitacao[0].toISOString()
          )}&dataFim=${formataData(filtros.dataSolicitacao[1].toISOString())}`
          : null,
        filtros?.statusPagamento ? `status=${filtros.statusPagamento}` : null,
        filtros?.renavam ? `renavam=${filtros.renavam}` : null,
        filtros?.idConsulta ? `consultaId=${filtros.idConsulta}` : null,
        filtros?.statusAdiantamento ? `statusAdiantamento=${filtros.statusAdiantamento}` : null,
      ];
      setStatusPagamento(
        filtros?.statusPagamento ? `status=${filtros.statusPagamento}` : null,

      );

      const queryParam = ajuste.filter((item) => item !== null).join("&");

      const { status, data } = await msConsulta.get(
        `pagamento/solicita/total?${queryParam}`
      );
      setTotais(data);
      if (data.status == HttpStatusCode.NotFound) {
        setTotais({
          aPagar: 0,
          totalJuros: 0,
          valorPago: 0,
        });
      }
      const response = new HttpResponse(false, status, data);
      setError(response);

      return response;

    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);

      if (error.status !== HttpStatusCode.NotFound) {
        setError(response);
      }
      setTotais({
        aPagar: 0,
        totalJuros: 0,
        valorPago: 0,
      });

      return response;
    } finally {
      setLoadingTotal(false);
    }
  }, [statusPagamento, filtros]);

  const buscarDados = useCallback(async () => {
    setLoadingDados(true);
    setLoadingTotal(true);

    const ajuste = [
      filtros?.idEmpresa ? `empresaId=${filtros.idEmpresa}` : null,
      filtros?.placa ? `placa=${filtros.placa}` : null,
      filtros?.idPagamento ? `idSolicitacao=${filtros.idPagamento}` : null,
      filtros?.dataSolicitacao
        ? `dataInicio=${formataData(
          filtros.dataSolicitacao[0].toISOString()
        )}&dataFim=${formataData(filtros.dataSolicitacao[1].toISOString())}`
        : null,
      filtros?.statusPagamento ? `status=${filtros.statusPagamento}` : null,
      filtros?.renavam ? `renavam=${filtros.renavam}` : null,
      filtros?.idConsulta ? `consultaId=${filtros.idConsulta}` : null,
      filtros?.statusAdiantamento ? `statusAdiantamento=${filtros.statusAdiantamento}` : null,
    ];
    setStatusPagamento(
      filtros?.statusPagamento ? `status=${filtros.statusPagamento}` : null
    );

    const queryParam = ajuste.filter((item) => item !== null).join("&");

    try {
      const { status, data } = await msConsulta.get(
        `/pagamento/solicita/?${queryParam || ""
        }&page=${page}&limit=${limit}&order=DESC`
      );
      setDados(data.items);
      setTotalRows(data.meta.totalItems);
      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      if (error.status !== HttpStatusCode.NotFound) {
        setError(response);
      }
      setDados([]);
      return response;
    } finally {
      setLoadingDados(false);
      buscarTotais();
    }
  }, [limit, page, filtros, buscarTotais]);

  const buscarDetalhes = useCallback(
    async (id: string | number) => {
      setLoadingDados(true);
      const ajuste = [
        filtrosDetalhes?.placa ? `placa=${filtrosDetalhes.placa}` : null,
        filtrosDetalhes?.tipoDebito
          ? `tipoDebito=${filtrosDetalhes.tipoDebito}`
          : null,
        filtrosDetalhes?.statusPagamento
          ? `status=${filtrosDetalhes.statusPagamento}`
          : null,
        filtrosDetalhes?.vencimento
          ? `vencimento=${filtrosDetalhes.vencimento}`
          : null,
        filtrosDetalhes?.renavam ? `renavam=${filtrosDetalhes.renavam}` : null,
      ];

      const queryParam = ajuste.filter((item) => item !== null).join("&");

      try {
        const { status, data } = await msConsulta.get(
          `/pagamento/solicita/${id}/debitos?${queryParam || ""
          }&page=${page}&limit=${limitDetalhes}`
        );

        setDadosRelatorio(data.items);
        setTotalRows(data.meta.totalItems);
        const response = new HttpResponse(false, status, data);
        setError(response);
        return response;
      } catch (error: IHttpError<IData, any> | any) {
        const response = new HttpError<IData>(true, error);
        setError(response);
        setDadosRelatorio([]);
        return response;
      } finally {
        setLoadingDados(false);
      }
    },
    [limitDetalhes, page, filtrosDetalhes]
  );

  async function baixarComprovanteUnitario(
    debitoPagamentoId: number,
    placa: string,
    idTipoDebito: number
  ) {
    try {
      setLoading(true);
      const { data } = await msConsulta.get(
        `/pagamento/debito/comprovante/unitario/${debitoPagamentoId}`,
        {
          responseType: "blob",
        }
      );

      const nomeArquivo = `${placa}_${TipoDebitos[idTipoDebito]}.pdf`;
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(
        new Blob([data], { type: "application/pdf" })
      );
      link.setAttribute("download", nomeArquivo);
      link.click();
      link.remove();
    } catch (error: any) {
      const response = new HttpError<IData>(true, error);
      if (error.status !== HttpStatusCode.NotFound) {
        setError(response);
      }
    } finally {
      setLoading(false);
    }
  }

  async function baixarComprovanteLote(solicitacaoPagamentoId: number, filtros: string) {
    setLoadingComprovantes(true);
    try {
      window.open(
        `${process.env.REACT_APP_BASE_URL_MS_CONSULTA
        }/pagamento/debito/comprovante/lote/${solicitacaoPagamentoId}?filtros=${filtros}&authorization=Bearer ${localStorage.getItem(
          "@pagamento_debito_Token"
        )}`
      );
    } catch (error) {
      const response = new HttpError<IData>(true, error);
      setError(response);
    } finally {
      setLoadingComprovantes(false);
    }
  }

  async function buscarDadosPlanilhaRelatorio() {
    setLoadingDadosPlanilha(true);
    const ajuste = [
      filtros?.placa ? `placa=${filtros.placa}` : null,
      filtros?.idPagamento ? `idSolicitacao=${filtros.idPagamento}` : null,
      filtros?.dataSolicitacao
        ? `dataInicio=${formataData(
          filtros.dataSolicitacao[0].toISOString()
        )}&dataFim=${formataData(filtros.dataSolicitacao[1].toISOString())}`
        : null,
      filtros?.statusPagamento ? `status=${filtros.statusPagamento}` : null,
      filtros?.renavam ? `renavam=${filtros.renavam}` : null,
      filtros?.idConsulta ? `idConsulta=${filtros.idConsulta}` : null,
      filtros?.statusAdiantamento ? `statusAdiantamento=${filtros.statusAdiantamento}` : null,
    ];
    setStatusPagamento(
      filtros?.statusPagamento ? `status=${filtros.statusPagamento}` : null
    );

    const queryParam = ajuste.filter((item) => item !== null).join("&");

    try {
      const { status, data } = await msConsulta.get(
        `/pagamento/solicita/?${queryParam || ""
        }&paginate=0&order=DESC`
      );

      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      if (error.status !== HttpStatusCode.NotFound) {
        setError(response);
      }
      return response;
    } finally {
      setLoadingDadosPlanilha(false);
    }
  }

  async function buscarDadosPlanilhaDetalhes(
    idSolicitacaoPagamento: string | number
  ) {
    setLoadingDadosPlanilha(true);
    const ajuste = [
      filtrosDetalhes?.placa ? `placa=${filtrosDetalhes.placa}` : null,
      filtrosDetalhes?.tipoDebito
        ? `idTipoDebito=${filtrosDetalhes.tipoDebito}`
        : null,
      filtrosDetalhes?.statusPagamento
        ? `status=${filtrosDetalhes.statusPagamento}`
        : null,
      filtrosDetalhes?.vencimento
        ? `vencimento=${filtrosDetalhes.vencimento}`
        : null,
      filtrosDetalhes?.renavam ? `renavam=${filtrosDetalhes.renavam}` : null,
    ];

    const queryParam = ajuste.filter((item) => item !== null).join("&");

    try {
      const { status, data } = await api.get(
        `/debito/pagamento/solicita/${idSolicitacaoPagamento}/debitos?${queryParam}&paginate=0`
      );

      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      if (error.status !== HttpStatusCode.NotFound) {
        setError(response);
      }
      return response;
    } finally {
      setLoadingDadosPlanilha(false);
    }
  }

  async function buscarTotalComprovantes(idSolicitacaoPagamento: number) {
    setLoadingComprovantes(true);
    try {
      const { status, data } = await api.get(
        `/debito/pagamento/comprovante/${idSolicitacaoPagamento}/quantidade`
      );

      setQtdComprovantes(data.qtdDebitosComComprovante);
      setQtdDebitos(data.totalDebitos);
      setPlacasSemComprovante(data.placas);
      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      if (error.status !== HttpStatusCode.NotFound) {
        setError(response);
      }
      return response;
    } finally {
      setLoadingComprovantes(false);
    }
  }

  async function baixarComprovantesPorFiltro(filtros: any): Promise<any> {
    setLoadingComprovantes(true);
    try {
      window.open(
        `${process.env.REACT_APP_BASE_URL_MS_CONSULTA
        }/pagamento/debito/comprovante/geral/?filtros=${JSON.stringify(filtros)}&authorization=Bearer ${localStorage.getItem(
          "@pagamento_debito_Token"
        )}`
      );
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      if (error.status !== HttpStatusCode.NotFound) {
        setError(response);
      }
      return response;
    } finally {
      setLoadingComprovantes(false);
    }
  }

  const buscarResumoSolicitacaoPagamento = useCallback(
    async (id: number | string) => {
      try {
        const ajuste = [
          filtrosDetalhes?.placa ? `placa=${filtrosDetalhes.placa}` : null,
          filtrosDetalhes?.tipoDebito
            ? `tipoDebito=${filtrosDetalhes.tipoDebito}`
            : null,
          filtrosDetalhes?.statusPagamento
            ? `status=${filtrosDetalhes.statusPagamento}`
            : null,
          filtrosDetalhes?.vencimento
            ? `vencimento=${filtrosDetalhes.vencimento}`
            : null,
          filtrosDetalhes?.renavam ? `renavam=${filtrosDetalhes.renavam}` : null,
        ];

        const queryParam = ajuste.filter((item) => item !== null).join("&");
        const { status, data } = await msConsulta.get(
          `/pagamento/solicita/${id}/resumo?${queryParam}`
        );

        const response = new HttpResponse(false, status, data);
        setResumoSolicitacaoPagamento(data);
        setError(response);
        return response;
      } catch (error: IHttpError<IData | any> | any) {
        const response = new HttpError<IData>(true, error);
        setError(response);
        return response;
      } finally {
        setLoading(false);
      }
    },
    [filtrosDetalhes]
  );

  return {
    buscarDados,
    dados,
    error,
    loadingDados,
    loadingTotal,
    totais,
    setPage,
    page,
    setLimit,
    limit,
    queryParams,
    buscarDetalhes,
    dadosRelatorio,
    baixarComprovanteUnitario,
    loading,
    totalRows,
    baixarComprovanteLote,
    baixarComprovantesPorFiltro,
    filtros,
    setFiltros,
    filtrosDetalhes,
    setFiltrosDetalhes,
    buscarDadosPlanilhaDetalhes,
    buscarDadosPlanilhaRelatorio,
    loadingDadosPlanilha,
    buscarTotalComprovantes,
    loadingComprovantes,
    qtdComprovantes,
    qtdDebitos,
    limitDetalhes,
    setLimitDetalhes,
    downloadIniciado,
    buscarResumoSolicitacaoPagamento,
    resumoSolicitacaoPagamento,
    placasSemComprovante,
  };
}

