import styled from "styled-components";

export const ContainerAvisoHaVinculoArquivo = styled.div`
  margin-bottom: 24px;
`;

export const TituloTabelaArquivosJaVinculados = styled.div`
  margin-bottom: 24px;
  font-weight: 600;
`;
