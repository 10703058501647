import { Form } from "react-bootstrap";
import styled from "styled-components";

export const CustomSwitch = styled(Form.Switch)`
  .form-check-input {
    width: 28px;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
  
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.neutral.neutral20};
    border-color: ${({ theme }) => theme.colors.neutral.neutral20};

    &:checked {
      background-color: ${({ theme }) => theme.colors.project.main};
      border-color: ${({ theme }) => theme.colors.project.main};
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
`;


export const Container = styled.div`
  background-color: #FAFAFA;
  width: 100%;
  padding: 10px 20px;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  box-shadow: 5px 3px 5px 2px rgba(0, 0, 0, 0.15);

  .btnFechar:hover {
    cursor: pointer;
  }
`;
