import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: grid;
  place-items: center;
  gap: 1rem;
`;

export const IconeContainer = styled.div`
  width: 3.5rem;
  height: 3.5rem;
  background-color: #FFF5EB;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  
  svg {
    color: #FE8F14;
    width: 2rem;
    height: 2rem;
  }
`;

export const TextoContainer = styled.div`
  display: grid;
  place-items: center;
  gap: 1rem;
`;

export const Texto = styled.p`
  margin: 0;
  padding: 0;
  text-align: center;
`;