import styled from "styled-components";

export const Container = styled.div`
  background-color: #f5f5f5;
  padding: 1rem 2rem;
  border-radius: 1rem;
  margin-bottom: 2rem;
  margin-top: 1rem;
`;

interface IPropsContainerBotaoVisualizar {
  quantidade: number;
}
export const ContainerBotaoVisualizar = styled.span<IPropsContainerBotaoVisualizar>`
  color: ${({ theme, quantidade }) =>
    quantidade ? theme.colors.project.main : theme.colors.neutral.neutral50};
  margin-left: 16px;
  ${({ quantidade }) => (quantidade ? "cursor: pointer;" : "")}
`;
