import { ModalCustom } from "components/ModalCustom";
import { DadosInfoCnab } from "./Interfaces/DadosInfoCnab";
import { ContainerCinza, ContainerDadosGerais } from "./style";
import { InfoCnabDadosDebito } from "./Containers/InfoCnabDadosDebito";
import { InfoCnabDados } from "./Containers/InfoCnabDados";
import { AvisoHaVinculo } from "./Containers/AvisoHaVinculo";
import { InfoCnabsVinculados } from "./Containers/InfoCnabsVinculados";
import { InfoStatusCnab } from "./Containers/InfoStatusCnab";
import { AvisoCnabComFalha } from "./Containers/AvisoCnabComFalha";
import { handleStatusCnab } from "../../Constants/handleStatusCnab";

interface Props {
  handleClose: () => void;
  show: boolean;
  dadosInfoCnab: DadosInfoCnab;
  tentarNovamente: (idDebito: number, idCnab: number) => void;
}

export function ModalInfoCNAB({
  handleClose,
  show,
  dadosInfoCnab,
  tentarNovamente,
}: Readonly<Props>) {
  return (
    <ModalCustom
      title={dadosInfoCnab.cnabs.length ? dadosInfoCnab.cnabs[0].nome : "-"}
      size="xl"
      handleClose={handleClose}
      onConfirm={() =>
        tentarNovamente(dadosInfoCnab.debito.id, dadosInfoCnab.cnabs[0].id)
      }
      show={show}
      centered
      footer={
        dadosInfoCnab.cnabs.length
          ? handleStatusCnab(dadosInfoCnab.cnabs[0].status) === "falha"
          : false
      }
      footerConfirmButton={
        dadosInfoCnab.cnabs.length
          ? handleStatusCnab(dadosInfoCnab.cnabs[0].status) === "falha"
          : false
      }
      closeButtonTitle="Sair"
      confirmButtonTitle="Tentar novamente"
      buttonPosition="end"
    >
      <InfoStatusCnab
        status={
          dadosInfoCnab.cnabs.length
            ? handleStatusCnab(dadosInfoCnab.cnabs[0].status)
            : "processando"
        }
      />
      <ContainerCinza>
        <InfoCnabDadosDebito dados={dadosInfoCnab.debito} />
      </ContainerCinza>
      <ContainerDadosGerais>
        {dadosInfoCnab.cnabs.length ? (
          <InfoCnabDados dados={dadosInfoCnab} />
        ) : (
          <div style={{ textAlign: "center" }}>Nenhum dado encontrado</div>
        )}
      </ContainerDadosGerais>
      {handleStatusCnab(
        dadosInfoCnab.cnabs.length
          ? dadosInfoCnab.cnabs[0].status
          : "PROCESSANDO"
      ) === "falha" && <AvisoCnabComFalha />}
      {!!dadosInfoCnab.cnabs.length && dadosInfoCnab.cnabs.length > 1 && (
        <>
          <AvisoHaVinculo />
          <InfoCnabsVinculados dados={dadosInfoCnab.cnabs} />
        </>
      )}
    </ModalCustom>
  );
}
