import { ModalCustom } from "components/ModalCustom";
import { AvisoPodeGerarPagamentosDuplicados } from "./containers/AvisoPodeGerarPagamentosDuplicados";
import { ContainerCinza } from "./style";
import { InfoCnabDadosDebito } from "./containers/InfoCnabDadosDebito";
import { InfoCnabsVinculados } from "./containers/InfoCnabsVinculados";
import { DadosModalCnabsVinculados } from "./Interfaces/DadosModalCnabsVinculados";

interface Props {
  handleClose: () => void;
  show: boolean;
  dados: DadosModalCnabsVinculados;
}

export function ModalListaCNABsVinculados({
  handleClose,
  show,
  dados,
}: Readonly<Props>) {
  return (
    <ModalCustom
      title="Atenção"
      size="xl"
      handleClose={handleClose}
      show={show}
      centered
      footer
      footerCloseButton
    >
      <AvisoPodeGerarPagamentosDuplicados />
      <ContainerCinza>
        <InfoCnabDadosDebito dados={dados.debito} />
      </ContainerCinza>
      {!!dados.cnabs.length && (
        <InfoCnabsVinculados dados={dados.cnabs} />
      )}
    </ModalCustom>
  );
}
