import { ModalCustom } from "components/ModalCustom";
import { FaCheck } from "react-icons/fa";
import { Container, IconeContainer, Texto, TextoContainer } from "./style";
interface IModalConfirmacaoSolicitacao {
  show: boolean;
  handleClose: () => void;
}

export function ModalConfirmacaoGerarCnab({
  show,
  handleClose,
}: IModalConfirmacaoSolicitacao) {
  return (
    <ModalCustom
      show={show}
      handleClose={handleClose}
      title="Gerar CNAB"
      centered={true}
      footer
      footerCloseButton
      footerCloseButtonOutline={false}
    >
      <Container>
        <IconeContainer>
          <FaCheck />
        </IconeContainer>
        <TextoContainer>
          <Texto>
            Solicitação efetuada com sucesso. Em breve, o arquivo será
            disponibilizado para download via e-mail.
          </Texto>
        </TextoContainer>
      </Container>
    </ModalCustom>
  );
}
