import { Form } from "react-bootstrap";
import { CustomSwitch } from "./style";
import { ChangeEvent } from "react";

interface Props {
    texto: string;
    status: boolean;
    setStatus: (status: boolean) => void;
}

export function Switch({
    texto,
    status,
    setStatus,
}: Props) {
    return (
        <Form>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <CustomSwitch
                    id='custom-switch'
                    label={''}
                    checked={status}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setStatus(e.target.checked);
                    }}
                />
                <span style={{
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '16px',
                }}>{texto}</span>
            </div>
        </Form>
    );
}