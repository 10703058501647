import * as yup from "yup";

export const filtrarDetalhesEmissaoSchema = yup.object().shape({
    tipoDeArquivo: yup
      .string()
      .required("Selecione o tipo de arquivo")
      .notOneOf(["selecione"], "Selecione um tipo válido"),
    cobrar: yup.string().required("Selecione se deseja cobrar"),
    motivoIsencao: yup.string().required("Selecione um motivo de isenção"),
    empresa: yup.string().required("Selecione um identificador de cliente"),
    uf: yup.string().required("Selecione uma UF"),
  });
  