import { Data } from "_utils/Data";
import { formatarDinheiro } from "_utils/Money";
import { Consultar } from "features/debito/consulta/enum/Consultar";
import { Col, Row } from "react-bootstrap";
import { BotaoVisualizar } from "./containers/botaoVisualizar";
import { Container } from "./style";
import { CotaIpva } from "features/debito/consulta/enum/CotaIpva";

interface Props {
  id?: string;
  resumo: any;
  onClickVisualizar: (idConsulta: number) => void;
}

export function Resumo({ id, resumo, onClickVisualizar }: Props) {
  function makeTipoDebito(ipva: string, licenciamento: string) {
    let debitos = [];

    if (ipva === Consultar.SIM) {
      debitos.push("IPVA");
    }

    if (licenciamento === Consultar.SIM) {
      debitos.push("Licenciamento");
    }
    return debitos.join(", ");
  }

  return (
    <Container>
      {resumo && (
        <>
          <Row className="mb-4">
            <Col className="d-flex flex-column">
              <div>Empresa</div>
              <div>
                <b>{resumo.empresa}</b>
              </div>
            </Col>
            <Col className="d-flex flex-column">
              <div>Usuário</div>
              <div>
                <b>{resumo.nomeUsuario}</b>
              </div>
            </Col>
            <Col className="d-flex flex-column">
              <div>ID Consulta</div>
              <div>
                <b>{resumo.consultaId}</b>
              </div>
            </Col>
            <Col className="d-flex flex-column">
              <div>Data/hora solicitação</div>
              <div>
                <b>
                  {resumo.dataHora
                    ? new Data(resumo.dataHora).getDataHoraBr()
                    : "-"}
                </b>
              </div>
            </Col>
            <Col className="d-flex flex-column">
              <div>Placas solicitadas</div>
              <div>
                <b>{resumo.qtdPlacasSolicitadas}</b>
              </div>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col className="d-flex flex-column">
              <div>Placas consultadas</div>
              <div>
                <b>{resumo.qtdPlacasConsultadas}</b>
              </div>
            </Col>
            <Col className="d-flex flex-column">
              <div>Débitos disponíveis</div>
              <div>
                <b>{resumo.qtdDebitosDisponiveis}</b>
              </div>
            </Col>
            <Col className="d-flex flex-column">
              <div>Pagamento solicitados</div>
              <div>
                <b>{resumo.qtdPlacasSolicitadoPagamento}</b>
              </div>
            </Col>
            <Col className="d-flex flex-column">
              <div>Valor da solicitação</div>
              <div>
                <b>
                  {resumo.valorTotal
                    ? formatarDinheiro(resumo.valorTotal / 100)
                    : "-"}
                </b>
              </div>
            </Col>
            <Col className="d-flex flex-column">
              <div>ID Recibo</div>
              <div>
                <b>{resumo.idRecibo || "-"}</b>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column">
              <div>Arquivos CNAB gerados</div>
              <div>
                <b>
                  {resumo.quantidadeCnabsGerados}
                  {
                    <BotaoVisualizar
                      idConsulta={resumo.consultaId}
                      onClick={onClickVisualizar}
                      quantidade={resumo.quantidadeCnabsGerados}
                    />
                  }
                </b>
              </div>
            </Col>
            <Col className="d-flex flex-column">
              <div>Débitos vinculados CNAB</div>
              <div>
                <b>{resumo.quantidadeDebitosCnab}</b>
              </div>
            </Col>
            <Col className="d-flex flex-column">
              <div>Tipo do débito</div>
              <div>
                <b>{makeTipoDebito(resumo.ipva, resumo.licenciamento)}</b>
              </div>
            </Col>
            <Col className="d-flex flex-column">
              <div>Código de barras</div>
              <div>
                <b>
                  {resumo.comCodigoDeBarras == "SIM"
                    ? "Habilitado"
                    : "Desabilitado"}
                </b>
              </div>
            </Col>
            <Col className="d-flex flex-column">
            <div>Cota IPVA</div>
              <div>
                <b>
                  {CotaIpva[resumo.cotaIpva]}
                </b>
              </div>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}
