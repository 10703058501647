import { yupResolver } from "@hookform/resolvers/yup";
import { CustomDatePickerV2 } from "components/DatePickerV2";
import { ModalCustom } from "components/ModalCustom";
import { pagamentoManual } from "features/debito/pagamento/validate/validator";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import {
  ModalContainerInfo,
  ModalContainerInfoItem,
  ModalContainerInfoItemTitulo,
  ModalContainerInfoItemValor,
  ModalContainerInfoRow,
  ModalContainerRadio,
  ModalContainerRadioLabel,
  ModalDatePickerFormLabel,
  ModalFooter,
  ModalFooterCancelar,
  ModalFooterConfirmar,
  ModalTituloPrincipal,
} from "./style";
import { formatarDinheiro } from "_utils/Money";
import { DropdownSelect } from "components/DropdownSelect";
import { OptionsBancos } from "../../constants/optionsBancos";

interface SalvarPagamentoManual {
  dataPagamento: Date;
  banco: string;
}

interface Props {
  show: boolean;
  setShowPagamentoManual(show: boolean): void;
  selectedRows: any[];
  openShowConfirmarPagamentoManual(): void;
  dados: any[];
  handleClearRows(): void;
  onConfirm: () => void;
  dataPagamento: Date | null;
  setDataPagamento: (data: Date | null) => void;
  setCodigoBanco: (codigo: string) => void;
  codigoBanco: string;
}

export function ModalPagamentoManualLote({
  show,
  setShowPagamentoManual,
  selectedRows,
  onConfirm,
  dataPagamento,
  setDataPagamento,
  setCodigoBanco,
  codigoBanco,
}: Props) {
  const { control } = useForm<SalvarPagamentoManual>({
    resolver: yupResolver(pagamentoManual),
  });

  function handleClose() {
    setShowPagamentoManual(false);
  }

  return (
    <ModalCustom
      show={show}
      handleClose={handleClose}
      size="lg"
      title="Informar pagamento manual"
      centered
      disabledConfirmButton={!dataPagamento}
      confirmButtonTitle="Confirmar pagamento"
    >
      <ModalTituloPrincipal>Dados de pagamento</ModalTituloPrincipal>
      <ModalContainerRadio>
        <input
          style={{
            marginRight: "10px",
          }}
          id="radioLote"
          type="radio"
          checked
        />
        <ModalContainerRadioLabel htmlFor="radioLote">
          Pagamento em lote
        </ModalContainerRadioLabel>
      </ModalContainerRadio>
      <form>
        <ModalContainerInfoRow>
          <ModalContainerInfo>
            <ModalContainerInfoItem>
              <ModalContainerInfoItemTitulo>
                Total de pagamentos
              </ModalContainerInfoItemTitulo>
              <ModalContainerInfoItemValor>
                {selectedRows.length}
              </ModalContainerInfoItemValor>
            </ModalContainerInfoItem>
            <ModalContainerInfoItem>
              <ModalContainerInfoItemTitulo>
                Valor total
              </ModalContainerInfoItemTitulo>
              <ModalContainerInfoItemValor>
                {formatarDinheiro(
                  selectedRows.reduce((acc, row) => {
                    if (row.valorUltimaConsulta) return acc + row.valorUltimaConsulta; 
                    return acc + row.valor;
                  }, 0) / 100
                )}
              </ModalContainerInfoItemValor>
            </ModalContainerInfoItem>
          </ModalContainerInfo>
        </ModalContainerInfoRow>
        <Row>
          <Col>
            <Form.Group>
              <ModalDatePickerFormLabel>
                Data do pagamento
              </ModalDatePickerFormLabel>
              <Controller
                name="dataPagamento"
                control={control}
                render={({ field }) => (
                  <CustomDatePickerV2
                    placeholder="Selecione a data"
                    selected={dataPagamento}
                    onChange={(date) => setDataPagamento(date)}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col>
              <Form.Group>
                <ModalDatePickerFormLabel>
                  Banco
                </ModalDatePickerFormLabel>
                <Controller
                  name="banco"
                  control={control}
                  render={({ field}) => (
                    <DropdownSelect
                      height="37px"
                      required
                      options={OptionsBancos}
                      onSelect={(option) => {
                        field.onChange(option);
                        setCodigoBanco(option);
                      }}
                      selectedValue={field.value}
                    />
                  )}
                />
              </Form.Group>
          </Col>
        </Row>
      </form>
      <ModalFooter>
        <ModalFooterCancelar onClick={() => handleClose()}>
          Cancelar
        </ModalFooterCancelar>
        <ModalFooterConfirmar ativo={
          !!dataPagamento && !!codigoBanco
        } onClick={() => {
          if (dataPagamento && codigoBanco) {
            onConfirm();
          }
        }}>
          Confirmar pagamento
        </ModalFooterConfirmar>
      </ModalFooter>
    </ModalCustom>
  );
}
