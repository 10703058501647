import { yupResolver } from "@hookform/resolvers/yup";
import { PrimaryButton } from "components/Button/style";
import { CustomInput } from "components/CustomInput";
import { DropdownSelect, IOptions } from "components/DropdownSelect";
import { Multiselect } from "components/Multiselect";
import { Required } from "components/Required";
import FileUpload from "components/UploadArquivos";
import { UploadContext } from "contexts/UploadContext";
import { useSolicitacaoConsulta } from "features/debito/consulta/hooks/useSolicitacaoConsulta";
import { IConsultarVeiculosLote } from "features/debito/consulta/interfaces/IConsultarVeiculosLote";
import { consultarVeiculoSchma } from "features/debito/consulta/validate/validator";
import { ContextPermissao } from "hooks/ContextPermissao";
import { useEmpresas } from "hooks/useEmpresas";
import { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaDownload } from "react-icons/fa";
import { Socket } from "socket.io-client";
import { ConsultaLoteContainer, InfoContainer, InputTitle } from "./style";
import { Tooltip } from "components/Tooltip";
import { Question } from "components/Icons/Question";
import { InfoTooltip } from "components/InfoTooltip";
import { OpcaoCota } from "features/debito/consulta/enum/OpcaoCota";
import { lerPlanilha } from "_utils/excel";
import { toastErro } from "components/Toast";
import { IOptionsCheck } from "features/debito/consulta/interfaces/IOptionsCheck";
import { Informacao } from "components/Informacao";
import { Cobrar, MotivosIsencaoOptions } from "features/licenciamento/emissao/constants";
import {
  InputsContainer,
  RadioContainer,
  SelectContainer,
  SpanErro,
  Container
} from "features/licenciamento/emissao/pages/Listar/containers/ModalEmitirLicenciamento/containers/BodySP/containers/SelecionarEmpresa/style";
import { Switch } from "components/Switch";
import { AlertaToolTip } from "components/AlertaToolTip";

interface Props {
  close: Callback;
  socket: Socket | null;
  handleChangeEmpresa: (idEmpresa: number | string) => void;
  optionsUf: IMultiselectOptions[];
  setTipoDebitoEscolhido: (e: Array<"IPVA" | "LICENCIAMENTO"> | null) => void;
  setUfEscolhida: (array: string[]) => void;
  optionsCotaIpva: IOptions[];
  setOpcaoCotaEscolhida: (e: OpcaoCota.PARCELADA | OpcaoCota.UNICA | null) => void;
  setOnConfirmModalValor: (funcao: () => void) => void;
  setShowModalValor: (show: boolean) => void;
  setLoadingModalValor: (loading: boolean) => void;
  setValorConsulta: (valor: number) => void;
  setQtdLinhas: (quantidade: number) => void;
  optionsTipoDebito: IOptionsCheck[];
  listarConfigServicos: (empresa: number | string) => void;
  setDadosPlanilha: (dados: any[]) => void;
  setShowConsultar: (show: boolean) => void;
}

export function BodyLote({
  close,
  socket,
  handleChangeEmpresa,
  optionsUf,
  setTipoDebitoEscolhido,
  setUfEscolhida,
  optionsCotaIpva,
  setOnConfirmModalValor,
  setShowModalValor,
  setLoadingModalValor,
  setValorConsulta,
  setQtdLinhas,
  setOpcaoCotaEscolhida,
  optionsTipoDebito,
  listarConfigServicos,
  setDadosPlanilha,
  setShowConsultar,
}: Props) {
  const { verificarUsuarioAwLw } = useContext(ContextPermissao);
  const [cobranca, setCobranca] = useState<string | null>(null);
  const [cobrado, setCobrado] = useState<boolean>(true);
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm<IConsultarVeiculosLote>({
    resolver: yupResolver(consultarVeiculoSchma),
    defaultValues: {
      comCodigoDeBarras: false,
    }
  });

  const { empresasAgrupamento, agrupamentoOptions, criarOptionsAgrupamento } = useEmpresas();
  const valueTodas = "todas";

  const [disableCota, setDisableCota] = useState(true);
  const { solicitarConsultaArquivo } = useSolicitacaoConsulta();
  const {
    adicionarArquivo,
    atualizarProgressoUpload,
    showBox,
    toggleShowBox,
    setErrosUpload,
    setPlanilhaCorrompida,
    setErroUpload,
    setSucessoUpload,
  } = useContext(UploadContext);

  useEffect(() => {
    criarOptionsAgrupamento();
  }, [criarOptionsAgrupamento]);

  function buscarNomeEmpresa(idCliente: NullableString) {
    return empresasAgrupamento.find((item) => item.idClienteAgrupamento === Number(idCliente))?.razaoSocial;
  }

  async function handleSubmitLote() {
    let dados: any = getValues();

    if (!dados.cotaIpva) {
      dados.cotaIpva = 1;
    }

    const numeroAleatorio = Math.floor(Math.random() * 10000000);

    dados.socketId = `${dados.arquivo.name}:${numeroAleatorio}`;
    dados.empresaNome = buscarNomeEmpresa(dados.empresa);
    dados.nomeArquivo = `${numeroAleatorio}${dados.arquivo.name}`;
    dados.uf = dados.uf.filter((item: any) => item !== valueTodas).join(",");

    socket?.on(`${dados.socketId}:corrompida`, () => {
      atualizarProgressoUpload({
        nomeArquivo: dados.socketId,
        progressoAtual: 100,
        temErro: true,
      });
      setPlanilhaCorrompida(dados.socketId);
    });

    socket?.on(`${dados.socketId}:invalida`, (data) => {
      setErrosUpload(`${dados.socketId}`, data);
      atualizarProgressoUpload({
        nomeArquivo: `${dados.socketId}`,
        progressoAtual: 100,
        temErro: true,
      });
    });

    socket?.on(`progresso_insercao:${dados.socketId}`, (data) => {
      atualizarProgressoUpload({
        nomeArquivo: `${dados.socketId}`,
        progressoAtual: 50 + ((data.atual / data.total) * 100) / 2,
        temErro: false,
      });
    });

    socket?.on(`${dados.socketId}:concluida`, (data) => {
      setSucessoUpload(dados.socketId, `/debito/consulta/${data.id}`);
      atualizarProgressoUpload({
        nomeArquivo: dados.socketId,
        progressoAtual: 100,
        temErro: false,
      });
    });

    adicionarArquivo([
      {
        nomeArquivo: `${dados.socketId}`,
        progressoUpload: 0,
        temErro: false,
        uploadFinalizado: false,
      },
    ]);

    if (!showBox) {
      toggleShowBox();
    }

    const { data, hasErro } = await solicitarConsultaArquivo(dados);

    if (hasErro) {
      setErroUpload(`${dados.socketId}`, data.message);
      atualizarProgressoUpload({
        nomeArquivo: `${dados.socketId}`,
        progressoAtual: 100,
        temErro: true,
      });
      return;
    }
  }

  function downloadModelo() {
    const link = document.createElement("a");
    link.download = "Consultar-Debitos-Modelo.xlsx";
    link.href = "/files/consultar-debitos-modelo.xlsx";
    link.click();
  }

  const tooltipBottom = -3.8;
  const tooltipWidth = 205;
  const maxHeightListaEmpresas = 250;

  useEffect(() => {
    if (optionsUf && optionsUf.length === 1) {
      optionsUf[0].checked = true;
      setValue("uf", [`${optionsUf[0].value}`]);
      setUfEscolhida([`${optionsUf[0].value}`]);
      return;
    };
    setValue("uf", []);
    setUfEscolhida([]);
  }, [optionsUf]);

  useEffect(() => {
    if (optionsTipoDebito && optionsTipoDebito.length === 1) {
      optionsTipoDebito[0].checked = true;
      setValue("tipoDebito", [`${optionsTipoDebito[0].value}`]);
      setDisableCota(false);
      setTipoDebitoEscolhido([`${optionsTipoDebito[0].value}` as "LICENCIAMENTO" | "IPVA"]);
      return;
    };
    setValue("tipoDebito", []);
    setTipoDebitoEscolhido(null);
    setDisableCota(true);
  }, [optionsTipoDebito]);

  useEffect(() => {
    if (optionsCotaIpva && optionsCotaIpva.length === 1) {
      setValue("cotaIpva", `${optionsCotaIpva[0].value}`);
      setOpcaoCotaEscolhida(Number(optionsCotaIpva[0].value));
      return;
    };
    setValue("cotaIpva", "");
    setOpcaoCotaEscolhida(null);
  }, [optionsCotaIpva]);


  useEffect(() => {
    if (agrupamentoOptions && agrupamentoOptions.length === 1) {
      setValue("empresa", `${agrupamentoOptions[0].value}`);
      listarConfigServicos(agrupamentoOptions[0].value);
    };
  }, [agrupamentoOptions]);

  const handleCustoConsulta = () => {
    const dados: any = getValues();

    lerPlanilha(dados.arquivo)
      .then((linhas: any) => {
        if (linhas.some((linha: any) => !Object.keys(linha).includes("UF"))) {
          setShowConsultar(true);
          setShowModalValor(false);
          toastErro("A planilha deve incluir a coluna UF");
          return;
        }
        close();
        setDadosPlanilha(linhas);
        setQtdLinhas(linhas.length);
        setLoadingModalValor(false);
      })
      .catch((errorPlanilha: any) => {
        setQtdLinhas(0);
        setLoadingModalValor(false);
        toastErro("Não foi possível ler a planilha")
      });
  }

  const handleSubmitComCusto = () => {
    setShowModalValor(false);
    setValorConsulta(0);
    setQtdLinhas(0);
    handleSubmitLote();
  }

  return (
    <ConsultaLoteContainer>
      <>
        <span>Preencha as informações abaixo para realizar a consulta de débitos dos veículos.</span>
        <Form
          onSubmit={handleSubmit(() => {
            if (cobrado) {
              setShowConsultar(false);
              setShowModalValor(true);
              setLoadingModalValor(true);
              setOnConfirmModalValor(() => handleSubmitComCusto);
              handleCustoConsulta();
              return;
            }
            close();
            handleSubmitLote();
          })}
        >
          {verificarUsuarioAwLw() ? (
            <>
              <Row>
                <Col md={5} className="mb-3">
                  <Form.Group>
                    <Form.Label>Descrição</Form.Label>
                    <Controller
                      name="descricao"
                      control={control}
                      render={({ field: { onChange } }) => (
                        <CustomInput onChange={onChange} placeholder="Informe a descrição" />
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col className="mb-3">
                  <Form.Group>
                    <Container>
                      <InputsContainer>
                        <SelectContainer>
                          <Controller
                            name="empresa"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <DropdownSelect
                                onSelect={(e) => {
                                  onChange(e);
                                  handleChangeEmpresa(e);
                                }}
                                options={agrupamentoOptions}
                                selectedValue={value}
                                title="Empresa"
                                searchable={true}
                                required
                                mensagemErro={errors.empresa?.message}
                                maxHeight={maxHeightListaEmpresas}
                              />
                            )}
                          />
                        </SelectContainer>
                        {verificarUsuarioAwLw() && (
                          <RadioContainer>
                            <span>Gerar recibo de cobrança</span>
                            <div>
                              <div>
                                <Controller
                                  name="cobrar"
                                  control={control}
                                  render={({ field: { onChange } }) => (
                                    <input
                                      id="radioSim"
                                      type="radio"
                                      value={Cobrar.SIM}
                                      onChange={(event: any) => {
                                        setCobranca("SIM");
                                        onChange(event);
                                        setCobrado(true);
                                      }}
                                      checked={cobranca === "SIM"}
                                    />
                                  )}
                                />
                                <label htmlFor="radioSim">Sim</label>
                              </div>
                              <div>
                                <Controller
                                  name="cobrar"
                                  control={control}
                                  render={({ field: { onChange } }) => (
                                    <input
                                      id="radioNao"
                                      type="radio"
                                      value={Cobrar.NAO}
                                      onChange={(event: any) => {
                                        setCobranca("NAO");
                                        onChange(event);
                                        setCobrado(false);
                                      }}
                                      checked={cobranca === "NAO"}
                                    />
                                  )}
                                />
                                <label htmlFor="radioNao">Não</label>
                              </div>
                            </div>
                            <SpanErro>{errors.cobrar?.message}</SpanErro>
                          </RadioContainer>
                        )}
                      </InputsContainer>
                    </Container>
                  </Form.Group>
                </Col>
              </Row>
              {cobranca === "NAO" && (
                <Row className="mb-3">
                  <Controller
                    control={control}
                    name="motivoIsencao"
                    render={({ field: { onChange, value } }) => (
                      <DropdownSelect
                        onSelect={onChange}
                        options={MotivosIsencaoOptions}
                        selectedValue={value}
                        title="Motivo da isenção da cobrança"
                        mensagemErro={errors.motivoIsencao?.message}
                      />
                    )}
                  />
                </Row>
              )}
            </>
          ) : (
            <>
              <Row className="mb-3">
                <Col>
                  <Form.Group>
                    <Form.Label>Descrição</Form.Label>
                    <Controller
                      name="descricao"
                      control={control}
                      render={({ field: { onChange } }) => (
                        <CustomInput onChange={onChange} placeholder="Informe a descrição" />
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col className="mt-1" style={{ width: '100%' }}>
                  <Form.Group>
                    <Controller
                      name="empresa"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <DropdownSelect
                          onSelect={(e) => {
                            onChange(e);
                            handleChangeEmpresa(e);
                          }}
                          options={agrupamentoOptions}
                          searchable={true}
                          selectedValue={value}
                          title="Empresa"
                          required
                          mensagemErro={errors.empresa?.message}
                          maxHeight={maxHeightListaEmpresas}
                        />
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}
          <Row className="mb-4">
            <Col md={3}>
              <Form.Group>
                <Controller
                  name="tipoDebito"
                  control={control as any}
                  render={({ field: { onChange, value } }) => (
                    <Multiselect
                      options={optionsTipoDebito}
                      onChange={(e) => {
                        setDisableCota(true);
                        setTipoDebitoEscolhido(e);

                        if ([...e].includes("IPVA")) {
                          setDisableCota(false);
                        }

                        onChange(e);
                      }}
                      title={"Tipo de débito"}
                      value={value ?? []}
                      required
                      mensagemErro={errors.tipoDebito?.message}
                      disabled={optionsTipoDebito && !optionsTipoDebito.length}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col md={2} className="mt-1">
              <Form.Group>
                <Controller
                  name="cotaIpva"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DropdownSelect
                      disabled={disableCota}
                      selectedValue={value}
                      title="Cota IPVA"
                      options={optionsCotaIpva}
                      onSelect={(e) => {
                        onChange(e);
                        setOpcaoCotaEscolhida(e);
                      }}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Controller
                  name="uf"
                  control={control as any}
                  render={({ field: { onChange, value } }) => (
                    <Multiselect
                      options={optionsUf}
                      valueTodos={valueTodas}
                      onChange={(e) => {
                        onChange(e);
                        setUfEscolhida([...e].filter(option => option.value !== valueTodas));
                      }}
                      title={<span>
                        <InputTitle desativado={optionsUf && !optionsUf.length}>UF</InputTitle> <Required />{" "}
                        <Tooltip bottom={tooltipBottom} width={tooltipWidth} trigger={<Question />}>
                          <InfoTooltip
                            mensagem={"UF de emplacamento"}
                          ></InfoTooltip>
                        </Tooltip>
                      </span>}
                      value={value ?? []}
                      required
                      mensagemErro={errors.uf?.message}
                      disabled={optionsUf && !optionsUf.length}
                      maxHeight={270}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>
                  Arquivo
                  <Required />
                </Form.Label>
                <FileUpload onFileUpload={(file) => setValue("arquivo", file)} mensagemErro={errors.arquivo?.message} />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={12}>
              <InfoContainer>
                <Form.Group>
                  <Controller
                    name="comCodigoDeBarras"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                        <Switch
                          texto="Buscar código de barras"
                          status={value}
                          setStatus={() => {
                            onChange(!value);
                          }}
                        />
                        <div style={{
                          marginLeft: '12px',
                        }}>
                          <AlertaToolTip posicao='bottom' texto="Ative esta opção caso necessite do código de barras para pagamento do débito via arquivo CNAB." />
                        </div>
                      </div>
                    )}
                  />
                </Form.Group>
              </InfoContainer>
            </Col>
          </Row>

          <Informacao
            type="azul"
            mensagem="Serão listadas na tela as UFs contratadas para o(s) tipo(s) de débito(s) selecionado(s)."
            showBotaoFechar={false}
            showLabel={false}
            style={{ boxShadow: "none", padding: "16px" }}
          />
          <div className="d-flex justify-content-end mt-4 mb-3">
            <PrimaryButton type="button" className="me-2" outline variante="primary" onClick={downloadModelo}>
              Baixar modelo <FaDownload />
            </PrimaryButton>
            <PrimaryButton type="submit">Consultar</PrimaryButton>
          </div>
        </Form>
      </>
    </ConsultaLoteContainer>
  );
}
