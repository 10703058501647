import * as yup from "yup";

export const regexPlacas = /^[A-Za-z]{3}-?\d[0-9A-Za-z]\d{2}$/;

export const filtrosExtratoVeicularSchema = yup.object().shape({
  id: yup.number().nullable(),
  placa: yup.string().matches(regexPlacas, { message: "Insira uma placa válida", excludeEmptyString: true }).nullable(),
  dataInicial: yup
    .date()
    .nullable()
    .test("DATE_RANGE", "Insira a data inicial para prosseguir", (value, context) => {
      if (context.parent.dataFinal && !value) {
        return false;
      }

      return true;
    }),
  dataFinal: yup
    .date()
    .nullable()
    .test("DATE_RANGE", "Insira a final para prosseguir", (value, context) => {
      if (context.parent.dataInicial && !value) {
        return false;
      }

      return true;
    }),
  tipoConsulta: yup.string().nullable(),
  renavam: yup.string().nullable(),
  chassi: yup.string().nullable(),
  empresa: yup.string().nullable(),
  statusLoteConsulta: yup.string().nullable(),
  cobranca: yup.string().nullable(),
});

export const filtrosUnitariosExtratoVeicularSchema = yup.object().shape({
  placa: yup.string().matches(regexPlacas, { message: "Insira uma placa válida", excludeEmptyString: true }).nullable(),
  dataInicial: yup
    .date()
    .nullable()
    .test("DATE_RANGE", "Insira a data inicial para prosseguir", (value, context) => {
      if (context.parent.dataFinal && !value) {
        return false;
      }

      return true;
    }),
  dataFinal: yup
    .date()
    .nullable()
    .test("DATE_RANGE", "Insira a final para prosseguir", (value, context) => {
      if (context.parent.dataInicial && !value) {
        return false;
      }

      return true;
    }),
  statusConsulta: yup.string().nullable(),
  tipoConsulta: yup.string().nullable(),
  tipoPendencia: yup.string().nullable(),
  renavam: yup.string().nullable(),
  chassi: yup.string().nullable(),
  id: yup.number().nullable(),
  empresa: yup.string().nullable(),
  cobranca: yup.string().nullable(),
});

