import { formatarDinheiro } from "_utils/Money";
import { IDadosBancosGerarCnab } from "../../Interfaces/IDadosBancosGerarCnab";
import { ValorSelecaoAutomaticaBanco } from "../ValorSelecaoAutomaticaBanco";

interface ITabelaSelecaoBancoAutomatica {
  dadosBancos: IDadosBancosGerarCnab[];
  setDadosBancosGerarCnab: (dados: IDadosBancosGerarCnab[]) => void;
}

export function TabelaSelecaoBancoAutomatica({
  dadosBancos,
  setDadosBancosGerarCnab,
}: ITabelaSelecaoBancoAutomatica) {
  const onClickLixeira = (codigoBanco: number | string) => {
    const dadosBancosGerarCnab = dadosBancos.map((banco) => {
      if (banco.codigoBanco == codigoBanco) {
        banco.visivel = false;
      }

      return banco;
    });

    setDadosBancosGerarCnab(dadosBancosGerarCnab);
  };

  return (
    <table width="100%">
      <thead>
        <td width="34%">
          <strong>Banco selecionado</strong>
        </td>
        <td width="33%">
          <strong>Qtd de débitos</strong>
        </td>
        <td width="33%">
          <strong>Valor</strong>
        </td>
      </thead>
      <tbody>
        {dadosBancos.length ? (
          dadosBancos
            .filter((item) => item.visivel)
            .map((item) => {
              return (
                <tr>
                  <td>{item.nomeBanco}</td>
                  <td>{item.quantidadeDebitos}</td>
                  <td>
                    <ValorSelecaoAutomaticaBanco
                      valor={item.montanteDebitos}
                      habilitado={item.bancoDisponivel}
                      onClickLixeira={onClickLixeira}
                      codigoBanco={item.codigoBanco}
                    />
                  </td>
                </tr>
              );
            })
        ) : (
          <tr>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </tr>
        )}
      </tbody>
      <tr>
        <td>
          <strong>Totais</strong>
        </td>
        <td>
          <strong>
            {dadosBancos.filter((item) => item.visivel).length
              ? dadosBancos
                  .filter((item) => item.visivel)
                  .map((item) => item.quantidadeDebitos)
                  .reduce(
                    (previousValue, currentValue) =>
                      previousValue + currentValue
                  )
              : "-"}
          </strong>
        </td>
        <td>
          <strong>
            {dadosBancos.filter((item) => item.visivel).length
              ? formatarDinheiro(
                  (dadosBancos
                    .filter((item) => item.visivel)
                    .map((item) => item.montanteDebitos)
                    .reduce(
                      (previousValue, currentValue) =>
                        previousValue + currentValue
                    ) ?? 0) / 100
                )
              : "-"}
          </strong>
        </td>
      </tr>
    </table>
  );
}
