export const dadosInfoCnabDefault = {
  cnabs: [],
  debito: {
    id: 0,
    placa: "",
    uf: "",
    debito: 1,
    vencimento: "",
    valor: 0,
  },
};
