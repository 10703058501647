import styled from "styled-components";

export const ErroCrlv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    gap: 15px;
    left: -8vw;
    color: #4D4D4D;
`;

export const ContainerExclamacaoCrlv = styled.div`
    width: 40px;
    height: 40px;
    background-color: #FFF5EB;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
`;

export const ErroCrlvBotao = styled.div`
    border: 1px solid #009933;
    border-radius: 100px;
    height: 43px;
    width: 92px;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ErroCrlvBotaoSpan = styled.span`
    font-family: 'Roboto';
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    color: #009933;
`;
