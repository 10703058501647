import styled from "styled-components";

interface InputMultiTextosProps {
  size: "sm" | "lg";
}

export const BotaoFechar = styled.span`
  font-weight: 600;
  margin-left: 10px;
  cursor: pointer;
  padding: 0;
`;

export const DivInput = styled.div<InputMultiTextosProps>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  padding: ${({ size }) => (size === "sm" ? "2px" : "8px")};
  border: 1px solid #7f7f7f;
  border-radius: 5px;
  background-color: #ffffff;
`;

export const TextoSpan = styled.span<InputMultiTextosProps>`
  display: inline-flex;
  align-items: center;
  padding: ${({ size }) => (size === "sm" ? "0 8px" : "5 10px")};
  margin: 0 8px 0 0;
  background-color: #f5f5f5;
  border-radius: 100px;
  font-size: 14px;
`;

export const InputNovoTexto = styled.input`
  border: none;
  outline: none;
  flex: 1;
  min-width: 20px;
`;

export const DivErro = styled.div`
  color: ${({ theme }) => theme.colors.danger.main};
`;
