import { ModalCustom } from "components/ModalCustom";
import {
  BancoRadioContainer,
  BancoRadioInputContainer,
  ContainerCinza,
  DivEnviarPara,
  DivEnviarParaTitulo,
  DivSelecionarBanco,
  DivSelecionarBancoTitulo,
  DivSolicitante,
} from "./styles";
import InputMultiTextos from "components/InputMultiTextos";
import { TabelaSelecaoBancoAutomatica } from "./Containers/TabelaSelecaoBancoAutomatica";
import { TabelaSelecaoBancoManual } from "./Containers/TabelaSelecaoBancoManual";
import { AvisoSelecaoManualBanco } from "./Containers/AvisoSelecaoManualBanco";
import { AvisoCnabLote } from "./Containers/AvisoCnabLote";
import { IDadosBancosGerarCnab } from "./Interfaces/IDadosBancosGerarCnab";
import { getEmail } from "_services/auth";
import { AvisoNaoHaBancoGerarCnab } from "./Containers/AvisoNaoHaBancoGerarCnab";
import { IOptions } from "components/DropdownSelect";

interface Props {
  handleClose: () => void;
  show: boolean;
  onConfirm: () => void;
  loteInteiro: boolean;
  enviarArquivoCnabPara: string[];
  onChangeEnviarArquivoCnabPara: (emails: string[]) => void;
  setSelecaoBanco: (selecao: string) => void;
  setBancoSelecionado: (selecao: string | number) => void;
  selecaoBanco: string;
  dadosBancosGerarCnab: IDadosBancosGerarCnab[];
  bancoSelecionado: number | string;
  erroMultiTextos: string;
  setDadosBancosGerarCnab: (dados: IDadosBancosGerarCnab[]) => void;
  bancosOptions: IOptions[];
}

export function ModalGerarCNAB({
  handleClose,
  onConfirm,
  show,
  loteInteiro,
  enviarArquivoCnabPara,
  onChangeEnviarArquivoCnabPara,
  setSelecaoBanco,
  setBancoSelecionado,
  selecaoBanco,
  dadosBancosGerarCnab,
  bancoSelecionado,
  erroMultiTextos,
  setDadosBancosGerarCnab,
  bancosOptions,
}: Readonly<Props>) {
  return (
    <ModalCustom
      title="Gerar arquivo CNAB"
      size="lg"
      handleClose={handleClose}
      show={show}
      centered
      footer
      footerConfirmButton
      onConfirm={onConfirm}
      disabledConfirmButton={
        (selecaoBanco === "manual" && !bancoSelecionado) ||
        !enviarArquivoCnabPara.length ||
        !!erroMultiTextos ||
        !dadosBancosGerarCnab.filter((banco) => banco.nomeBanco !== "-").length
      }
      confirmButtonTitle="Gerar CNAB"
      buttonPosition="end"
    >
      <ContainerCinza>
        <DivSolicitante>
          Solicitante: <strong>{getEmail()}</strong>
        </DivSolicitante>
        <DivEnviarPara>
          <DivEnviarParaTitulo>Enviar arquivo para:</DivEnviarParaTitulo>
          <InputMultiTextos
            value={enviarArquivoCnabPara}
            onChange={onChangeEnviarArquivoCnabPara}
            mensagemErro={erroMultiTextos}
          />
        </DivEnviarPara>
      </ContainerCinza>
      <DivSelecionarBanco>
        <DivSelecionarBancoTitulo>Seleção de Banco</DivSelecionarBancoTitulo>
        <BancoRadioContainer>
          <BancoRadioInputContainer>
            <input
              type="radio"
              value="automatica"
              checked={selecaoBanco === "automatica"}
              onChange={() => {
                setSelecaoBanco("automatica");
                setBancoSelecionado(0);
              }}
              name="selecaoBanco"
              id="automatica"
            />
            <label htmlFor="automatica">Automática</label>
          </BancoRadioInputContainer>
          <BancoRadioInputContainer>
            <input
              type="radio"
              value="manual"
              checked={selecaoBanco === "manual"}
              onChange={() => {
                setSelecaoBanco("manual");
              }}
              name="manual"
              id="manual"
            />
            <label htmlFor="manual">Manual</label>
          </BancoRadioInputContainer>
        </BancoRadioContainer>
      </DivSelecionarBanco>
      {selecaoBanco === "manual" && <AvisoSelecaoManualBanco />}
      <ContainerCinza>
        {selecaoBanco === "automatica" ? (
          <TabelaSelecaoBancoAutomatica
            dadosBancos={dadosBancosGerarCnab}
            setDadosBancosGerarCnab={setDadosBancosGerarCnab}
          />
        ) : (
          <TabelaSelecaoBancoManual
            bancoSelecionado={bancoSelecionado}
            setBancoSelecionado={setBancoSelecionado}
            dadosBancos={dadosBancosGerarCnab}
            bancosOptions={bancosOptions}
          />
        )}
      </ContainerCinza>
      {!!dadosBancosGerarCnab.filter((banco) => banco.nomeBanco !== "-")
        .length && <AvisoCnabLote loteInteiro={loteInteiro} />}
      {!dadosBancosGerarCnab.filter((banco) => banco.nomeBanco !== "-")
        .length && <AvisoNaoHaBancoGerarCnab />}
    </ModalCustom>
  );
}
