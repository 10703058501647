import styled from "styled-components";

export const ContainerUpload = styled.div`
    min-height: 800px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ContainerUploadDragAndDrop = styled.div`
    border: 1px dashed #B3B3B3;
    width: 97%;
    height: 189px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
    flex-direction: column;
    transition: ease 0.5s;
    margin-bottom: 20px;
`;

export const ContainerUploadIcone = styled.div`
    background-color: #E7F5EB;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    margin-bottom: 20px;
`;

export const ContainerArquivosUpados = styled.div`
    width: 97%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
`;

export const ArquivoUpado = styled.div<{
    esquerdaCima?: boolean;
    direitaCima?: boolean;
    esquerdaBaixo?: boolean;
    direitaBaixo?: boolean;
}>`
    flex: 0 1 calc(33.33% - 10px);
    height: 60px;
    background-color: #FAFAFA;
    box-sizing: border-box;
    display: flex;
    border-top-left-radius: ${(props) => (props.esquerdaCima ? '5px' : '0')};
    border-bottom-right-radius: ${(props) => (props.direitaBaixo ? '5px' : '0')};
    border-top-right-radius: ${(props) => (props.direitaCima ? '5px' : '0')};
    border-bottom-left-radius: ${(props) => (props.esquerdaBaixo ? '5px' : '0')};

    @media (max-width: 1366px) {
        flex: 0 1 calc(50% - 10px);
    }

    @media (max-width: 768px) {
        flex: 0 1 calc(100% - 10px);
    }
`;

export const HeaderArquivoUpado = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: calc(100% - 70px);
    padding: 8px;
`;

export const IconesArquivoUpado = styled.div`
    height: 60px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: space-around;
`;


export const IconeArquivo = styled.div`
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
`;
