import { Container, TextoContainer, TitleContainer } from "./style";
import {
  FaExclamationTriangle,
  FaCheck,
  FaExclamationCircle,
} from "react-icons/fa";
import { HiX } from "react-icons/hi";
import React, { ReactNode, useState } from "react";

interface IInformacao {
  type?: "atencao" | "erro" | "info" | "sucesso" | "branco" | "azul" | "atencaoRedondo";
  mensagem: string | ReactNode;
  showBotaoFechar?: boolean;
  showLabel?: boolean;
  className?: string;
  title?: boolean;
  style?: React.CSSProperties | undefined;
  fontSize?: string;
  widthSvg?: string;
  marginTopSvg?: string;
}

export function Informacao({
  type = "info",
  mensagem,
  showBotaoFechar = true,
  showLabel = true,
  className,
  title = true,
  style,
  fontSize,
  widthSvg,
  marginTopSvg,
}: IInformacao) {
  const [mostrar, setMostrar] = useState(true);

  function handleClickBotaoFechar() {
    setMostrar(false);
  }

  const cardStrategy = {
    atencao: (
      <span>
        <FaExclamationTriangle />
        {showLabel && "Atenção"}
      </span>
    ),
    sucesso: (
      <span>
        <FaCheck />
        {showLabel && "Sucesso"}
      </span>
    ),
    erro: (
      <span>
        <FaExclamationCircle />
        {showLabel && "Erro"}
      </span>
    ),
    info: (
      <span>
        <FaExclamationCircle />
        {showLabel && "Info"}
      </span>
    ),
    branco: (
      <span>
        <FaExclamationCircle />
        {showLabel && "Info"}
      </span>
    ),
    azul: (
      <span>
        <FaExclamationCircle />
        {showLabel && "Info"}
      </span>
    ),
    atencaoRedondo: (
      <span>
        <FaExclamationCircle />
        {showLabel && "Atenção"}
      </span>
    ),
  };

  return (
    <Container
      className={className}
      mostrar={mostrar}
      showLabel={showLabel}
      type={type}
      style={style}
    >
      {title && type !== "branco" && (
        <TitleContainer type={type} widthSvg={widthSvg} marginTopSvg={marginTopSvg}>
          {cardStrategy[type]}
          {showBotaoFechar && (
            <HiX className="btnFechar" onClick={handleClickBotaoFechar} />
          )}
        </TitleContainer>
      )}
      <TextoContainer fontSize={fontSize}>{mensagem}</TextoContainer>
    </Container>
  );
}
