import { FormContainer } from "./style";

interface Props {
  close: Callback;
  idConsulta: number;
}

export function ConsultaManualLote({ close, idConsulta }: Props) {
  return <FormContainer>ConsultaManualL ote</FormContainer>;
}
