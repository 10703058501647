import styled from "styled-components";

interface Props {
  disabled: boolean;
}

export const Container = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  overflow: hidden;


  svg {
    width: 1.00rem;
    height: 1.00rem;
    color: ${({ disabled, theme }) =>
      disabled
        ? theme.colors.neutral.neutral50
        : theme.colors.neutral.neutral90};
 
    overflow: hidden;
    cursor: pointer;

`;
