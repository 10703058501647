export const handleStatusCnab = (status: string) => {
  if (status === "ERRO") {
    return "falha";
  }

  if (status === "SUCESSO") {
    return "enviado";
  }

  return "processando";
};
