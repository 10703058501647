import styled from "styled-components";

export const ModalBody = styled.div`
  text-align: center;
  display: grid;
  gap: 1rem;

  .informacao {
    margin: 0;
    width: 100%;
    font-size: 0.95rem;
  }
`;

export const ContainerCinza = styled.div`
  padding: 24px 16px;
  background-color: #f5f5f5;
  border-radius: 10px;
`;

export const DivSolicitante = styled.div`
  margin-bottom: 16px;
`;

export const DivEnviarPara = styled.div``;

export const DivEnviarParaTitulo = styled.div`
  color: #4d4d4d;
  margin-bottom: 8px;
`;

export const DivSelecionarBanco = styled.div`
  margin: 24px 0 8px 0;
  padding: 0 8px;
  display: flex;
  justify-content: space-between;
`;

export const DivSelecionarBancoTitulo = styled.div`
  font-weight: 600;
`;

export const BancoRadioContainer = styled.div`
  width: 32%;
  display: flex;
  align-items: right;
  gap: 2rem;
  margin-bottom: 1rem;

  input {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const BancoRadioInputContainer = styled.div`
  display: flex;
  align-items: right;
  gap: 0.5rem;

  input {
    accent-color: ${({ theme }) => theme.colors.project.main};
    margin-right: 0.5rem;
    &:hover {
      cursor: pointer;
    }
  }

  label {
    margin-top: -3px;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const ContainerSelecionarBanco = styled.div`
  max-width: 300px;
  margin-bottom: 16px;
`;

export const ContainerAvisoSelecaoManualBanco = styled.div`
  margin-bottom: 16px;
`

export const ContainerAvisoCnabLote = styled.div`
  margin-top: 24px;
`

export const DivIconesBanco = styled.div`
  display: flex;
  justify-content: space-between;
`;