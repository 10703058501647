import { DadosVisualizarCnabs } from "../../Interfaces/DadosVisualizarCnabs";
import { ContainerCinza } from "../../style";

interface Props {
  dados: DadosVisualizarCnabs;
}
export function InfosGerais({ dados }: Props) {
  return (
    <ContainerCinza>
      <table width="100%">
        <thead>
          <tr>
            <td width="33%">Débitos disponíveis</td>
            <td width="33%">Arquivos CNAB gerados</td>
            <td width="34%">Débitos vinculados CNAB</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>{dados.qtdDebitosDisponiveis ?? "-"}</strong>
            </td>
            <td>
              <strong>{dados.cnabGerados ?? "-"}</strong>
            </td>
            <td>
              <strong>{dados.debitosVinculados ?? "-"}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </ContainerCinza>
  );
}
