import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Container } from "./estilo";
import BotaoFecharClaro from "./Botoes/BotaoFecharClaro";

export default function Toast({ ...props }) {
  return (
    <Container>
      <ToastContainer
        theme="dark"
        position="top-center"
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
    </Container>
  );
}

const toastSucesso = (mensagem: string | React.ReactNode) => {
  toast.success(mensagem, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0, // false
  });
};

const toastErro = (mensagem: string) => {
  toast.error(mensagem, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0, // false
  });
};

const toastErroCustom = (elementoCustom: any, fecharCustom: any) => {
  toast(elementoCustom, {
    style: {
      minHeight: '215px',
      backgroundColor: 'white',
    },
    closeButton(props) {
      return <BotaoFecharClaro />;
    },
    toastId: 'custom-erro',
    hideProgressBar: true,
    autoClose: 3000,
  });
}

const toastInfo = (mensagem: string) => {
  toast.info(mensagem, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0, // false
  });
};

const toastAtencao = (mensagem: string) => {
  toast.warn(mensagem, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0, // false
  });
};

export { toastSucesso, toastErro, toastInfo, toastAtencao, toastErroCustom };
