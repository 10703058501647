import styled from "styled-components";

interface TriggerProps {
  cursorHelp?: boolean;
}

export const TriggerContainer = styled.div<TriggerProps>`
  ${({ cursorHelp }) => cursorHelp ? "cursor: help;" : "cursor: pointer;"};
  padding: 0.5rem 0;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

TriggerContainer.defaultProps = {
  cursorHelp: false,
};

export const TooltipContent = styled.div`
  background-color: #fff;
  width: 230px !important;
  padding: 0.5rem 1rem;
  display: grid;
  gap: 0.5rem;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;

  span {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0.5rem;

    svg {
      color: ${({ theme }) => theme.colors.warning.main};
    }
  }
`;

export const MensagemTooltipContainer = styled.div`
  white-space: normal !important;
  overflow-x: hidden !important;
  text-overflow: unset !important;
  display: grid;
  gap: 0.5rem
`;
