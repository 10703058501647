import { formataDataISOParaBR } from "_utils/Data";
import { formatarDinheiro } from "_utils/Money";
import { PrimaryButton } from "components/Button/style";
import { Container } from "components/Container";
import { StatusSolicitacaoPagamento } from "components/StatusSolicitacaoPagamento";
import { Tooltip } from "components/Tooltip";
import { Table } from "containers/Table";
import { IColum } from "containers/Table/interfaces";
import { StatusSolicitacaoPagamentoEnumV2 } from "features/debito/consulta/enum/StatusSolicitacaoPagamento";
import { TipoDebitos } from "features/debito/consulta/enum/TipoDebitos";
import { IDetalhesConsulta } from "features/debito/consulta/interfaces/IDetalhesConsulta";
import { useContext, useEffect, useState } from "react";
import { ExpanderComponentProps } from "react-data-table-component";
import {
  FaDollarSign,
  FaDownload,
  FaFolderPlus,
  FaPlusCircle,
} from "react-icons/fa";
import { MdRefresh } from "react-icons/md";
import { utils, writeFileXLSX } from "xlsx";
import { IconeInfo } from "../IconeInfo";
import { MensagemDebitos } from "../MensagemDebitos";
import { Resumo } from "../Resumo";
import { TentativasConsultas } from "../TentativasConsultas";
import { IListarConsultasProps } from "./interface/IListarDetalhes";
import { ButtonHeader, TooltipContemt } from "./style";
import { ConsultaManual } from "../ConsultaManual";
import { BtnGerarCNAB } from "../BtnGerarCNAB";
import { ContainerBtnCNAB } from "../BtnGerarCNAB/style";
import { IconeStatusCNAB } from "../IconeStatusCNAB";
import { getEmail } from "_services/auth";
import { Empresa } from "_utils/enuns/Empresa.enum";
import { Dominios } from "_utils/enuns/Dominios.enum";
import { ExportExcel } from "components/ExportExcel";
import { ContextPermissao } from "hooks/ContextPermissao";

export function makeStatusDebitosConsulta(statusDebito: string) {
  return <StatusSolicitacaoPagamento statusDebito={statusDebito} />;
}

export function ExpandedComponentConsulta({
  data,
}: Readonly<ExpanderComponentProps<IDetalhesConsulta>>) {
  return (
    <MensagemDebitos
      idDebito={data.id}
      tipoDebito={data.debito}
      exercicio={data.exercicio}
      temMulta={data.temMulta}
      descricao={data.descricao}
      statusDebito={data.statusDebito}
    />
  );
}

export function makeTentativasConsulta(tentativas: number, status: number) {
  return <TentativasConsultas tentativas={tentativas} status={status} />;
}

export function ListarDetalhes({
  dados,
  setDados,
  loading,
  idConsulta,
  setRowExpandToggled,
  setSelectedRows,
  handleShow,
  disableReconsultar,
  solicitarTodos,
  rowToExpand,
  onChangePage,
  onChangeRowsPerPage,
  limit,
  totalRows,
  clearSelectedRows,
  onClickReconsultar,
  resumo,
  buscarDetalhesSemPaginacao,
  onClickGerarCNAB,
  onClickVisualizar,
  idsDebitosSelecionados,
  onClickInfoCnab,
}: Readonly<IListarConsultasProps>) {
  const { verificarUsuarioAwLw } = useContext(ContextPermissao);
  const [loadingExportar, setLoadingExportar] = useState<boolean>(false);

  const statusDesabilitarSolicitarPagamento = [
    StatusSolicitacaoPagamentoEnumV2["CNAB enviado"],
    StatusSolicitacaoPagamentoEnumV2["CNAB processando"],
  ];

  const handleStatusCNAB = (
    status: number | string,
    nome: string,
    idDebito: number
  ) => {
    if (status == StatusSolicitacaoPagamentoEnumV2["CNAB enviado"]) {
      return {
        elemento: (
          <>
            <IconeStatusCNAB status="enviado" tamanho="8px" />
            <span
              onClick={() => onClickInfoCnab(idDebito)}
              style={{ cursor: "pointer", textDecoration: "underline" }}
            >
              {nome}
            </span>
          </>
        ),
        texto: nome,
      };
    }

    if (status == StatusSolicitacaoPagamentoEnumV2["CNAB não enviado"]) {
      return {
        elemento: (
          <>
            <IconeStatusCNAB status="falha" tamanho="8px" />
            <span
              onClick={() => onClickInfoCnab(idDebito)}
              style={{ cursor: "pointer", textDecoration: "underline" }}
            >
              Falha no envio
            </span>
          </>
        ),
        texto: "Falha no envio",
      };
    }

    if (status == StatusSolicitacaoPagamentoEnumV2["CNAB processando"]) {
      return {
        elemento: (
          <>
            <IconeStatusCNAB status="processando" tamanho="8px" />
            <span>Processando</span>
          </>
        ),
        texto: "Processando",
      };
    }

    if (status == StatusSolicitacaoPagamentoEnumV2["Débito disponível"]) {
      return {
        elemento: (
          <>
            <IconeStatusCNAB status="desabilitar" tamanho="8px" />
            <span>Não vinculado</span>
          </>
        ),
        texto: "Não vinculado",
      };
    }

    return { elemento: "--", texto: "-" };
  };

  const handleExibicaoCodigoDeBarras = (
    valorResumo: string,
    codigoDeBarras: string,
    status: string
  ) => {
    if (valorResumo !== "SIM") return "-";
    if (
      !codigoDeBarras &&
      codigoDeBarras == "0000000000000000000000000000000000000"
    )
      return "Informação não disponibilizada pelo órgão.";
    if (
      status === String(StatusSolicitacaoPagamentoEnumV2["Débito disponível"])
    ) {
      return codigoDeBarras;
    }
    return "-";
  };

  const handleExport = async () => {
    setLoadingExportar(true);
    const dados = await buscarDetalhesSemPaginacao();

    const dadosPlanilha = dados.map((item: any) => ({
      Placa: item.placa.toUpperCase(),
      Renavam: item.renavam,
      UF: item.uf?.toUpperCase(),
      Debito: TipoDebitos[Number(item.debito)],
      "Cota IPVA": item.cota ? item.cota : "-",
      Vencimento: item.vencimento
        ? formataDataISOParaBR(item.vencimento.toString())
        : "-",
      "Valor Total": item.total ? item.total / 100 : "-",
      Observação:
        Number(item.statusDebito) ===
        StatusSolicitacaoPagamentoEnumV2.Consultando
          ? "--"
          : item.mensagem ?? item.descricao !== "null"
          ? item.descricao
          : "--",
      Status: StatusSolicitacaoPagamentoEnumV2[item.statusDebito],
      "Tentativas Consulta": item.tentativas,
      "ID Recibo": item.idRecibo,
      CNAB: handleStatusCNAB(item.statusDebito, item.nomeCnab, item.id).texto,
      "Banco CNAB": item.nomeBanco ? item.nomeBanco : "-",
      "Código de Barras": handleExibicaoCodigoDeBarras(
        resumo?.comCodigoDeBarras,
        item.codigoDeBarras,
        item.statusDebito
      ),
    }));

    const planilha = utils.json_to_sheet(dadosPlanilha);
    planilha["!cols"] = [
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 40 },
    ];
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, planilha, "Detalhes");
    writeFileXLSX(workBook, "detalhes_solicitacao.xlsx");
    setLoadingExportar(false);
  };

  function makeIconeInfo(debito: any) {
    return (
      <IconeInfo
        statusDebito={debito.statusDebito}
        onClick={() => setRowExpandToggled(debito)}
        temMulta={debito.temMulta}
        idTipoDebito={debito.debito}
      />
    );
  }

  useEffect(() => {
    setSelectedRows([]);
  }, [dados]);

  const quantidadeCaracteresTextoLongo = 70;
  const widthStrategy = { md: 200, lg: 350 };
  const widthObservacao = `${Math.max(
    ...dados.map((item) =>
      item.descricao && item.descricao.length > quantidadeCaracteresTextoLongo
        ? widthStrategy.lg
        : widthStrategy.md
    )
  )}px`;

  const columns: IColum<IDetalhesConsulta>[] = [
    {
      name: <span>Id</span>,
      selector: ({ id }) => id,
      sortable: true,
      omit: true,
      sortField: "id",
    },
    {
      name: <span>Placa</span>,
      selector: ({ placa }) => placa.toUpperCase(),
      sortable: true,
      sortField: "placa",
    },

    {
      name: <span>Renavam</span>,
      selector: ({ renavam }) => renavam,
      width: "100px",
    },
    {
      name: <span>UF</span>,
      selector: ({ uf }) => uf.toUpperCase(),
      sortable: true,
      sortField: "uf",
      width: "80px",
    },
    {
      name: <span>Débito</span>,
      selector: ({ debito }) => TipoDebitos[debito] || "--",
      sortable: true,
      sortField: "debito",
      width: "115px",
    },
    {
      name: <span>Cota IPVA</span>,
      selector: ({ cota, cotaIpva }) =>
        !cota || (cotaIpva == 1 && cota == 8) ? "-" : cota,
      sortable: true,
      sortField: "cota",
    },
    {
      name: <span>Vencimento</span>,
      selector: ({ vencimento }) =>
        vencimento ? formataDataISOParaBR(vencimento) : "--",
      sortable: false,
      sortField: "vencimento",
      width: "125px",
    },
    {
      name: <span>Valor Total</span>,
      selector: ({ total }) => {
        const totalAtualizado = total / 100;
        return formatarDinheiro(totalAtualizado) || "--";
      },
      sortable: true,
      sortField: "total",
      width: "120px",
    },
    {
      name: <span>Observação</span>,
      selector: ({ descricao, mensagem, statusDebito, fonteDeConsulta }) =>
        Number(statusDebito) === StatusSolicitacaoPagamentoEnumV2.Consultando
          ? "--"
          : mensagem ?? descricao !== "null"
          ? `${descricao}${
              fonteDeConsulta == "MANUAL" && verificarUsuarioAwLw()
                ? " (Manual)"
                : ""
            } `
          : "--",
      width: widthObservacao,
    },
    {
      name: <span>Info</span>,
      selector: (debito: IDetalhesConsulta) => makeIconeInfo(debito),
      width: "45px",
    },

    ...(verificarUsuarioAwLw()
      ? [
          {
            name: (
              <span style={{ marginLeft: "9px" }}>
                <ConsultaManual
                  tipo="LOTE"
                  idConsulta={idConsulta}
                  setDados={setDados}
                  dados={dados}
                />
              </span>
            ),
            selector: (debito: IDetalhesConsulta) => (
              <ConsultaManual
                setDados={setDados}
                debito={debito}
                tipo="UNITARIO"
                idConsulta={idConsulta}
                dados={dados}
              />
            ),
            width: "40px",
            sortable: false,
          },
        ]
      : []),
    {
      name: <span>Status da consulta</span>,
      selector: ({ statusDebito }) => makeStatusDebitosConsulta(statusDebito),
      sortable: true,
      sortField: "status",
      width: "200px",
    },
    {
      name: <span>Tentativas consulta</span>,
      selector: ({ tentativas, statusDebito }) =>
        makeTentativasConsulta(tentativas, Number(statusDebito)),
    },
    {
      name: <span>ID Recibo</span>,
      selector: ({ idRecibo }) => idRecibo || "--",
      sortable: true,
      sortField: "idRecibo",
      width: "115px",
    },
    {
      name: <span>CNAB</span>,
      selector: ({ statusDebito, nomeCnab, id }) =>
        handleStatusCNAB(statusDebito, nomeCnab, id).elemento,
      sortable: true,
      sortField: "status",
      width: "200px",
    },
    {
      name: <span>Banco CNAB</span>,
      selector: ({ nomeBanco }) => nomeBanco || "--",
      sortable: true,
      sortField: "status",
      width: "200px",
    },
  ];

  const bottomTooltipReconsultar = 2.3;
  const rightTooltipReconsultar = -90;
  const widthTooltipReconsultar = 240;

  const bottomTooltipSolicitarPgto = 2.3;
  const rightTooltipSolicitarPgto = -110;
  const widthTooltipSolicitarPgto = 280;

  const handleBotaoAtivo = (): boolean => {
    if (resumo?.cnabBloqueado) return false;

    const ufsConsultadas = resumo?.ufsConsultadas;
    const ufsProibidas = ["PR", "SP"];
    const ufForaDaProibidaConsultada = ufsConsultadas?.some(
      (uf: string) => !ufsProibidas.includes(uf)
    );

    const email = getEmail();
    if (!email)
      return resumo?.comCodigoDeBarras === "SIM" && !resumo?.cnabBloqueado;

    const dominioEmail = email.split("@")[1];
    if (dominioEmail === Dominios.LW) {
      if (ufForaDaProibidaConsultada) return true;
    }

    return resumo?.comCodigoDeBarras === "SIM" && !resumo?.cnabBloqueado;
  };

  return (
    <Container titulo={`Detalhes Consulta ID - ${idConsulta}`}>
      <Resumo
        id={idConsulta}
        resumo={resumo}
        onClickVisualizar={onClickVisualizar}
      />
      <Table
        loadingExportar={loadingExportar}
        rowsPerPage={limit}
        exportExcel
        exportComponent={
          <ContainerBtnCNAB>
            <ExportExcel
              texto={"Exportar"}
              variant={"secondary"}
              onClick={handleExport}
              icone={FaDownload}
            />
            <BtnGerarCNAB
              ativo={handleBotaoAtivo()}
              onClick={onClickGerarCNAB}
            />
          </ContainerBtnCNAB>
        }
        buttonExportVariant="secondary"
        columns={columns}
        data={dados}
        keyField="id"
        loading={loading}
        clearSelectedRows={clearSelectedRows}
        setRowsSelected={(row) => setSelectedRows(row)}
        selectableRows={true}
        toolsHeader={
          <ButtonHeader>
            <Tooltip
              bottom={bottomTooltipReconsultar}
              right={rightTooltipReconsultar}
              width={widthTooltipReconsultar}
              trigger={
                <PrimaryButton
                  onClick={onClickReconsultar}
                  outline
                  disabled={disableReconsultar}
                  buttonWidth="lg"
                  variante="primary"
                >
                  <MdRefresh style={{ transform: "scaleX(-1)" }} /> Consultar
                  novamente
                </PrimaryButton>
              }
            >
              <TooltipContemt>
                <span>
                  Reconsultar novamente todos veículos com status de{" "}
                  <strong>falha</strong>
                </span>
              </TooltipContemt>
            </Tooltip>
            {dados.some(
              (debito) =>
                idsDebitosSelecionados.includes(debito.id) &&
                statusDesabilitarSolicitarPagamento.includes(
                  Number(debito.statusDebito)
                )
            ) ? (
              <Tooltip
                bottom={bottomTooltipSolicitarPgto}
                right={rightTooltipSolicitarPgto}
                width={widthTooltipSolicitarPgto}
                trigger={
                  <PrimaryButton onClick={() => {}} disabled>
                    <FaDollarSign /> Solicitar pagamento
                  </PrimaryButton>
                }
              >
                <TooltipContemt>
                  <span>
                    Não é possível solicitar pagamento de débitos vinculados a
                    arquivos CNAB.
                  </span>
                </TooltipContemt>
              </Tooltip>
            ) : (
              <PrimaryButton onClick={solicitarTodos}>
                <FaDollarSign /> Solicitar pagamento
              </PrimaryButton>
            )}
          </ButtonHeader>
        }
        rowToExpand={rowToExpand}
        expandableRowsComponent={ExpandedComponentConsulta}
        pagination={true}
        paginationRowsPerPageOptions={[100, 200, 300]}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        totalRows={totalRows}
        handleExport={handleExport}
      />
    </Container>
  );
}
