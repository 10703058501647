import { ModalCustom } from "components/ModalCustom";
import { DatePickerBody, DivButtons, DivTextoRegra, DivTextoSelecione, ModalBody, SpanIcon } from "./styles";
import ReactDatePicker from "react-datepicker";
import br from "date-fns/locale/pt-BR";
import { PillButton } from "components/PillButton/style";
import { FaCheck } from "react-icons/fa";
import { useMaskInput } from "hooks/useMaskInput";
import { Container } from "components/DatePicker/style";

interface Props {
  handleClose: () => void;
  show: boolean;
  onConfirm: () => void;
  setDatasExportar: (datas: (Date | null)[]) => void;
  datasExportar: (Date | null)[];
  setPressed: (dados: boolean[]) => void;
  pressed: boolean[];
  setShowTextoRegra: (dados: boolean) => void;
  showTextoRegra: boolean;
  intervalorDiasDentroLimite: (startDate: Date, endDate: Date) => boolean;
}

export function ModalPeriodoExportar({
  handleClose,
  onConfirm,
  show,
  setDatasExportar,
  datasExportar,
  setPressed,
  pressed,
  setShowTextoRegra,
  showTextoRegra,
  intervalorDiasDentroLimite,
}: Readonly<Props>) {
  const onClickDias = (dias: number) => {
    const hoje = new Date();
    const diasAtras = new Date();
    diasAtras.setDate(hoje.getDate() - dias);
    setDatasExportar([diasAtras, hoje]);
    setShowTextoRegra(false);
  }
  const { mDataRangeInput } = useMaskInput();
  function handleDataDigitada(value: string) {
    const dateRangePadrao = /^\d{2}\/\d{2}\/\d{4}-\d{2}\/\d{2}\/\d{4}$/;
    if (value && dateRangePadrao.test(value.replaceAll(" ", ""))) {
      const datas = value
        .replaceAll(" ", "")
        .split("-")
        .map((item: string) => {
          const partes = item.split("/");
          return new Date(
            Number(partes[2]),
            Number(partes[1]) - 1,
            Number(partes[0])
          );
        });

      return datas;
    }
  }
  const onChangePeriodoConsulta = (update: (Date | null)[]) => {
    const [startDate, endDate] = update;
    setDatasExportar([startDate, endDate]);
    setPressed([false, false, false, false])
    if (startDate && endDate && intervalorDiasDentroLimite(startDate, endDate)) {
      setShowTextoRegra(false);
      return;
    }
    setShowTextoRegra(true);
  }

  return (
    <ModalCustom
      title="Exportar Excel"
      handleClose={handleClose}
      show={show}
      centered
      footer
      footerConfirmButton
      onConfirm={onConfirm}
      disabledConfirmButton={!datasExportar[0] || !datasExportar[1] || showTextoRegra}
    >
      <ModalBody>
        <DivTextoSelecione>Selecione o período que você deseja exportar o relatório.</DivTextoSelecione>
        <DivButtons>
          <PillButton pressed={pressed[0]} onClick={() => { setPressed([true, false, false, false]); onClickDias(15); }}><SpanIcon show={pressed[0]}><FaCheck /></SpanIcon> 15 dias</PillButton>
          <PillButton pressed={pressed[1]} onClick={() => { setPressed([false, true, false, false]); onClickDias(30); }}><SpanIcon show={pressed[1]}><FaCheck /></SpanIcon> 30 dias</PillButton>
          <PillButton pressed={pressed[2]} onClick={() => { setPressed([false, false, true, false]); onClickDias(60); }}><SpanIcon show={pressed[2]}><FaCheck /></SpanIcon> 60 dias</PillButton>
          <PillButton pressed={pressed[3]} onClick={() => { setPressed([false, false, false, true]); onClickDias(90); }}><SpanIcon show={pressed[3]}><FaCheck /></SpanIcon> 90 dias</PillButton>
        </DivButtons>
        <DatePickerBody>
          Outro período
          <Container>
            <ReactDatePicker
              className={`form-control${showTextoRegra ? " border-danger" : ""}`}
              dateFormat="dd/MM/yyyy"
              locale={br}
              placeholderText="Selecione"
              startDate={datasExportar ? datasExportar[0] : null}
              endDate={datasExportar ? datasExportar[1] : null}
              selectsRange={true}
              onChange={(update) => {
                onChangePeriodoConsulta(update)
              }}
            />
          </Container>
          <DivTextoRegra show={showTextoRegra}>O período selecionado deve ser inferior a 90 dias</DivTextoRegra>
        </DatePickerBody>
      </ModalBody>
    </ModalCustom>
  );
}
