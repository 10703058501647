import styled from 'styled-components';

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface InputCustomizadoContainerProps {
  disabled: boolean;
}

export const InputDeDataCustomizadoContainer = styled.div<InputCustomizadoContainerProps>`
  display: flex;
  align-items: center;
  background-color: ${({ disabled }) => disabled ? '#F5F5F5' : 'white'};
  border: 1px solid #7F7F7F;
  min-height: 32px;
  max-height: 32px;
  padding: 0.2rem 1rem;
`

export const InputDeDataCustomizadoPlaceholder = styled.span`
  width: 95%;
  font-size: 14px;
  color: #7F7F7F;
`;

export const InputDeDataCustomizadoDataSpan = styled.span`
  width: 95%;
  color: #333333;
  font-size: 14px;
`;

export const ModalBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ModalTitulo = styled.div`
  margin-bottom: 30px;
`;

export const ModalTituloSpan = styled.span`
  color: #666666;
`

export const ModalMultiInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: calc(30px - 1rem);
`

export const ModalMultiInputSpan = styled.span`
  font-size: 14px;
  color: #1A1A1A;
  margin-bottom: 4px;
`;

export const ModalDatePicker = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

export const ModalDatePickerSpan = styled.div`
  font-size: 14px;
  color: #1A1A1A;
  margin-bottom: 4px;
`;

export const ModalEmpresaSelect = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const ModalEmpresaSpan = styled.div`
  font-size: 14px;
  color: #1A1A1A;
  margin-bottom: 4px;
`;

interface ModalBotaoConfirmarProps {
  disabled: boolean;
}

export const ModalBotaoConfirmar = styled.button<ModalBotaoConfirmarProps>`
  width: 87px;
  height: 32px;
  background-color: ${({ disabled }) => disabled ? 'gray' : '#333333'};
  border: ${({ disabled }) => disabled ? '1px solid gray' : 'none'};
  color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-family: 'Roboto';
  font-size: 14px;
`;

export const ModalBotaoCancelar = styled.button`
  width: 87px;
  height: 32px;
  border: 1px solid #333333;
  border-radius: 5px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-family: 'Roboto';
  font-size: 14px;
`;

export const ModalFooterBotoes = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 20px;
`;
