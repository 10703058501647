import { FaCircle } from "react-icons/fa";
import { Container } from "./style";

interface Props {
  status: "enviado" | "processando" | "falha" | "desabilitar";
  tamanho: string;
  margin?: string;
}

export function IconeStatusCNAB({ status, tamanho, margin = "10px" }: Props) {
  const strategyCor = {
    enviado: "sucesso",
    processando: "processando",
    falha: "falha",
    desabilitar: "desabilitar",
  };
  return (
    <Container
      cor={
        strategyCor[status] as
          | "sucesso"
          | "processando"
          | "falha"
          | "desabilitar"
      }
      tamanho={tamanho}
      margin={margin}
    >
      <FaCircle />
    </Container>
  );
}
