import { Tooltip } from "components/Tooltip";
import { DadosDestinatarios } from "../../Interfaces/DadosVisualizarCnabs";
import { formataDataISOParaBR } from "_utils/Data";
import { handleStatusCnab } from "../../../../Constants/handleStatusCnab";

interface Props {
  dados: DadosDestinatarios[];
  statusCnab: string;
}

const maxIndiceRgb = 170;
const getCor = (index: number, total: number) => {
  const indiceRgb = Math.floor(
    ((total - index - 1) / (total - 1)) * maxIndiceRgb
  );
  return `rgb(${indiceRgb}, ${indiceRgb}, ${indiceRgb})`;
};

const getCorStatusEnvio = (status: string, lido: string | null) => {
  if (status !== "enviado") {
    return;
  }

  return (
    <div
      style={{
        position: "absolute",
        top: "-2px",
        right: "1px",
        width: "7px",
        height: "7px",
        borderRadius: "50%",
        backgroundColor: lido ? "#10AC85" : "#FE8F14",
      }}
    />
  );
};

export function IconesEnviarPara({ dados, statusCnab }: Props) {
  const bottomTooltip = 1.5;
  const rightTooltip = -750;
  const widthTooltip = 320;
  return (
    <div style={{ display: "flex", justifyContent: "left", flexWrap: "wrap" }}>
      {dados
        .filter((pessoa) => pessoa.email)
        .map((pessoa, index) => {
          return (
            <Tooltip
              bottom={bottomTooltip}
              right={rightTooltip}
              width={widthTooltip}
              trigger={
                <div
                  style={{
                    width: "21px",
                    height: "21px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor:
                      dados.length > 1
                        ? getCor(index, dados.length)
                        : `rgb(${maxIndiceRgb},${maxIndiceRgb},${maxIndiceRgb})`,
                    color: "white",
                    fontSize: "12px",
                    marginLeft: index === 0 ? 0 : "-2px",
                    zIndex: dados.length - index,
                  }}
                >
                  {pessoa.email.charAt(0).toLocaleUpperCase()}
                  {getCorStatusEnvio(
                    handleStatusCnab(statusCnab),
                    pessoa.dataLeitura
                  )}
                </div>
              }
            >
              <div
                style={{
                  color: "white",
                  backgroundColor: "#333333",
                  padding: "8px",
                  borderRadius: "5px",
                  textAlign: "left",
                  fontSize: 14,
                }}
              >
                <div>
                  <strong>{pessoa.email}</strong>
                  {handleStatusCnab(statusCnab) === "enviado" && (
                    <>
                      <div
                        style={{ fontSize: 12, marginBottom: 6, marginTop: 6 }}
                      >
                        Lida em
                      </div>
                      <div>
                        <strong>
                          {pessoa.dataLeitura
                            ? formataDataISOParaBR(pessoa.dataLeitura)
                            : "-"}
                        </strong>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Tooltip>
          );
        })}
    </div>
  );
}
