import { StatusConsulta } from "components/StatusConsulta";
import { Table } from "containers/Table";
import { IColum } from "containers/Table/interfaces";
import { Container } from "components/Container";
import { converterDataHoraBr } from "_utils/Data";
import { ISolicitacaoConsulta } from "interfaces/SolicitacaoConsulta";
import { Consultar } from "features/debito/consulta/enum/Consultar";
import { CotaIpva } from "features/debito/consulta/enum/CotaIpva";
import { RedirectDetalhes } from "components/RedirectDetalhes";

interface IListarConsultasProps {
  dados: any[];
  loading: boolean;
  totalRows?: number;
  rowsPerPage: number;
  onChangeRowsPerPage?: (limit: number) => void;
  onChangePage?: (page: number) => void;
}

export function ListarConsultas({
  dados,
  loading,
  totalRows,
  rowsPerPage,
  onChangeRowsPerPage,
  onChangePage,
}: IListarConsultasProps) {
  function makeTipoDebito(ipva: string, licenciamento: string) {
    let debitos = [];

    if (ipva === Consultar.SIM) {
      debitos.push("IPVA");
    }

    if (licenciamento === Consultar.SIM) {
      debitos.push("Licenciamento");
    }
    return debitos.join(", ");
  }

  const columns: IColum<ISolicitacaoConsulta>[] = [
    {
      name: <span>ID Consulta</span>,
      selector: (consulta: ISolicitacaoConsulta) => consulta.id,
      sortable: true,
      sortField: "id",
      width: "112px",
    },
    {
      name: <span>Tipo de débito</span>,
      selector: ({ ipva, licenciamento }: ISolicitacaoConsulta) => makeTipoDebito(ipva, licenciamento),
      sortable: true,
      sortField: "id",
      width: "150px",
    },
    {
      name: <span>Cota IPVA</span>,
      selector: (consulta: ISolicitacaoConsulta) => CotaIpva[consulta.cotaIpva],
      sortable: true,
      sortField: "cotaIpva",
      width: "75px",
    },
    {
      name: <span>Data / Hora solicitação</span>,
      selector: (consulta: ISolicitacaoConsulta) => converterDataHoraBr(consulta.dataHora),
      sortable: true,
      width: "192px",
      sortField: "dataHora",
    },
    {
      name: <span>Descrição</span>,
      selector: (consulta: ISolicitacaoConsulta) => consulta.descricao,
      width: "200px",
    },
    {
      name: <span>Empresa</span>,
      selector: (consulta: ISolicitacaoConsulta) => consulta.empresa,
      sortable: true,
      sortField: "empresa",
      width: "150px",
    },
    {
      name: <span>Usuário</span>,
      selector: (consulta: ISolicitacaoConsulta) => consulta.nomeUsuario,
      width: "130px",
    },
    {
      name: <span>Placas solicitadas</span>,
      selector: (consulta: ISolicitacaoConsulta) => consulta.qtdPlacasSolicitadas,
    },
    {
      name: <span>Placas consultadas</span>,
      selector: (consulta: ISolicitacaoConsulta) => consulta.qtdPlacasConsultadas,
    },
    {
      name: <span>Débitos disponíveis</span>,
      selector: (consulta: ISolicitacaoConsulta) => consulta.qtdDebitosDisponiveis,
    },
    {
      name: <span>Pagamentos solicitados</span>,
      width: "110px",
      selector: (consulta: ISolicitacaoConsulta) => consulta.qtdPlacasSolicitadoPagamento,
    },
    {
      name: <span>Débitos em CNAB</span>,
      width: "110px",
      selector: (consulta: ISolicitacaoConsulta) => consulta.qtdDebitosCnab ?? 0,
    },
    {
      name: <span>Status consulta</span>,
      selector: (consulta: ISolicitacaoConsulta) => (
        <StatusConsulta
          statusConsulta={consulta.status}
        />
      ),
      width: "120px",
    },
    {
      name: "Detalhes",
      selector: (consulta: ISolicitacaoConsulta) => (
        <div style={{width: '70px'}}>
          <RedirectDetalhes path={`/debito/consulta/${consulta.id}`} />
        </div>
      ),
      width: "80px",
    },
  ];

  return (
    <Container titulo="Listagem de Consultas Realizadas">
      <Table
        keyField="id"
        totalRows={totalRows}
        pagination={true}
        columns={columns}
        data={dados}
        rowsPerPage={rowsPerPage}
        paginationRowsPerPageOptions={[10, 20, 50]}
        loading={loading}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
      />
    </Container>
  );
}
