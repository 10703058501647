import { Table } from "containers/Table";
import { IColum } from "containers/Table/interfaces";
import { useEffect, useState } from "react";
import { ContainerToggleVisualizacao, TooltipContentContainer } from "./style";
import { CompositeContainer } from "components/CompositeContainer";
import { PrimaryButton } from "components/Button/style";
import { MdRefresh } from "react-icons/md";
import { SemPendencia } from "../SemPendencia";
import { ComPendencia } from "../ComPendencia";
import { FalhaNaConsulta } from "../FalhaNaConsulta";
import { Consultando } from "../Consultando";
import { converterDataHoraBr, Data, formataDataISOParaBR } from "_utils/Data";
import { StatusConsultaExtratoVeicular } from "../StatusConsultaExtratoVeicular";
import { AcoesExtratoVeicular } from "../AcoesExtratoVeicular";
import { StatusExtratoVeicular } from "../StatusConsultaExtratoVeicular/style";
import { formatarDinheiro, intlCurrencyFormat } from "_utils/Money";
import { ModalReconsultarFalhasExtratoVeicular } from "../ModalReconsultarFalhasExtratoVeicular";
import { useParams } from "react-router-dom";
import { useDetalhesExtratoVeicular } from "../../hooks/useDetalhesExtratoVeicular";
import { Tooltip } from "components/Tooltip";
import { Stronger } from "components/Stronger";
import { utils, writeFileXLSX } from "xlsx";
import { TooltipTentativas } from "../TooltipTentativas";
import { ToggleVisualizacaoDetalhesExtratoVeicular } from "../ToggleVisualizacaoDetalhesExtratoVeicular";
import { IHttpResponse } from "_services/api/interface/HttpResponse";
import { NaoPertenceAoCombo } from "../NaoPertenceAoCombo";
import { ExportExcel } from "components/ExportExcel";
import { OpcoesExportarExcel } from "../../../Listar/containers/OpcoesExportarExcel";
import { FaDownload } from "react-icons/fa";
import { RetornoDetalhesExtrato } from "../../../Listar/interfaces/IRelatorioExtratoVeicular";
import { useMask } from "hooks/useMask";

export interface IDadosDetalhado {
  idLoteExtratoVeicular: number;
  idExtratoVeicular: number;
  placa: string;
  renavam: string;
  chassi: string;
  ipvas: string;
  licenciamentos: string;
  multas: string;
  gravames: string;
  restricoes: string;
  recalls: string;
  leiloes: string;
  dataHoraCadastro: string;
  statusExtratoVeicular: string;
  tentativasConsulta: number;
  dataHoraUltimaConsulta: string;
  observacao: string;
}

export interface IDadosUnitario {
  placa: string;
  renavam: string;
  chassi: string;
  tipoPendencia: string;
  dataVencimento: string;
  valor: number;
  status: string;
  tentativasConsulta: number;
  observacao: string;
}

interface ListarDetalhesExtratoVeicularProps {
  dados: IDadosDetalhado[] | IDadosUnitario[];
  buscarDadosDetalhado: () => Promise<void>;
  buscarDadosUnitarios: () => Promise<void>;
  handlePageChange: (page: number) => void;
  handleLimitChange: (limit: number) => void;
  totalRows: number;
  rowsPerPage: number;
  loading: boolean;
  setBuscarPor: (buscarPor: "DETALHADOS" | "UNITARIOS") => void;
  reconsultarFalhas: (loteExtratoVeicularId: string) => Promise<IHttpResponse>;
}

export function handleStatusConsulta(resultadoItem: string) {
  if (resultadoItem === "Consultando") {
    return <Consultando />;
  }

  if (resultadoItem === "Falha" || resultadoItem === "Dados inconsistentes") {
    return <FalhaNaConsulta />;
  }

  if (resultadoItem === "Nada consta") {
    return <SemPendencia />;
  }

  if (resultadoItem === "NAO_PERTENCE_AO_COMBO") {
    return <NaoPertenceAoCombo />;
  }
  return <ComPendencia />;
}

export function gerarColunasDetalhado() {
  return [
    {
      name: <span>Placa</span>,
      selector: ({ placa }: IDadosDetalhado) => placa?.toUpperCase() ?? "-",
      width: "80px",
    },
    {
      name: <span>Renavam</span>,
      selector: ({ renavam }: IDadosDetalhado) => renavam?.toUpperCase() ?? "-",
      width: "100px",
    },
    {
      name: <span>Chassi</span>,
      selector: ({ chassi }: IDadosDetalhado) => chassi?.toUpperCase() ?? "-",
    },
    {
      name: <span>IPVA</span>,
      selector: ({ ipvas }: IDadosDetalhado) => handleStatusConsulta(ipvas),
      width: "70px",
    },
    {
      name: <span>Lic</span>,
      selector: ({ licenciamentos }: IDadosDetalhado) =>
        handleStatusConsulta(licenciamentos),
      width: "60px",
    },
    {
      name: <span>Multa</span>,
      selector: ({ multas }: IDadosDetalhado) => handleStatusConsulta(multas),
      width: "70px",
    },
    {
      name: <span>Gravame</span>,
      selector: ({ gravames }: IDadosDetalhado) =>
        handleStatusConsulta(gravames),
      width: "85px",
    },
    {
      name: <span>Restrições</span>,
      selector: ({ restricoes }: IDadosDetalhado) =>
        handleStatusConsulta(restricoes),
      width: "100px",
    },
    {
      name: <span>Recall</span>,
      selector: ({ recalls }: IDadosDetalhado) => handleStatusConsulta(recalls),
      width: "70px",
    },
    {
      name: <span>Leilão</span>,
      selector: ({ leiloes }: IDadosDetalhado) => handleStatusConsulta(leiloes),
      width: "70px",
    },
    {
      name: <span>Data/Hora da consulta</span>,
      selector: ({
        dataHoraUltimaConsulta,
        statusExtratoVeicular,
      }: IDadosDetalhado) =>
        statusExtratoVeicular !== "Consultando"
          ? converterDataHoraBr(new Date(dataHoraUltimaConsulta))
          : "-",
    },
    {
      name: <span>Status da consulta</span>,
      selector: ({ statusExtratoVeicular }: IDadosDetalhado) => (
        <StatusConsultaExtratoVeicular statusConsulta={statusExtratoVeicular} />
      ),
      width: "170px",
    },
    {
      name: <span>Tentativas de consulta</span>,
      selector: ({
        tentativasConsulta,
        statusExtratoVeicular,
        observacao,
      }: IDadosDetalhado) => (
        <TooltipTentativas
          statusExtratoVeicular={statusExtratoVeicular}
          tentativas={tentativasConsulta}
          mensagemErro={observacao}
        />
      ),
      width: "190px",
    },
    {
      name: <span>Ações</span>,
      selector: ({
        idExtratoVeicular,
        idLoteExtratoVeicular,
        statusExtratoVeicular,
      }: IDadosDetalhado) => (
        <AcoesExtratoVeicular
          idExtratoVeicular={idExtratoVeicular}
          statusExtratoVeicular={statusExtratoVeicular}
          idLoteExtratoVeicular={idLoteExtratoVeicular}
          resumoLote={true}
        />
      ),
      width: "90px",
    },
  ] as IColum<IDadosDetalhado>[];
}

function gerarColunasUnitarios() {
  return [
    {
      name: "Placa",
      selector: ({ placa }: IDadosUnitario) => placa?.toUpperCase() ?? "-",
    },
    {
      name: "Renavam",
      selector: ({ renavam }: IDadosUnitario) => renavam?.toUpperCase() ?? "-",
    },
    {
      name: "Chassi",
      selector: ({ chassi }: IDadosUnitario) => chassi?.toUpperCase() ?? "-",
    },
    {
      name: "Tipo de pendência",
      selector: ({ tipoPendencia }: IDadosUnitario) => tipoPendencia,
    },
    {
      name: "Vencimento",
      selector: ({ dataVencimento }: IDadosUnitario) =>
        dataVencimento ? formataDataISOParaBR(dataVencimento) : "-",
    },
    {
      name: "Valor",
      selector: ({ valor }: IDadosUnitario) =>
        valor ? intlCurrencyFormat(valor / 100) : "-",
    },
    {
      name: "Status da consulta",
      selector: ({ status }: IDadosUnitario) => (
        <StatusConsultaExtratoVeicular
          statusConsulta={
            status === "Sucesso" ? StatusExtratoVeicular.COM_PENDENCIA : status
          }
        />
      ),
    },
    {
      name: "Tentativas de consulta",
      selector: ({
        tentativasConsulta,
        status,
        observacao,
      }: IDadosUnitario) => (
        <TooltipTentativas
          statusExtratoVeicular={status}
          tentativas={tentativasConsulta}
          mensagemErro={observacao}
        />
      ),
    },
  ] as IColum<IDadosUnitario>[];
}

export function ListarDetalhesExtratoVeicular({
  buscarDadosDetalhado,
  buscarDadosUnitarios,
  dados,
  handleLimitChange,
  handlePageChange,
  totalRows,
  rowsPerPage,
  loading,
  setBuscarPor,
  reconsultarFalhas,
}: Readonly<ListarDetalhesExtratoVeicularProps>) {
  const [exibir, setExibir] = useState<"DETALHADOS" | "UNITARIOS">(
    "DETALHADOS"
  );
  const [colunas, setColunas] = useState<any[]>([]);
  const [
    showModalSucessoReconsultarFalhas,
    setShowModalSucessoReconsultarFalhas,
  ] = useState<boolean>(false);

  const { buscarDadosPlanilhaUnitarios, buscarDadosRelatorio } =
    useDetalhesExtratoVeicular();

  const { id: loteExtratoVeicularId } = useParams();

  useEffect(() => {
    setColunas(gerarColunasDetalhado());
  }, []);

  const { mCPFCNPJ, } = useMask();

  function handleStatusExcel(statusConsulta: string, valor: any) {
    const STATUS_MOSTRAR = ["Consultando", "Falha", "Dados inconsistentes"];

    if (STATUS_MOSTRAR.includes(statusConsulta)) {
      return statusConsulta;
    }
  
    const statusSucesso = "Sucesso";
    const statusNaoConsultado = "NAO_PERTENCE_AO_COMBO";
  
    if (statusConsulta === statusNaoConsultado) {
      return "-";
    }
  
    if (statusConsulta === statusSucesso && !valor) {
      return "-";
    }
  
    if (typeof valor !== "number" && !valor) {
      return "-";
    }
  
    if (!Number.isNaN(Number(valor))) {
      if (valor > 0) {
        return formatarDinheiro(valor / 100);
      }
  
      if (valor <= 0) {
        return "Nada consta";
      }
    }
  
    return valor;
  }

  async function  handleExportExtratosDetalhes() {

    const data = await buscarDadosRelatorio();

    const idLote = data[0]?.dadosExtrato?.idLoteExtratoVeicular;

    const dadosPlanilha = data.map((item: RetornoDetalhesExtrato) => ({
      "Id Lote": item.dadosExtrato.idLoteExtratoVeicular ?? "-",
      "Dados do veículo - Placa": item.dadosExtrato.placa ?? "-",
      "Dados do veículo - Chassi": item.dadosExtrato.chassi ?? "-",
      "Dados do veículo - Renavam": item.dadosExtrato.renavam ?? "-",
      "Dados do veículo - Situação": item.dadosExtrato.situacao ?? "-",
      "Dados do veículo - Município": item.dadosExtrato.municipio ?? "-",
      "Dados do veículo - Marca/Modelo": item.dadosExtrato.marcaModelo ?? "-",
      "Dados do veículo - Ano fab/Ano Mod": item.dadosExtrato.ano ?? "-",
      "Dados do veículo - Cor": item.dadosExtrato.cor ?? "-",
      "Dados do veículo - Espécie": item.dadosExtrato.especie ?? "-",
      "Dados do veículo - Categoria": item.dadosExtrato.categoria ?? "-",
      "Dados do veículo - Combustível": item.dadosExtrato.combustivel ?? "-",
      "Dados do veículo - Número motor": item.dadosExtrato.codigoMotor ?? "-",
      "Dados do veículo - Situação Chassi": item.dadosExtrato.situacaoChassi ?? "-",
      "Ipva": handleStatusExcel(item.dadosExtrato.statusIpvas, item.dadosExtrato.ipva),
      "Licenciamento": handleStatusExcel(item.dadosExtrato.statusLicenciamentos, item.dadosExtrato.licenciamento),
      "Multas - Total": handleStatusExcel(item.dadosExtrato.statusMultas, item.multas.total),
      "Restrições": handleStatusExcel(item.dadosExtrato.statusRestricoes, item.bloqueios),
      "Gravame - Nome da financeira": handleStatusExcel(item.dadosExtrato.statusGravames, item.gravames.nomeFinanceira),
      "Gravame - Data Contrato": handleStatusExcel(item.dadosExtrato.statusGravames, item.gravames.dataContrato),
      "Gravame - Situação": handleStatusExcel(item.dadosExtrato.statusGravames, item.gravames.situacao),
      "Recall - Identificador": handleStatusExcel(item.dadosExtrato.statusRecalls, item.recalls.identificador),
      "Recall - Data Registro": handleStatusExcel(item.dadosExtrato.statusRecalls, item.recalls.dataRegistro ? new Data(item.recalls.dataRegistro).getDataBr() : null),
      "Leilão": item.leiloes,
      "Proprietário - Nome": item.dadosExtrato.nomeProprietario ? item.dadosExtrato.nomeProprietario.toLocaleUpperCase() : "-",
      "Proprietário - Numero documento": item.dadosExtrato.cpfCnpjProprietario ? mCPFCNPJ(item.dadosExtrato.cpfCnpjProprietario) : "-",
    }));

    const planilha = utils.json_to_sheet(dadosPlanilha);
    planilha["!cols"] = [
      { wch: 15 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      { wch: 30 },
      { wch: 30 },
      { wch: 25 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 20 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
    ];
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, planilha, "Dados");
    if (idLote) {
      writeFileXLSX(workBook, `Detalhes do lote ${idLote}.xlsx`);
    } else {
      writeFileXLSX(workBook, "Detalhes da consulta.xlsx");
    }
  };


  function handleTrocarTabela() {
    if (exibir === "DETALHADOS") {
      setExibir("UNITARIOS");
      setBuscarPor("UNITARIOS");
      setColunas(gerarColunasUnitarios());
      buscarDadosUnitarios();
      return;
    }

    setExibir("DETALHADOS");
    setBuscarPor("DETALHADOS");
    setColunas(gerarColunasDetalhado());
    buscarDadosDetalhado();
  }

  async function handleReconsultarFalhas() {
    const { hasErro } = await reconsultarFalhas(loteExtratoVeicularId!);

    if (hasErro) {
      return;
    }

    setShowModalSucessoReconsultarFalhas(true);
  }

  async function handleExportUnitarios() {
    const dados = await buscarDadosPlanilhaUnitarios();

    if (!dados.length) {
      return;
    }

    const dadosPlanilha = dados.map((item: any) => ({
      Placa: item.placa?.toUpperCase() ?? "-",
      Renavam: item.renavam?.toUpperCase() ?? "-",
      Chassi: item.chassi?.toUpperCase() ?? "-",
      "Tipo de pendência": item.tipoPendencia ?? "-",
      Vencimento: item.dataVencimento
        ? formataDataISOParaBR(item.dataVencimento)
        : "-",
      Valor: item.valor ? intlCurrencyFormat(item.valor / 100) : "-",
      "Status da consulta": item.status,
      "Tentativas de consulta": item.tentativasConsulta,
    }));
    const planilha = utils.json_to_sheet(dadosPlanilha);
    planilha["!cols"] = [
      { wch: 10 },
      { wch: 15 },
      { wch: 20 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];

    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, planilha, "Detalhes");
    writeFileXLSX(workBook, "Detalhes Extrato Veicular.xlsx");

    setExportarAberto(false);
  }

  const bottomTooltipReconsultar = 2.3;
  const rightTooltipReconsultar = -90;
  const widthTooltipReconsultar = 240;

  const [exportarAberto, setExportarAberto] = useState(false);

  return (
    <CompositeContainer.Root>
      <CompositeContainer.Header>
        <CompositeContainer.Titulo
          text={`Detalhes Consulta ID ${loteExtratoVeicularId?.replace(
            ",",
            ", "
          )}`}
        />
      </CompositeContainer.Header>
      <CompositeContainer.Body>
        <Table
          columns={colunas}
          data={dados}
          keyField="id"
          loading={loading}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          totalRows={totalRows}
          toolsHeader={
            <ContainerToggleVisualizacao>
              <Tooltip
                trigger={
                  <PrimaryButton
                    type="button"
                    onClick={handleReconsultarFalhas}
                    variante="primary"
                    outline
                  >
                    <MdRefresh style={{ transform: "scaleX(-1)" }} /> Consultar
                    novamente
                  </PrimaryButton>
                }
                bottom={bottomTooltipReconsultar}
                right={rightTooltipReconsultar}
                width={widthTooltipReconsultar}
              >
                <TooltipContentContainer>
                  <span>
                    Consultar novamente placa(s) com status de{" "}
                    <Stronger>Falha</Stronger>
                  </span>
                </TooltipContentContainer>
              </Tooltip>
            </ContainerToggleVisualizacao>
          }
          exportExcel
          exportComponent={
              <div>
                <ExportExcel
                  texto={"Exportar"}
                  variant={"secondary"}
                  onClick={() => {
                    setExportarAberto(!exportarAberto);
                  }}
                  icone={FaDownload}
                  onClickToggleVisualizacao={() => {}}
                  showToggleVisualizacao
                  toggleComponent={<div />}
                />
                <OpcoesExportarExcel
                  aberto={exportarAberto}
                  onClickListagem={handleExportUnitarios}
                  onClickDetalhes={handleExportExtratosDetalhes}
                  showOpcaoListagem
                  loading={false}
                  opcaoConsultas="Listagem da consulta"
                  opcaoDetalhes="Detalhes da consulta"
                />
              </div>
          }
          buttonExportVariant="secondary"
          showToggleVisualizacao
          onClickToggleVisualizacao={handleTrocarTabela}
          toggleComponent={
            <ToggleVisualizacaoDetalhesExtratoVeicular
              visualizacaoAtual={exibir}
            />
          }
          pagination
          rowsPerPage={rowsPerPage}
        />
      </CompositeContainer.Body>
      <ModalReconsultarFalhasExtratoVeicular
        show={showModalSucessoReconsultarFalhas}
        handleClose={() => setShowModalSucessoReconsultarFalhas(false)}
      />
    </CompositeContainer.Root>
  );
}
