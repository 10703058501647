import { FaEye } from "react-icons/fa";
import { ContainerBotaoVisualizar } from "../../style";

interface Props {
  onClick: (idConsulta: number) => void;
  idConsulta: number;
  quantidade: number;
}
export function BotaoVisualizar({ onClick, idConsulta, quantidade }: Props) {
  return (
    <ContainerBotaoVisualizar
      onClick={quantidade ? () => onClick(idConsulta) : () => {}}
      quantidade={quantidade}
    >
      <FaEye style={{ margin: "0 4px 2px 0" }} />
      Visualizar
    </ContainerBotaoVisualizar>
  );
}
