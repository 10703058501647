import { Col } from "react-bootstrap";
import styled from "styled-components";

export const RadioContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const RadioCustomCol = styled(Col)`
  min-width: 50px;
  max-width: 190px;
  width: 190px;
`;
