import styled from "styled-components";

interface IContainerProps {
  cor: "sucesso" | "processando" | "falha" | "desabilitar";
  tamanho: string;
  margin: string;
}

const textColorStrategy = {
  processando: "#FF9F42",
  sucesso: "#10AC85",
  falha: "#EE5353",
  desabilitar: "#7F7F7F",
};

export const Container = styled.span<IContainerProps>`
  svg {
    width: ${({ tamanho }) => tamanho};
    height: ${({ tamanho }) => tamanho};
    color: ${({ cor }) => textColorStrategy[cor]};
    margin-right: ${({ margin }) => margin};
  }
`;
