import styled from "styled-components";

export const ContainerCinza = styled.div`
  padding: 24px 16px;
  background-color: #f5f5f5;
  border-radius: 10px;
`;

export const ContainerDadosGerais = styled.div`
  margin: 32px 0;
`;

export const ContainerAvisoHaVinculo = styled.div`
  margin-bottom: 32px;
`;

export const TituloInfoCnabsVinculados = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
`;

export const ContainerTabelaCnabsVinculados = styled.div`
  padding: 16px 16px 16px 8px;
`;

export const ContainerInfoStatusCnab = styled.div`
  margin-bottom: 32px;
`;

export const ContainerAvisoCnabFalha = styled.div`
`;