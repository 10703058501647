import styled from "styled-components";

export const Container = styled.div`
  padding: 26px 13px 26px 13px;
  display: grid;
  gap: 0.5rem;
`;

export const ResumoContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral.neutral05};
  padding: 2rem;
  display: grid;
  gap: 2rem;
  border-radius: 0.5rem;
`;

export const DadosContainer = styled.div`
  display: grid;
  font-size: 14px;
`;

export const TextoNegrito = styled.span`
  font-weight: 600;
  margin-top: 8px;
`;

export const Titulo = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.neutral80};
  font-size: 16px;
  padding: 12px 16px 12px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.neutral10};
`;

export const DivModal = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 5px 3px 5px 2px rgba(0, 0, 0, 0.15);
`;
