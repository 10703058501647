import styled from "styled-components";

export const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ConsultaLoteContainer = styled.div`
  margin-top: 1rem;
  display: grid;
  gap: 1.5rem;

  .baixarExcell {
    color: ${({ theme }) => theme.colors.primary.main};
    justify-self: end;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.primary.hover};
    }

    svg {
      margin-left: 0.5rem;
    }
  }
`;

export const UploadContainer = styled.div`
  display: grid;
  gap: 1rem;
`;

interface IInputTitle {
  desativado?: boolean;
}

export const InputTitle = styled.span<IInputTitle>`
  color: ${({ desativado }) => (desativado ? "#9f9f9f" : "")};
`;

export const InfoContainer = styled.div`
  width: 100%;
  background-color: #F5F5F5;
  display: flex;
  padding: 10px;
  border-radius: 0.5rem;
  align-items: center;
`;

export const InfoSwitchContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;
