import { yupResolver } from "@hookform/resolvers/yup"
import { IRequisicaoEmissaoCliente } from "features/emissao-cliente/interfaces/IRequisicaoEmissaoCliente"
import { emitirLicenciamentoClienteSchema } from "features/emissao-cliente/validate/validator"
import { Controller, useForm } from "react-hook-form"
import { HeaderBotao, HeaderBotaoContainer, HeaderContainer, HeaderLogoContainer, HeroContainer, HeroForm, HeroFormButton, HeroFormContainer, HeroFormInput, HeroFormInputError, HeroFormInputErrorWarning, HeroImage, HeroImageContainer, HeroSeparator, HeroSubtitle, HeroTitle, MainContainer, PrimeiroFooterContainer, SegundoFooterContainer, SegundoFooterItem, SegundoFooterItemLogo, SegundoFooterLogo, TercoDeFooter, TercoDeFooterContainer, TercoDeFooterH1, TercoDeFooterH2, TercoDeFooterIcone } from "./style"

import logo from "_assets/images/logo-lw-debitos.png";
import logoBranca from "_assets/images/lw-debitos-branco.png";
import linkedinBranco from "_assets/images/linkedin-branco.png";
import linkBranco from "_assets/images/link-branco.png";
import instagramBranco from "_assets/images/instagram-branco.png";
import facebookBranco from "_assets/images/facebook-branco.png";
import error from "_assets/images/erro-vermelho.svg";
import logoCinza from "_assets/images/logo-grupo-lw-cinza.png";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { useDownloadCrlvs } from "features/emissao-cliente/hooks/useDownloadCrlv"
import { useMaskInput } from "hooks/useMaskInput"

export function LandingPageEmissaoCliente() {
    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm<IRequisicaoEmissaoCliente>({
        resolver: yupResolver(emitirLicenciamentoClienteSchema)
    });

    const { mInputPlaca, mInputChassi } = useMaskInput();
    const { downloadCrlvs } = useDownloadCrlvs();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const onSubmit = async (data: IRequisicaoEmissaoCliente) => {
        if (!executeRecaptcha) return;
        const token = await executeRecaptcha("landingPage");
        const { response } = await downloadCrlvs(data, token);
        const objectUrl = response?.url;

        if (!objectUrl) return;

        const newWindow = window.open(objectUrl, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const validadorDePlaca = (evento: any): string => {
        evento.target.value = evento.target.value.replace(/[^a-zA-Z0-9-]/g, '');
        const placaTratada = mInputPlaca(evento);
        const temHifen = placaTratada.includes('-');

        if (temHifen) {
            return placaTratada.slice(0, 8);
        }

        return placaTratada.slice(0, 7);
    }

    const validadorDeChassi = (evento: any): string => {
        evento.target.value = evento.target.value.replace(/[^a-zA-Z0-9-]/g, '');
        return mInputChassi(evento);
    }

    return (
        <MainContainer>
            <HeaderContainer>
                <HeaderLogoContainer>
                    <img width='118px' height='28px' src={logo} alt="logo lw" />
                </HeaderLogoContainer>
                <HeaderBotaoContainer>
                    <a href={process.env.REACT_APP_BASE_URL ?? '/'} style={{
                        textDecoration: 'none',
                    }}>
                    <HeaderBotao>
                        Área do cliente
                    </HeaderBotao>
                    </a>
                </HeaderBotaoContainer>
            </HeaderContainer>
            <HeroContainer>
                <HeroSeparator>
                    <HeroFormContainer>
                        <HeroTitle>
                            Consulte e imprima o documento do seu veículo.
                        </HeroTitle>
                        <HeroSubtitle>
                            Preencha os dados abaixo para fazer a emissão grátis do CRLV do seu veículo.
                        </HeroSubtitle>
                        <HeroForm onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                name="placa"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <HeroFormInput maxLength={8} value={value} onChange={(e) => {
                                        onChange(validadorDePlaca(e));
                                    }} placeholder="Placa" />
                                )}
                            />
                            {
                                errors.placa && <HeroFormInputError>
                                    <HeroFormInputErrorWarning src={error} alt="warning" />
                                    {errors.placa.message}
                                </HeroFormInputError>
                            }
                            <Controller
                                name="chassi"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <HeroFormInput maxLength={8} minLength={8} value={value} onChange={(e) => {
                                        onChange(validadorDeChassi(e));
                                    }} placeholder="Últimos 8 dígitos do chassi" />
                                )}
                            />
                            {
                                errors.chassi && <HeroFormInputError>
                                    <HeroFormInputErrorWarning src={error} alt="warning" />
                                    {errors.chassi.message}
                                </HeroFormInputError>
                            }
                            <HeroFormButton type="submit">
                                Emitir CRLV
                            </HeroFormButton>
                        </HeroForm>
                    </HeroFormContainer>
                    <HeroImageContainer>
                        <HeroImage src='https://assets.lwtecnologia.com.br/lw-debitos/Frame_home_crlv.png' alt="homem no computador" />
                    </HeroImageContainer>
                </HeroSeparator>
            </HeroContainer>
            <PrimeiroFooterContainer>
                <TercoDeFooterContainer
                >
                    <TercoDeFooter style={{
                        minHeight: '132px',
                        justifyContent: 'space-around'
                    }}>
                        <div>
                            <img width='119px' height='28px' src={logoBranca} alt="logo lw" />
                        </div>
                        <div style={{
                            fontWeight: '700',
                            color: 'white',
                            fontSize: '16px',
                            lineHeight: '24px',
                            fontFamily: 'Inter',
                        }}>
                            Um produto do Grupo LW
                        </div>
                        <div>
                            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/grupolw_gestaodefrotas/">
                                <TercoDeFooterIcone src={instagramBranco} alt="link instagram" />
                            </a>
                            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/lwtecnologia">
                            <TercoDeFooterIcone src={facebookBranco} alt="link facebook" />
                            </a>
                            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/grupolw/">
                                <TercoDeFooterIcone src={linkedinBranco} alt="link linkedin" />
                            </a>
                            <a target="_blank" rel="noreferrer" href="https://lwgrupo.com.br/">
                                <TercoDeFooterIcone src={linkBranco} alt="link site" />
                            </a>
                        </div>
                    </TercoDeFooter>
                </TercoDeFooterContainer>
                <TercoDeFooterContainer>
                    <TercoDeFooter>
                        <TercoDeFooterH1>
                            Matriz
                        </TercoDeFooterH1>
                        <TercoDeFooterH2>
                            R. XV de Novembro, 621 - 2° andar
                        </TercoDeFooterH2>
                        <TercoDeFooterH2>
                            Centro, Curitiba - PR
                        </TercoDeFooterH2>
                    </TercoDeFooter>
                </TercoDeFooterContainer>
                <TercoDeFooterContainer>
                    <TercoDeFooter>
                        <TercoDeFooterH1>
                            Filial Rio de Janeiro
                        </TercoDeFooterH1>
                        <TercoDeFooterH2>
                            Rua General Olimpio, n° 00340
                        </TercoDeFooterH2>
                        <TercoDeFooterH2>
                            Santa Cruz, Rio de Janeiro - RJ
                        </TercoDeFooterH2>
                    </TercoDeFooter>
                </TercoDeFooterContainer>
            </PrimeiroFooterContainer>
            <SegundoFooterContainer>
                <SegundoFooterItem>
                    Copyright © 2024 Grupo LW
                </SegundoFooterItem>
                <SegundoFooterItem>
                    Todos os direitos reservados
                </SegundoFooterItem>
                <SegundoFooterItem>
                    <a href="#" style={{
                        color: '#009933',
                    }}>
                    Termos de uso e política de privacidade
                    </a>
                </SegundoFooterItem>
                <SegundoFooterItemLogo>
                    <SegundoFooterLogo src={logoCinza} alt="logo lw" />
                </SegundoFooterItemLogo>
            </SegundoFooterContainer>
        </MainContainer>
    )
}