import { Informacao } from "components/Informacao";
import { ContainerAvisoPodeGerarPgtosDuplicados } from "../../style";

export function AvisoPodeGerarPagamentosDuplicados() {
  return (
    <ContainerAvisoPodeGerarPgtosDuplicados>
      <Informacao
        mensagem={
          <span>
            Este débito está vinculado a mais de um arquivo CNAB, podendo gerar
            pagamentos duplicados.
          </span>
        }
        showBotaoFechar={false}
        showLabel={false}
        type="atencao"
        className="informacao"
        style={{ boxShadow: "none", margin: "10px auto", width: "100%" }}
        fontSize="14px"
      />
    </ContainerAvisoPodeGerarPgtosDuplicados>
  );
}
