import { ModalCustom } from "components/ModalCustom";
import { AvisoHaVinculoArquivo } from "./containers/AvisoHaVinculoArquivo";
import { TituloTabelaArquivosJaVinculados } from "./style";
import { Table } from "containers/Table";
import { IColum } from "containers/Table/interfaces";
import {
  converterDataHoraBr,
  formataDataISOParaBR,
  formataDataISOParaBR2,
} from "_utils/Data";
import { formatarDinheiro } from "_utils/Money";
import { TipoDebitos } from "features/debito/consulta/enum/TipoDebitos";
import { DadosDebitosJaVinculadosCNAB } from "./interfaces/DadosDebitosJaVinculadosCNAB";
import temaPadrao from "_config/temas/estilo/base";
import { FaExclamationCircle } from "react-icons/fa";
import { IconeStatusCNAB } from "../IconeStatusCNAB";
import { handleStatusCnab } from "../../Constants/handleStatusCnab";

interface Props {
  handleClose: () => void;
  show: boolean;
  dados: DadosDebitosJaVinculadosCNAB[];
  onConfirm: () => void;
  onClickVerCnabsVinculados: (id: number) => void;
  onClickColunaCnab: (id: number) => void;
}

export function ModalJaExisteCNABVinculado({
  handleClose,
  show,
  dados,
  onConfirm,
  onClickVerCnabsVinculados,
  onClickColunaCnab,
}: Readonly<Props>) {
  const handleQtdArquivosVinculados = (id: number, qtd: number) => {
    if (qtd > 1) {
      return (
        <FaExclamationCircle
          style={{ color: "red", cursor: "pointer" }}
          onClick={() => onClickVerCnabsVinculados(id)}
        />
      );
    }
    return "";
  };

  const columns: IColum<DadosDebitosJaVinculadosCNAB>[] = [
    {
      name: <span>Id</span>,
      selector: ({ idDebito }: DadosDebitosJaVinculadosCNAB) => idDebito,
      omit: true,
    },
    {
      name: <span>Placa</span>,
      selector: ({ placa }: DadosDebitosJaVinculadosCNAB) =>
        placa.toUpperCase(),
      width: "102px",
    },
    {
      name: <span>UF</span>,
      selector: ({ uf }: DadosDebitosJaVinculadosCNAB) => uf.toUpperCase(),
      width: "56px",
    },
    {
      name: <span>Débito</span>,
      selector: ({ idTipoDebito }: DadosDebitosJaVinculadosCNAB) =>
        TipoDebitos[idTipoDebito] || "--",
      width: "138px",
    },
    {
      name: <span>Vencimento</span>,
      selector: ({ vencimento }: DadosDebitosJaVinculadosCNAB) =>
        vencimento ? formataDataISOParaBR(vencimento) : "--",
      width: "123px",
    },
    {
      name: <span>Valor Total</span>,
      selector: ({ valor }: DadosDebitosJaVinculadosCNAB) => {
        const totalAtualizado = valor / 100;
        return formatarDinheiro(totalAtualizado) || "--";
      },
      width: "117px",
    },
    {
      name: <span>CNAB</span>,
      selector: ({
        status,
        nomeLote,
        idDebito,
      }: DadosDebitosJaVinculadosCNAB) =>
        status ? (
          <span>
            <IconeStatusCNAB
              status={handleStatusCnab(status)}
              tamanho="8px"
              margin="2px"
            />{" "}
            <span
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => onClickColunaCnab(idDebito)}
            >
              {nomeLote}
            </span>
          </span>
        ) : (
          "--"
        ),
    },
    {
      name: <span>Banco CNAB</span>,
      selector: ({ nomeBanco }: DadosDebitosJaVinculadosCNAB) =>
        nomeBanco ?? "--",
      width: "157px",
    },
    {
      name: <span>Data/hora de envio</span>,
      selector: ({ dataHoraCriacao }: DadosDebitosJaVinculadosCNAB) =>
        dataHoraCriacao ? converterDataHoraBr(new Date(dataHoraCriacao)) : "--",
      width: "183px",
    },
    {
      name: "",
      selector: ({ quantidadeCnabs, idDebito }: DadosDebitosJaVinculadosCNAB) =>
        handleQtdArquivosVinculados(idDebito, quantidadeCnabs),
      width:
        dados && dados.filter((debito) => debito.quantidadeCnabs > 1).length
          ? "48px"
          : "0px",
    },
  ];

  const estiloCustomizado = {
    rows: {
      style: (linha: any) => {
        const indiceLinha = Number(linha.id.split("-")[1]) - 1;
        const quantidade = dados[indiceLinha]?.quantidadeCnabs ?? 0;

        let resultado: any = {
          display: "flex",
          height: "52px",
          margin: "4px 0",
          alignItems: "center",
          justifyContent: "flex-start",
          "&:not(:last-of-type)": {
            borderBottomWidth: "0px",
          },
          borderRadius: "10px",
          paddingLeft: "14px",
        };

        if (quantidade > 1) {
          resultado = { ...resultado, backgroundColor: "#FFEAEE !important" };
        }
        return resultado;
      },
      stripedStyle: {
        background: temaPadrao.colors.neutral.neutral05,
        borderRadius: "10px",
      },
      selectedHighlightStyle: {
        background: `${temaPadrao.colors.project.bgColor} !important`,
      },
    },
  };

  return (
    <ModalCustom
      title={"Exportações CNAB"}
      size="xl"
      handleClose={handleClose}
      onConfirm={onConfirm}
      show={show}
      centered
      footer
      footerConfirmButton
      closeButtonTitle="Cancelar"
      confirmButtonTitle="Prosseguir"
      buttonPosition="end"
    >
      <AvisoHaVinculoArquivo />
      <TituloTabelaArquivosJaVinculados>
        Débitos vinculados a outros arquivos CNAB
      </TituloTabelaArquivosJaVinculados>
      <Table
        columns={columns}
        data={dados}
        keyField="id"
        loading={false}
        noSubHeader
        customTableStyle={estiloCustomizado}
      />
    </ModalCustom>
  );
}
