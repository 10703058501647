import { CompositeContainer } from "components/CompositeContainer";
import { ModalCustom } from "components/ModalCustom";
import { StatusSolicitacaoPagamentoEnumV2 } from "features/debito/consulta/enum/StatusSolicitacaoPagamento";
import { IDetalhesConsulta } from "features/debito/consulta/interfaces/IDetalhesConsulta";
import { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { ConsultaManualLote } from "./ConsultaManualLote";
import { ConsultaManualUnitaria } from "./ConsultaManualUnitaria";
import { Container } from "./style";

interface ConsultaManualProps {
  debito?: IDetalhesConsulta;
  tipo: "UNITARIO" | "LOTE";
  idConsulta?: string;
  dados: IDetalhesConsulta[];
  setDados: (dados: IDetalhesConsulta[]) => void;
}

export function ConsultaManual({
  debito,
  tipo,
  idConsulta,
  setDados,
  dados,
}: Readonly<ConsultaManualProps>) {
  const [showModal, setShowModal] = useState(false);

  function abrirModalLote() {
    setShowModal(false);
  }

  function abrirModalManual() {
    setShowModal(true);
  }
  function fecharClose() {
    setShowModal(false);
  }
  const bodyStrategy: { [key: string]: React.JSX.Element } = {
    LOTE: (
      <ConsultaManualLote
        close={fecharClose}
        idConsulta={Number(idConsulta ?? 0)}
      />
    ),
    UNITARIO: (
      <ConsultaManualUnitaria
        close={fecharClose}
        consultaId={Number(idConsulta ?? 0)}
        debitosId={debito?.id ?? 0}
        placa={debito?.placa}
        chassi={debito?.chassi}
        renavam={debito?.renavam}
        uf={debito?.uf}
        tipoDebito={debito?.debito}
        setDados={setDados}
        dados={dados}
        disabled={
          debito?.fonteDeConsulta == "MANUAL" &&
          Number(debito?.statusDebito) ==
            StatusSolicitacaoPagamentoEnumV2["Débito disponível"]
        }
      />
    ),
  };

  return (
    <>
      <Container
        disabled={
          (debito?.fonteDeConsulta != "MANUAL" &&
            Number(debito?.statusDebito) ==
              StatusSolicitacaoPagamentoEnumV2["Débito disponível"]) ||
          tipo == "LOTE" //TODO: temporario até implementação LOTE
        }
      >
        <FaEdit
          onClick={
            tipo == "LOTE"
              ? () => abrirModalLote
              : debito?.fonteDeConsulta != "MANUAL" &&
                Number(debito?.statusDebito) ==
                  StatusSolicitacaoPagamentoEnumV2["Débito disponível"]
              ? () => {}
              : abrirModalManual
          }
        />
      </Container>

      <ModalCustom
        show={showModal}
        handleClose={fecharClose}
        title={`Edição ${tipo == "UNITARIO" ? "unitária" : "lote"}`}
        centered
        size="xl"
      >
        <CompositeContainer.Body>{bodyStrategy[tipo]}</CompositeContainer.Body>
      </ModalCustom>
    </>
  );
}
