export enum TipoDebitos {
  IPVA = 1,
  Licenciamento = 2,
  Multa = 3,
  DPVAT = 4,
}

export class TipoDebitosString {
  static get(tipoDebito?: TipoDebitos): string {
    if (!tipoDebito) {
      return "-";
    }
    const strategyTipoDebitos: Record<number, string> = {
      1: "IPVA",
      2: "Licenciamento",
      3: "Multa",
      4: "DPVAT",
    };

    return strategyTipoDebitos[tipoDebito] ?? "-";
  }
}
