import { Informacao } from "components/Informacao";
import { ContainerAvisoCnabLote } from "../../styles";

interface IProps {
  loteInteiro: boolean;
}
export function AvisoCnabLote({ loteInteiro }: IProps) {
  const mensagem = loteInteiro ? (
    <span>
      Serão contemplados todos os débitos disponíveis neste lote. Para placas
      específicas, selecione-as a partir do checkbox da listagem.{" "}
      <strong>
        Deseja gerar o CNAB para todos os débitos disponíveis no lote?
      </strong>
    </span>
  ) : (
    <span>
      A seleção do checkbox do título contempla apenas os débitos disponíveis
      listados na página atual, para gerar o arquivo CNAB de todos os débitos
      disponíveis no lote, clique em Gerar arquivo CNAB sem selecionar nenhum
      checkbox.{" "}
      <strong>Deseja gerar o CNAB apenas dos débitos selecionados?</strong>
    </span>
  );
  return (
    <ContainerAvisoCnabLote>
      <Informacao
        mensagem={mensagem}
        showBotaoFechar={false}
        showLabel={false}
        type="atencaoRedondo"
        className="informacao"
        style={{ boxShadow: "none", margin: "10px auto", width: "100%" }}
        fontSize="14px"
        marginTopSvg={loteInteiro ? "-20px" : "-40px"}
        widthSvg="1rem"
      />
    </ContainerAvisoCnabLote>
  );
}
