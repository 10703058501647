import { Tooltip } from "react-bootstrap";
import { FaInfo } from "react-icons/fa";
import styled from "styled-components";

export const CustomQuestion = styled(FaInfo)`
  width: 3px;
  color: white;
`;

export const Circle = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  background-color: #009933;
`;

export const CustomTooltip = styled(Tooltip)`
opacity: 1 !important;
.tooltip-inner {
    max-width: 284px;
    max-height: 75px;
    background-color: #333333;
    opacity: 1;
    font-family: 'Roboto', sans-serif;
    border-radius: 4px;
    padding: 6px 6px;
    margin-left: -10px;
    font-size: 14px;
    color: white;
    text-align: left;
}
`;