import { ReactNode, createContext, useMemo, useState } from "react";
import { useListarExtratoVeicular } from "../hooks/useListarExtratoVeicular";
import { IFiltrosListarConsultasExtratoVeicular, IFiltrosListarConsultasUnitariasExtratoVeicular } from "../interfaces/IFiltrosListarConsultasExtratoVeicular";

interface IContext {
  exibir: "DETALHADOS" | "LOTES";
  setExibir: (exibir: "DETALHADOS" | "LOTES") => void;
  setFiltrosListarExtratosVeiculares: CallbackWithArgs;
  dados: any[];
  handleLimitChange: (limit: number) => void;
  handlePageChange: (page: number) => void;
  totalRegistros: number;
  loading: boolean;
  listarExtratosVeiculares: Callback<Promise<any>>;
  listarConsolidados: Callback<Promise<any>>;
  limit: number;
  setFiltrosListarDetalhadosExtratosVeiculares: CallbackWithArgs;
  handleExportExtratosConsolidados: Callback<Promise<any>>;
  handleExportExtratosDetalhes: Callback<Promise<any>>;
  setExtratosVeicularesSelecionados: (ids: number[]) => void;
  setConsultasSelecionadas: (ids: number[]) => void;
  extratosVeicularesSelecionados: number[];
  consultasSelecionadas: number[];
  filtrosListarExtratosVeiculares: IFiltrosListarConsultasExtratoVeicular | null;
  filtrosListarDetalhadosExtratosVeiculares: IFiltrosListarConsultasUnitariasExtratoVeicular | null;
  setDatasExportar: (datas: (Date | null)[]) => void;
  datasExportar: (Date | null)[];
  loadingRelatorio: boolean;
  showOpcoesExportarExcel: boolean;
  setShowOpcoesExportarExcel: (dados: boolean) => void;
}

export const ListarExtratosVeicularesContext = createContext<IContext>({
  exibir: "LOTES",
  setExibir: () => { },
  dados: [],
  handleLimitChange: () => { },
  handlePageChange: () => { },
  limit: 0,
  totalRegistros: 0,
  loading: false,
  listarExtratosVeiculares: async () => Promise.resolve(),
  listarConsolidados: async () => Promise.resolve(),
  setFiltrosListarExtratosVeiculares: () => { },
  setFiltrosListarDetalhadosExtratosVeiculares: () => { },
  handleExportExtratosConsolidados: async () => Promise.resolve(),
  handleExportExtratosDetalhes: async () => Promise.resolve(),
  setExtratosVeicularesSelecionados: () => { },
  setConsultasSelecionadas: () => { },
  extratosVeicularesSelecionados: [],
  consultasSelecionadas: [],
  filtrosListarExtratosVeiculares: null,
  filtrosListarDetalhadosExtratosVeiculares: null,
  setDatasExportar: () => { },
  datasExportar: [null, null],
  loadingRelatorio: false,
  showOpcoesExportarExcel: false,
  setShowOpcoesExportarExcel: () => { },
});

export function ListarExtratosVeicularesContextProvider({
  children,
}: Readonly<{ children: ReactNode }>) {
  const {
    setFiltrosListarExtratosVeiculares,
    dados,
    handleLimitChange,
    handlePageChange,
    totalRegistros,
    loading,
    listarExtratosVeiculares,
    listarConsolidados,
    limit,
    setFiltrosListarDetalhadosExtratosVeiculares,
    handleExportExtratosConsolidados,
    handleExportExtratosDetalhes,
    setExtratosVeicularesSelecionados,
    setConsultasSelecionadas,
    extratosVeicularesSelecionados,
    consultasSelecionadas,
    filtrosListarExtratosVeiculares,
    filtrosListarDetalhadosExtratosVeiculares,
    setDatasExportar,
    datasExportar,
    loadingRelatorio,
    showOpcoesExportarExcel,
    setShowOpcoesExportarExcel,
  } = useListarExtratoVeicular();

  const [exibir, setExibir] = useState<"DETALHADOS" | "LOTES">("LOTES");

  const value = useMemo(
    () =>
    ({
      setFiltrosListarExtratosVeiculares,
      dados,
      handleLimitChange,
      handlePageChange,
      totalRegistros,
      loading,
      listarExtratosVeiculares,
      listarConsolidados,
      limit,
      setFiltrosListarDetalhadosExtratosVeiculares,
      exibir,
      setExibir,
      handleExportExtratosConsolidados,
      handleExportExtratosDetalhes,
      setExtratosVeicularesSelecionados,
      setConsultasSelecionadas,
      extratosVeicularesSelecionados,
      consultasSelecionadas,
      filtrosListarExtratosVeiculares,
      filtrosListarDetalhadosExtratosVeiculares,
      setDatasExportar,
      datasExportar,
      loadingRelatorio,
      showOpcoesExportarExcel,
      setShowOpcoesExportarExcel,
    }),
    [
      setFiltrosListarExtratosVeiculares,
      dados,
      handleLimitChange,
      handlePageChange,
      totalRegistros,
      loading,
      listarExtratosVeiculares,
      listarConsolidados,
      limit,
      setFiltrosListarDetalhadosExtratosVeiculares,
      exibir,
      setExibir,
      handleExportExtratosConsolidados,
      handleExportExtratosDetalhes,
      setExtratosVeicularesSelecionados,
      setConsultasSelecionadas,
      extratosVeicularesSelecionados,
      consultasSelecionadas,
      filtrosListarExtratosVeiculares,
      filtrosListarDetalhadosExtratosVeiculares,
      setDatasExportar,
      datasExportar,
      loadingRelatorio,
      showOpcoesExportarExcel,
      setShowOpcoesExportarExcel,
    ]
  );
  return (
    <ListarExtratosVeicularesContext.Provider value={value}>
      {children}
    </ListarExtratosVeicularesContext.Provider>
  );
}
