import { yupResolver } from "@hookform/resolvers/yup";
import { FilterButton } from "components/Button/style";
import { Container } from "components/Container";
import { CustomInput } from "components/CustomInput";
import { DropdownSelect } from "components/DropdownSelect";
import {
  STATUS_SOLICITACAO_PAGAMENTO_OPTIONS,
  TIPO_DEBITOS_OPTIONS,
  VENCIMENTO_OPTIONS,
} from "features/relatorios/consultas/pages/DetalhesRelatorioConsultas/constants/options";
import { filtrosDetalhesRelatorioConsultaScheme } from "features/relatorios/consultas/validate/validator";
import { InputContainer } from "features/relatorios/pagamento/pages/relatorio/containers/FiltrosRelatorioPagamentos/style";
import { useMaskInput } from "hooks/useMaskInput";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaFilter } from "react-icons/fa";
import { ButtonContainer } from "./style";
import { MaisFiltros } from "components/MaisFiltros";
import { IFiltro } from "./Interface/IFiltro";
import { useMaisFiltros } from "hooks/useMaisFiltros";
import { CustomDatePickerV2 } from "components/DatePickerV2";
import { StatusSolicitacaoPagamentoEnumV2 } from "features/debito/consulta/enum/StatusSolicitacaoPagamento";

interface Props {
  handleSubmitFiltros: (data: IFiltro) => void;
}

export function Filtros({ handleSubmitFiltros }: Props) {
  const {
    handleAplicarMaisFiltros,
    handleFormSubmit,
    handleNaoUsarMaisFiltros,
  } = useMaisFiltros();
  const { mInputPlaca, mInputNum } = useMaskInput();

  const { handleSubmit, control, reset } = useForm<IFiltro>({
    resolver: yupResolver(filtrosDetalhesRelatorioConsultaScheme),
  });

  return (
    <Container titulo="Filtros">
      <Form
        noValidate
        onSubmit={handleSubmit((dados) => {
          handleFormSubmit({
            dados,
            excluir: ["renavam"],
            resetCallback: reset,
            submitCallback: handleSubmitFiltros,
          });
        })}
      >
        <Row>
          <InputContainer lg="2">
            <Form.Group>
              <Form.Label>Placa</Form.Label>
              <Controller
                control={control}
                name="placa"
                render={({ field: { onChange } }) => (
                  <CustomInput
                    type="text"
                    placeholder="Digite a placa"
                    onChange={(e: any) => {
                      onChange(mInputPlaca(e).replace(/-/g, ""));
                    }}
                  />
                )}
              />
            </Form.Group>
          </InputContainer>
          <Col lg="2">
            <Form.Group>
              <Controller
                control={control}
                name="idTipoDebito"
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    options={TIPO_DEBITOS_OPTIONS}
                    title="Tipo de Débito"
                    selectedValue={value}
                    onSelect={onChange}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col lg="2">
            <Form.Group>
              <Controller
                control={control}
                name="statusPagamento"
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    options={STATUS_SOLICITACAO_PAGAMENTO_OPTIONS}
                    title="Status da consulta"
                    selectedValue={value}
                    onSelect={onChange}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Final de placa</Form.Label>
              <Controller
                name="finalPlaca"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    required
                    selectedValue={value}
                    options={[
                      {
                        label: "1",
                        value: 1,
                      },
                      {
                        label: "2",
                        value: 2,
                      },
                      {
                        label: "3",
                        value: 3,
                      },
                      {
                        label: "4",
                        value: 4,
                      },
                      {
                        label: "5",
                        value: 5,
                      },
                      {
                        label: "6",
                        value: 6,
                      },
                      {
                        label: "7",
                        value: 7,
                      },
                      {
                        label: "8",
                        value: 8,
                      },
                      {
                        label: "9",
                        value: 9,
                      },
                      {
                        label: "0",
                        value: 0,
                      },
                    ]}
                    onSelect={onChange}
                  />
                )}
              />
            </Form.Group>
          </Col>

          <Col lg="2">
            <ButtonContainer>
              <FilterButton type="submit" disabled={false} loading={false}>
                <FaFilter /> Filtrar
              </FilterButton>
              <FilterButton
                type="reset"
                onClick={() => {
                  reset({
                    placa: null,
                    idTipoDebito: null,
                    statusPagamento: null,
                    finalPlaca: null,
                  });
                }}
                disabled={false}
                loading={false}
                outline={true}
              >
                Limpar
              </FilterButton>
            </ButtonContainer>
          </Col>
          <Col lg="2">
            <MaisFiltros
              cancelarOnClick={() => {
                handleNaoUsarMaisFiltros({
                  excluir: [
                    "renavam",
                    "cotaIpva",
                    "dataInicio",
                    "dataFim",
                    "vencimento",
                    "idRecibo",
                    "uf",
                    "arquivoCnab",
                    "statusCnab",
                  ],
                  resetCallback: reset,
                });
              }}
              handleClickAplicarFiltros={handleAplicarMaisFiltros}
              limparTudoOnClick={() => {
                handleNaoUsarMaisFiltros({
                  excluir: [
                    "renavam",
                    "cotaIpva",
                    "dataInicio",
                    "dataFim",
                    "vencimento",
                    "idRecibo",
                    "uf",
                    "arquivoCnab",
                    "statusCnab",
                  ],
                  resetCallback: reset,
                });
              }}
              widthModal="md"
            >
              <Form.Group>
                <Form.Label>Cota IPVA (Parcelas)</Form.Label>
                <Controller
                  name="cotaIpva"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DropdownSelect
                      required
                      selectedValue={value}
                      options={[
                        {
                          label: "Cota uníca",
                          value: 0,
                        },
                        {
                          label: "1",
                          value: 1,
                        },
                        {
                          label: "2",
                          value: 2,
                        },
                        {
                          label: "3",
                          value: 3,
                        },
                        {
                          label: "4",
                          value: 4,
                        },
                        {
                          label: "5",
                          value: 5,
                        },
                        {
                          label: "6",
                          value: 6,
                        },
                        {
                          label: "7",
                          value: 7,
                        },
                        {
                          label: "8",
                          value: 8,
                        },
                        {
                          label: "9",
                          value: 9,
                        },
                        {
                          label: "10",
                          value: 10,
                        },
                      ]}
                      onSelect={onChange}
                    />
                  )}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Renavam</Form.Label>
                <Controller
                  control={control}
                  name="renavam"
                  render={({ field: { onChange, value } }) => (
                    <CustomInput
                      type="text"
                      placeholder="Digite o renavam"
                      onChange={(e: any) => {
                        onChange(e);
                      }}
                      value={value ?? ""}
                    />
                  )}
                />
              </Form.Group>
              <Form.Group
                style={{
                  marginBottom: "-1.3rem",
                }}
              >
                <Form.Label>Período de vencimento</Form.Label>
                <div className="d-flex">
                  <div className="me-2">
                    <Controller
                      control={control}
                      name="dataInicio"
                      render={({ field: { onChange, value } }) => (
                        <CustomDatePickerV2
                          onChange={onChange}
                          placeholder="Data inicial"
                          selected={value}
                          isClearable={false}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      control={control}
                      name="dataFim"
                      render={({ field: { onChange, value } }) => (
                        <CustomDatePickerV2
                          onChange={onChange}
                          placeholder="Data final"
                          selected={value}
                          isClearable={false}
                        />
                      )}
                    />
                  </div>
                </div>
              </Form.Group>
              <Form.Group>
                <Controller
                  control={control}
                  name="vencimento"
                  render={({ field: { onChange, value } }) => (
                    <DropdownSelect
                      options={VENCIMENTO_OPTIONS}
                      title="Vencimento"
                      selectedValue={value}
                      onSelect={onChange}
                    />
                  )}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>ID Recibo</Form.Label>
                <Controller
                  control={control}
                  name="idRecibo"
                  render={({ field: { onChange, value } }) => (
                    <CustomInput
                      type="text"
                      placeholder="Busque por recibo"
                      onChange={(e: any) => {
                        onChange(`${mInputNum(e)}`);
                      }}
                      value={value ?? ""}
                    />
                  )}
                />
              </Form.Group>
              <Form.Group>
                <Controller
                  control={control}
                  name="uf"
                  render={({ field: { onChange, value } }) => (
                    <DropdownSelect
                      options={[
                        { value: "AC", label: "AC" },
                        { value: "AL", label: "AL" },
                        { value: "AP", label: "AP" },
                        { value: "AM", label: "AM" },
                        { value: "BA", label: "BA" },
                        { value: "CE", label: "CE" },
                        { value: "DF", label: "DF" },
                        { value: "ES", label: "ES" },
                        { value: "GO", label: "GO" },
                        { value: "MA", label: "MA" },
                        { value: "MT", label: "MT" },
                        { value: "MS", label: "MS" },
                        { value: "MG", label: "MG" },
                        { value: "PA", label: "PA" },
                        { value: "PB", label: "PB" },
                        { value: "PR", label: "PR" },
                        { value: "PE", label: "PE" },
                        { value: "PI", label: "PI" },
                        { value: "RJ", label: "RJ" },
                        { value: "RN", label: "RN" },
                        { value: "RS", label: "RS" },
                        { value: "RO", label: "RO" },
                        { value: "RR", label: "RR" },
                        { value: "SC", label: "SC" },
                        { value: "SP", label: "SP" },
                        { value: "SE", label: "SE" },
                        { value: "TO", label: "TO" },
                      ]}
                      title="UF"
                      selectedValue={value}
                      onSelect={onChange}
                      maxHeight={200}
                    />
                  )}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Arquivo CNAB</Form.Label>
                <Controller
                  control={control}
                  name="arquivoCnab"
                  render={({ field: { onChange, value } }) => (
                    <CustomInput
                      type="text"
                      placeholder="Busque por arquivo CNAB"
                      onChange={onChange}
                      value={value ?? ""}
                    />
                  )}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Status CNAB</Form.Label>
                <Controller
                  name="statusCnab"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DropdownSelect
                      required
                      selectedValue={value}
                      options={[
                        {
                          label: "Enviado",
                          value:
                            StatusSolicitacaoPagamentoEnumV2["CNAB enviado"],
                        },
                        {
                          label: "Processando",
                          value:
                            StatusSolicitacaoPagamentoEnumV2[
                              "CNAB processando"
                            ],
                        },
                        {
                          label: "Não vinculado",
                          value:
                            StatusSolicitacaoPagamentoEnumV2[
                              "Débito disponível"
                            ],
                        },
                        {
                          label: "Falha no envio",
                          value:
                            StatusSolicitacaoPagamentoEnumV2[
                              "CNAB não enviado"
                            ],
                        },
                      ]}
                      onSelect={onChange}
                    />
                  )}
                />
              </Form.Group>
            </MaisFiltros>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
