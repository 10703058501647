import styled from "styled-components";
import { StatusLoteExtratoVeicular } from "./enums/StatusLoteExtratoVeicular.enum";

interface Props {
  statusConsulta: string;
}

export const Container = styled.div<Props>`
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  padding: 0.25rem 0;

  ${({ statusConsulta, theme }) =>
    statusConsulta === StatusLoteExtratoVeicular.CONSULTANDO &&
    `
    background-color: ${theme.colors.neutral.neutral00};
    color: ${theme.colors.neutral.neutral60};
    border: 1px solid ${theme.colors.neutral.neutral60};
  `}

  ${({ statusConsulta, theme }) =>
    statusConsulta === StatusLoteExtratoVeicular.FINALIZADO &&
    `
    background-color: ${theme.colors.success.bgColor};
    color: ${theme.colors.success.main};
    border: 1px solid ${theme.colors.success.main};
  `}

  ${({ statusConsulta, theme }) =>
    statusConsulta === StatusLoteExtratoVeicular.FALHA &&
    `
    background-color: ${theme.colors.danger.bgColor};
    color: ${theme.colors.danger.main};
    border: 1px solid ${theme.colors.danger.main};
  `}
`;

Container.defaultProps = {
  statusConsulta: StatusLoteExtratoVeicular.CONSULTANDO,
};

