import * as yup from "yup";
import { regexPlacas } from "../../../validators/filtrosExtratoVeicularValidator";
import { cobrancaSchema } from "./cobrancaSchema";

const OBRIGATORIO_RENAVAM_CHASSI = ["PRE_VENDA_ESTADUAL_PR", "PRE_VENDA_ESTADUAL_PR_LEILÃO"];

export const individualSchema = yup
	.object({
		tipoConsulta: yup.string().required("Selecione o tipo de consulta"),
		placa: yup
			.string()
			.matches(regexPlacas, {
				message: "Informe uma placa válida",
			})
			.required("Placa é obrigatório"),
		renavam: yup
			.string()
			.nullable()
			.min(7, "Informe um renavam válido")
			.max(11, "Renavam deve ter no máximo 11 caracteres")
			.when("tipoConsulta", {
				is: (tipoConsulta: string) => OBRIGATORIO_RENAVAM_CHASSI.some((item) => item === tipoConsulta),
				then: (schema) => schema.required("Renavam é obrigatório"),
			}),
		uf: yup
			.string()
			.min(2, "UF deve conter 2 caracteres")
			.max(2, "UF deve conter 2 caracteres"),
	})
	.concat(cobrancaSchema);
