import { ModalCustom } from "components/ModalCustom";
import { useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import br from "date-fns/locale/pt-BR";
import { Container } from "components/DatePicker/style";
import { useMaskInput } from "hooks/useMaskInput";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEmpresas } from "hooks/useEmpresas";
import { DropdownSelect } from "components/DropdownSelect";
import InputMultiTextos from "components/InputMultiTextos";
import {
  InputDeDataCustomizadoContainer,
  InputDeDataCustomizadoDataSpan,
  InputDeDataCustomizadoPlaceholder,
  ModalBody,
  ModalBotaoCancelar,
  ModalBotaoConfirmar,
  ModalDatePicker,
  ModalDatePickerSpan,
  ModalEmpresaSelect,
  ModalEmpresaSpan,
  ModalFooterBotoes,
  ModalMultiInput,
  ModalMultiInputSpan,
  ModalTitulo,
  ModalTituloSpan,
} from "./style";
import { FaCalendar } from "react-icons/fa";

export interface IFiltrarPagamentos {
  ids: number[];
  dataInicio: Date | null;
  dataFim: Date | null;
  empresa: string;
}

export const filtrarPagamentosSchema = yup.object().shape({
  ids: yup.array().optional(),
  dataInicio: yup.date().required(),
  dataFim: yup.date().optional(),
  empresa: yup.number().optional(),
});

interface Props {
  show: boolean;
  loading: boolean;
  handleClose: () => void;
  onConfirm: () => void;
  loadingDownload: boolean;
  dataInicio: Date | null;
  setDataInicio: (data: Date | null) => void;
  dataFim: Date | null;
  setDataFim: (data: Date | null) => void;
  setRecibosSelecionados: (recibos: string[]) => void;
  recibosSelecionados: string[];
  setEmpresa: (empresa: string) => void;
  empresa: string;
}

const maxHeightListaEmpresas = 250;

export function ModalFiltrarPagamentos({
  handleClose,
  onConfirm,
  show,
  loadingDownload,
  dataFim,
  setDataFim,
  dataInicio,
  setDataInicio,
  setRecibosSelecionados,
  recibosSelecionados,
  setEmpresa,
  empresa,
}: Props) {
  const {
    control,
    formState: { errors },
    setValue,
  } = useForm<IFiltrarPagamentos>({
    resolver: yupResolver(filtrarPagamentosSchema),
  });

  useEffect(() => {
    if (!loadingDownload) {
      handleClose();
    }
  }, [loadingDownload]);

  function handleDataDigitada(value: string) {
    const dateRangePadrao = /^\d{2}\/\d{2}\/\d{4}-\d{2}\/\d{2}\/\d{4}$/;
    if (value && dateRangePadrao.test(value.replaceAll(" ", ""))) {
      const datas = value
        .replaceAll(" ", "")
        .split("-")
        .map((item: string) => {
          const partes = item.split("/");
          return new Date(
            Number(partes[2]),
            Number(partes[1]) - 1,
            Number(partes[0])
          );
        });

      return datas;
    }
  }

  const onChangePeriodoConsulta = (update: (Date | null)[]) => {
    const [startDate, endDate] = update;
    setDataInicio(startDate);
    setDataFim(endDate);
  };

  const inputCustomizado = (disabled: boolean) => (
    <InputDeDataCustomizadoContainer disabled={disabled}>
      {!dataInicio && (
        <InputDeDataCustomizadoPlaceholder>
                  Selecione
        </InputDeDataCustomizadoPlaceholder>
      )}
      {dataInicio && (
        <InputDeDataCustomizadoDataSpan>
          {dataInicio ? `${dataInicio.toLocaleDateString()} - ` : ""}{" "}
          {dataFim ? dataFim?.toLocaleDateString() : ""}
        </InputDeDataCustomizadoDataSpan>
      )}

      <FaCalendar height="13px" width="13px" />
    </InputDeDataCustomizadoContainer>
  );

  const { mDataRangeInput } = useMaskInput();
  const { agrupamentoOptions, criarOptionsAgrupamento } = useEmpresas();

  useEffect(() => {
    criarOptionsAgrupamento();
  }, [criarOptionsAgrupamento]);

  return (
    <ModalCustom
      show={show}
      title="Exportar comprovantes de pagamento"
      handleClose={() => {
        setDataInicio(null);
        setDataFim(null);
        setRecibosSelecionados([]);
        setEmpresa("");
        setValue("empresa", "");
        handleClose();
      }}
      centered
      onConfirm={onConfirm}
    >
      <ModalBody>
        <ModalTitulo>
          <ModalTituloSpan>
            Informe o(s) recibo(s) ou o período desejado para exportar os
            comprovantes de pagamento.
          </ModalTituloSpan>
        </ModalTitulo>
        <ModalMultiInput>
          <ModalMultiInputSpan>ID Recibo</ModalMultiInputSpan>
          <InputMultiTextos
            disabled={dataInicio !== null || dataFim !== null}
            size="sm"
            placeholder="Insira um ou mais IDs de recibo"
            onChange={(ids) => {
              setRecibosSelecionados(ids);
            }}
            onKeyDown={(texto) => {
              const textoSoNumeros = texto.replace(/[^0-9]/g, "");
              return textoSoNumeros;
            }}
            value={recibosSelecionados}
          />
        </ModalMultiInput>
        <ModalDatePicker>
          <ModalDatePickerSpan>Período de pagamento</ModalDatePickerSpan>
          <Container
            style={{
              width: "100%",
            }}
          >
            <ReactDatePicker
              showIcon
              selectsRange
              disabled={recibosSelecionados.length > 0}
              className="form-control"
              dateFormat={"dd/MM/yyyy"}
              customInput={
                inputCustomizado(recibosSelecionados.length > 0)
              }
              locale={br}
              placeholderText="Selecione"
              startDate={dataInicio}
              endDate={dataFim}
              onChange={(update) => {
                onChangePeriodoConsulta(update);
              }}
              onChangeRaw={(event: any) => {
                if (!event.target.value) {
                  return;
                }
                onChangePeriodoConsulta(
                  handleDataDigitada(mDataRangeInput(event)) ?? [null, null]
                );
              }}
            />
          </Container>
        </ModalDatePicker>
        <ModalEmpresaSelect>
          <ModalEmpresaSpan>Empresa</ModalEmpresaSpan>
          <Controller
            name="empresa"
            control={control}
            render={({ field: { onChange, value } }) => (
              <DropdownSelect
                onSelect={(empresa) => {
                  setEmpresa(empresa);
                  onChange(empresa);
                }}
                options={agrupamentoOptions}
                selectedValue={value}
                searchable
                disabled={dataInicio === null}
                mensagemErro={errors.empresa?.message}
                maxHeight={maxHeightListaEmpresas}
                borderColor={"#7F7F7F"}
              />
            )}
          />
        </ModalEmpresaSelect>
        <ModalFooterBotoes>
          <ModalBotaoCancelar
            onClick={() => {
              setDataInicio(null);
              setDataFim(null);
              setRecibosSelecionados([]);
              setEmpresa("");
              setValue("empresa", "");
              handleClose();
            }}
          >
            Cancelar
          </ModalBotaoCancelar>
          <ModalBotaoConfirmar
            disabled={
              (recibosSelecionados.length === 0 && dataInicio === null) ||
              (dataInicio !== null && empresa === "")
            }
            onClick={onConfirm}
          >
            Exportar
          </ModalBotaoConfirmar>
        </ModalFooterBotoes>
      </ModalBody>
    </ModalCustom>
  );
}
