import { converterDataHoraBr } from "_utils/Data";
import { DadosInfoCnab } from "../../Interfaces/DadosInfoCnab";
import { ContainerDadosGerais } from "../../style";

interface Props {
  dados: DadosInfoCnab;
}
export function InfoCnabDados({ dados }: Props) {
  return (
    <table width="100%">
      <tbody>
        <tr>
          <td width="25%">Arquivo CNAB:</td>
          <td width="75%">
            <strong>{dados.cnabs[0].nome ?? "-"}</strong>
          </td>
        </tr>
        <tr>
          <td>Banco:</td>
          <td>
            <strong>{dados.cnabs[0].nomeBanco ?? "-"}</strong>
          </td>
        </tr>
        <tr>
          <td>Data/hora de envio:</td>
          <td>
            <strong>
              {dados.cnabs[0].dataHora
                ? converterDataHoraBr(new Date(dados.cnabs[0].dataHora))
                : "-"}
            </strong>
          </td>
        </tr>
        <tr>
          <td>Usuário solicitante:</td>
          <td>
            <strong>{dados.cnabs[0].nomeUsuarioSolicitante ?? "-"}</strong>
          </td>
        </tr>
        <tr>
          <td>Enviado para:</td>
          <td>
            <strong>
              {dados.cnabs[0].emails && dados.cnabs[0].emails.length
                ? dados.cnabs[0].emails.join(", ")
                : "-"}
            </strong>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
