import { LoadingCirculo } from "components/LoadingCirculo";
import { Container, Titulo } from "./style";
import { Fragment } from "react";

interface Props {
    aberto: boolean;
    opcoes: ObjetoListagem[];
    loading: boolean;
}

interface ObjetoListagem {
    id: number;
    onClick: () => void;
    componente: JSX.Element;
}

export function ExcelMultiselect({ loading, aberto, opcoes }: Props) {
    return (
        <Container aberto={aberto}>
            {
                loading
                    ? (<LoadingCirculo mostrar={loading} />)
                    : (
                        <Fragment>
                            <Titulo>Exportar</Titulo>
                            {
                                opcoes.map(opcao => (
                                    <div style={{
                                        cursor: 'pointer'
                                    }} key={opcao.id} onClick={opcao.onClick}>
                                        {opcao.componente}
                                    </div>
                                ))
                            }
                        </Fragment>
                    )
            }
        </Container>
    );
}