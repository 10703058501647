import { converterDataBr, converterDataHoraBr } from "_utils/Data";
import { DadosDebito } from "../../Interfaces/DadosInfoCnab";
import { formatarDinheiro } from "_utils/Money";
import { TipoDebitos } from "features/debito/consulta/enum/TipoDebitos";

interface Props {
  dados: DadosDebito;
}
export function InfoCnabDadosDebito({ dados }: Props) {
  return (
    <table width="100%">
      <thead>
        <tr>
          <td width="20%">Placa</td>
          <td width="20%">UF</td>
          <td width="20%">Débito</td>
          <td width="20%">Vencimento</td>
          <td width="20%">Valor</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <strong>{dados.placa ?? "-"}</strong>
          </td>
          <td>
            <strong>{dados.uf ?? "-"}</strong>
          </td>
          <td>
            <strong>
              {dados.debito ? TipoDebitos[Number(dados.debito)] : "-"}
            </strong>
          </td>
          <td>
            <strong>
              {dados.vencimento
                ? converterDataBr(new Date(dados.vencimento))
                : "-"}
            </strong>
          </td>
          <td>
            <strong>
              {dados.valor ? formatarDinheiro(dados.valor / 100) : "-"}
            </strong>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
