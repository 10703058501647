import { formatarDinheiro } from "_utils/Money";
import { DivIconesBanco } from "../../styles";
import { FaExclamationCircle, FaTrash } from "react-icons/fa";
import { Tooltip } from "components/Tooltip";

interface Props {
  valor: number;
  habilitado: boolean;
  codigoBanco: string | number;
  onClickLixeira: (codigoBanco: string | number) => void;
}

export function ValorSelecaoAutomaticaBanco({
  valor,
  habilitado,
  onClickLixeira,
  codigoBanco,
}: Props) {
  const bottomTooltip = 1;
  const rightTooltip = -1300;
  const widthTooltip = 632;

  return (
    <>
      {habilitado ? (
        formatarDinheiro((valor ?? 0) / 100)
      ) : (
        <DivIconesBanco>
          <div>{formatarDinheiro((valor ?? 0) / 100)}</div>
          <div>
            <Tooltip
              bottom={bottomTooltip}
              right={rightTooltip}
              width={widthTooltip}
              trigger={
                <FaExclamationCircle
                  style={{
                    color: "red",
                    cursor: "help",
                    marginRight: "8px",
                    fontSize: 14,
                  }}
                />
              }
            >
              <div
                style={{
                  display: "flex",
                  maxWidth: "632px",
                  backgroundColor: "#FFEAEE",
                  padding: "16px",
                  borderRadius: "5px",
                }}
              >
                <div>
                  <FaExclamationCircle
                    style={{
                      color: "red",
                      marginRight: "8px",
                      fontSize: 16,
                    }}
                  />
                </div>
                <div>
                  O banco indicado para pagamento deste(s) débito(s) não esta
                  cadastrado para este cliente. Realize o cadastro para gerar o
                  CNAB de todos os débitos com seleção de banco automática ou
                  remova-os da listagem abaixo para gerar o CNAB dos demais
                  débitos.{" "}
                  <strong>
                    Você também pode gerar o arquivo CNAB de todos os débitos
                    selecionando manualmente o banco que deseja efetuar o
                    pagamento
                  </strong>
                  .
                </div>
              </div>
            </Tooltip>
            <FaTrash
              style={{
                color: "black",
                cursor: "pointer",
                marginRight: "8px",
                fontSize: 14,
              }}
              onClick={() => onClickLixeira(codigoBanco)}
            />
          </div>
        </DivIconesBanco>
      )}
    </>
  );
}
