import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContainerBotaoFecharClaro } from "./styled";

const BotaoFecharClaro = () => (
    <ContainerBotaoFecharClaro>
        <FontAwesomeIcon color="#8E8E8E" width="10px" height="10px" icon={faXmark} />
    </ContainerBotaoFecharClaro>
)

export default BotaoFecharClaro;