import { IconeStatusCNAB } from "../../../IconeStatusCNAB";
import { ContainerInfoStatusCnab } from "../../style";

interface Props {
  status: "enviado" | "falha" | "processando";
}

export function InfoStatusCnab({ status }: Props) {
  const converterStatus = {
    enviado: "CNAB enviado",
    falha: "Falha no envio",
    processando: "CNAB processando",
  };

  return (
    <ContainerInfoStatusCnab>
      <table width="100%">
        <tbody>
          <tr>
            <td width="25%">Status CNAB:</td>
            <td width="75%">
              <IconeStatusCNAB
                tamanho={"12px"}
                status={status}
              />
              <strong>{converterStatus[status] ?? ''}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </ContainerInfoStatusCnab>
  );
}
