import { ModalCustom } from "components/ModalCustom";
import { FaCheck, FaExclamation } from "react-icons/fa";
import { Container, IconeContainer, Texto, TextoContainer } from "./style";
import { IDadosModalDebitosCnabsUltimasHoras } from "./Interfaces/IDadosModalDebitosCnabsUltimasHoras";
interface IModalConfirmacaoSolicitacao {
  show: boolean;
  handleClose: () => void;
  onConfirm: () => void;
  dados: IDadosModalDebitosCnabsUltimasHoras;
}

export function ModalDebitosCnabsUltimasHoras({
  show,
  handleClose,
  onConfirm,
  dados,
}: IModalConfirmacaoSolicitacao) {
  return (
    <ModalCustom
      show={show}
      handleClose={handleClose}
      title="Gerar CNAB"
      centered={true}
      footer
      footerConfirmButton
      confirmButtonTitle="Prosseguir"
      onConfirm={onConfirm}
    >
      <Container>
        <IconeContainer>
          <FaExclamation />
        </IconeContainer>
        <TextoContainer>
          <Texto>
            Não é possível vincular o mesmo débito em outro arquivo CNAB antes
            de 24 horas do vínculo anterior. Devido a isso{" "}
            <strong>{dados.quantidadeDuplicados}</strong>{" "}dos{" "}
            <strong>{dados.totalDebitos}</strong>{" "}
            débitos selecionados não serão vinculados.
          </Texto>
        </TextoContainer>
      </Container>
    </ModalCustom>
  );
}
