import styled from "styled-components";

interface IListOptions {
  isVisible: boolean;
  maxHeight?: number;
}

export const SelectContainer = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 0;
  display: grid;
  gap: 0.5rem;
`;

interface ISelectBody {
  mensagemErro?: string;
}

export const SelectBody = styled.div<ISelectBody>`
  width: 100%;
  position: relative;
  border-radius: 7px;
  border: ${({ mensagemErro }) => (mensagemErro ? "1px red solid" : "")};
`;

export const SelectButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  height: 2.2rem;
  width: 100%;
  border-radius: 0.35rem;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.neutral.neutral20};
  color: ${({ theme }) => theme.colors.neutral.neutral40};
`;

interface ISelectTitle {
  desativado?: boolean;
}

export const SelectTitle = styled.span<ISelectTitle>`
  color: ${({ desativado }) => (desativado ? "#9f9f9f" : "")};
`;

export const OptionText = styled.span`
  color: ${({ theme }) => theme.colors.neutral.neutral90};
`;

export const IconContainer = styled.span``;

export const OptionsList = styled.ul<IListOptions>`
  position: absolute;
  width: 100%;
  z-index: 100;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 0 0 0.5rem 0.5rem;
  padding: 0.25rem 0;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.neutral.neutral20};
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  ${({ maxHeight }) => (maxHeight ? `max-height: ${maxHeight}px; overflow: auto;` : "")}
`;

export const ListItem = styled.li`
  padding: 0.15rem 0.25rem;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  gap: 0.5rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.project.bgColor};
  }
`;

export const Checkbox = styled.span`
  height: 14px;
  width: 14px;
  border-radius: 0.25rem;
  padding: 0.15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.neutral.neutral20};
  font-size: 0.75rem;
`;

export const OptionsText = styled.span``;
