import { FaFileExcel, FaFilePdf } from "react-icons/fa";
import { ComponenteComprovantesContainer, ComponenteRelatorioContainer, SpanDescricao } from "./style";

export const OpcaoRelatorio = () => (
    <ComponenteRelatorioContainer>
        <FaFileExcel style={{
            marginRight: '5px',
        }} /> Relatório
        <SpanDescricao>
            (Excel)
        </SpanDescricao>
    </ComponenteRelatorioContainer>
);

export const OpcaoComprovantes = () => (
    <ComponenteComprovantesContainer>
        <FaFilePdf style={{
            marginRight: '5px'
        }}/>
        Comprovantes
        <SpanDescricao>
            (Zip)
        </SpanDescricao>
    </ComponenteComprovantesContainer>
)