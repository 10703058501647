import { FaExclamation } from "react-icons/fa";
import { ContainerExclamacaoCrlv, ErroCrlv, ErroCrlvBotao, ErroCrlvBotaoSpan } from "./styled";

const ToastErroCrlv = () => (
  <ErroCrlv>
    <ContainerExclamacaoCrlv>
      <FaExclamation color="#FE8F14" height="23px" width="7px" />
    </ContainerExclamacaoCrlv>
    Não foi possível emitir o documento solicitado. Para mais
    informações entre em contato com o suporte.
    
    <ErroCrlvBotao>
      <ErroCrlvBotaoSpan>
        Entendi
      </ErroCrlvBotaoSpan>
    </ErroCrlvBotao>
  </ErroCrlv>
);

export default ToastErroCrlv;