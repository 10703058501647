import { Button } from "react-bootstrap";
import styled from "styled-components";

export const MainContainer = styled.div`
    min-height: 100vh;
    max-height: 100vh;
    max-width: 100vw;
`;

export const HeaderContainer = styled.div`
    background-color: white;
    width: 100%;
    height: 72px;
    box-shadow: 0px 7px 5px -3px rgba(0,0,0,0.25);
    z-index: 1;
    position: relative;
    display: flex;
`;

export const HeaderLogoContainer = styled.div`
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    padding-left: 20px;
`;

export const HeaderBotaoContainer = styled.div`
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
`;

export const HeaderBotao = styled.div`
    width: 117px;
    height: 40px;
    border: 1px solid #009933;
    border-radius: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: 'Roboto';
    color: #009933;
    cursor: pointer;
    text-decoration: none;
`;

export const HeroContainer = styled.div`
    background-color: white;
    width: 100%;
    height: 700px;
    z-index: 0;
    position: relative;

    @media (max-width: 850px) {
        height: auto;
    }
`;

export const HeroSeparator = styled.div`
    display: flex;
    height: 100%;

    @media (max-width: 850px) {
        flex-direction: column;
        min-height: 100%;
    }
`;

export const HeroFormContainer = styled.div`
    width: 50%;
    height: 100%;
    font-family: 'Inter';
    padding: 96px 80px;

    @media (max-width: 1280px) {
        padding: 48px 40px;
    }

    @media (max-width: 850px) {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 24px 12px;
        height: 45%;
    }
`;

export const HeroForm = styled.form`
    display: flex;
    flex-direction: column;

    @media (max-width: 850px) {
        align-items: center;
    }
`;

export const HeroFormInput = styled.input`
    border: 1px solid #B3B3B3;
    padding: 16px;
    border-radius: 5px;
    width: 536px;
    font-size: 16px;
    line-height: 24px;
    color: #999999;
    margin: 8px 0;

    @media (max-width: 1280px) {
        width: 400px;
    }

    @media (max-width: 850px) {
        width: 85vw;
    }
`;

export const HeroFormInputError = styled.div`
    background-color: #FFEAEE;
    padding: 16px;
    width: 536px;
    font-size: 14px;
    line-height: 16px;
    display: flex;

    border-radius: 5px;

    @media (max-width: 1280px) {
        width: 400px;
    }

    @media (max-width: 850px) {
        width: 85vw;
    }
`;

export const HeroFormInputErrorWarning = styled.img`
    margin-right: 8px;
`;

export const HeroFormButtonBoostrap = styled(Button)`
    width: 536px;
    border: none;
    background-color: #009933;
    color: white;
    border-radius: 500px;
    padding: 12px 16px;
    font-size: 16px;
    line-height: 24px;
    margin: 8px 0;

    @media (max-width: 1280px) {
        width: 400px;
    }

    @media (max-width: 850px) {
        width: 85vw;
    }
`;

export const HeroFormButton = styled.button`
    width: 536px;
    border: none;
    background-color: #009933;
    color: white;
    border-radius: 500px;
    padding: 12px 16px;
    font-size: 16px;
    line-height: 24px;
    margin: 8px 0;

    @media (max-width: 1280px) {
        width: 400px;
    }

    @media (max-width: 850px) {
        width: 85vw;
    }
`;

export const HeroTitle = styled.h1`
    font-weight: 800;
    font-size: 40px;
    line-height: 60px;
    max-width: 536px;
    margin-bottom: 22px;

    @media (max-width: 850px) {
        font-size: 32px;
        line-height: 33.6px;
        max-width: 85vw;
        width: 85vw;
        text-align: center;
    }

    @media (max-width: 360px) {
        font-size: 28px;
    }
`;

export const HeroSubtitle = styled.h2`
    color: #4D4D4D;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    max-width: 536px;
    margin-bottom: 22px;

    @media (max-width: 850px) {
        font-size: 16px;
        line-height: 24px;
        max-width: 85vw;
        width: 85vw;
        text-align: justify;
    }
`;

export const HeroImageContainer = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 850px) {
        width: 100%;
        height: 55%;
    }

    @media (max-width: 360px) {
        display: none;
    }
`;

export const HeroImage = styled.img`
    max-width: 626px;
    max-height: 572px;
    width: 50vw;
    height: 50vw;

    @media (max-width: 1280px) {
        width: 40vw;
        height: 40vw;
    }

    @media (max-width: 850px) {
        width: 85vw;
        height: 85vw;
        max-width: 400px;
        max-height: 400px;
    }
`;

export const PrimeiroFooterContainer = styled.div`
    width: 100%;
    height: 292px;
    background-color: #009933;
    display: flex;

    @media (max-width: 850px) {
        height: 454px;
        flex-direction: column;
        align-items: center;
    }
`;

export const TercoDeFooterContainer = styled.div`
    height: 100%;
    width: 33.33%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 850px) {
        width: 100%;
        height: 33.33%;
        padding: 24px 12px;
    }
`;

export const TercoDeFooter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: white;
    font-family: 'Inter';
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    width: 300px;

    @media (max-width: 850px) {
    }
`;

export const TercoDeFooterTitulo = styled.div`
    font-weight: 700;
    color: white;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Inter';
`;

export const TercoDeFooterIcone = styled.img`
    margin-right: 8px;
    width: 30px;
    height: 30px;
`;

export const TercoDeFooterH1 = styled.h1`
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
`;

export const TercoDeFooterH2 = styled.h2`
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
`;

export const SegundoFooterContainer = styled.div`
    background-color: white;
    width: 100%;
    height: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4D4D4D;

    @media (max-width: 850px) {
        height: 120px;
        flex-wrap: wrap;
    }
`;

export const SegundoFooterItem = styled.div`
    border-right: 2px solid #B3B3B3;
    height: 20px;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const SegundoFooterItemLogo = styled.div`
    height: 30px;
    padding-left: 0.5rem;
`;

export const SegundoFooterLogo = styled.img`
    width: 110px;
    height: 22px;
`;

