import * as yup from "yup";
import { portugues } from "_utils/validador";

yup.setLocale(portugues);

export const filtrarSolicitacoesPagamentoSchema = yup.object().shape({
  placa: yup.string().min(7).max(8).optional().nullable(),
  renavam: yup.string().optional().max(11).nullable(),
  empresa: yup.string().optional().nullable(),
  sort: yup.string().optional().nullable(),
  prazoPagamento: yup.date().optional().nullable(),
  idSolicitacaoPagamento: yup.string().optional().nullable(),
  idConsulta: yup.string().optional().nullable(),
  statusAdiantamento: yup.string().optional().nullable(),
});

export const filtrarPagamentos = yup.object().shape({
  placa: yup.string().max(8).optional().nullable(),
  renavam: yup.string().optional().max(11).nullable(),
  prazoPagamento: yup.date().optional().nullable(),
  idTipoDebito: yup.string().optional().nullable(),
  statusPrazoPagamento: yup.string().optional().nullable(),
  statusPagamento: yup.array().optional().nullable(),
  fonteDeConsulta: yup.string().optional().nullable(),
});

export const listarDebitosSolicitadoPagamentoSchema = yup.object().shape({});

export const motivoRecusaPagamento = yup.object().shape({
  motivo: yup.string().required(),
});

export const pagamentoManual = yup.object().shape({
  dataPagamento: yup.string().required("Data de pagamento é obrigatório"),
  valorPagamento: yup
    .string()
    .test("valida-valor", "Valor de pagamento inválido", (value) => value !== "R$ ")
    .required("Valor de pagamento é obrigatório"),
  banco: yup.string().required("Banco é obrigatório"),
});
