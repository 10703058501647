import { CustomInput } from "components/CustomInput";
import { DropdownSelect } from "components/DropdownSelect";
import { Required } from "components/Required";
import { Container } from "./style";
import { Col, Row } from "react-bootstrap";
import { BodyModalExtratoVeicularProps } from "../BodyLote";
import { useMaskInput } from "hooks/useMaskInput";
import { useContext, useState } from "react";
import { Multiselect } from "components/Multiselect";
import { ufs } from "_utils/UnidadesFederativas";
import { ContextPermissao } from "hooks/ContextPermissao";

export function BodyIndividual({
  combosConsulta,
  control,
  controller: Controller,
  errors,
  isSubmitted,
  adicionaisHabilitados,
  handleChangeAdicionais,
}: Readonly<BodyModalExtratoVeicularProps>) {
  const { verificarUsuarioAwLw } = useContext(ContextPermissao);
  const { mInputPlaca, mInputRenavam } = useMaskInput();
  const [desabilitarRenavamChassi, setDesabilitarRenavamChassi] =
    useState<boolean>(false);
  const handleDisabled = (tipoConsulta: string) => {
    const somentePlaca = [
      "PRE_VENDA_NACIONAL_LEILÃO",
      "PRE_VENDA_NACIONAL",
      "DEBITO_NACIONAL",
      "DEBITO_NACIONAL_CL",
    ];
    if (somentePlaca.includes(tipoConsulta)) {
      setDesabilitarRenavamChassi(true);
      return;
    }
    setDesabilitarRenavamChassi(false);
  };

  return (
    <Container>
      <Row style={{ width: "100%" }}>
        <Col lg="3">
          <Controller
            control={control}
            name="tipoConsulta"
            render={({ field: { onChange, value } }) => (
              <DropdownSelect
                options={combosConsulta}
                onSelect={(e) => {
                  onChange(e);
                  handleDisabled(e);
                }}
                selectedValue={value}
                required
                title="Tipo de consulta"
                mensagemErro={errors?.tipoConsulta?.message}
                disabled
              />
            )}
          />
        </Col>
        <Col lg="3">
          <Controller
            name="adicionais"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Multiselect
                options={adicionaisHabilitados}
                onChange={(e) => {
                  onChange(e);
                  handleChangeAdicionais(e);
                }}
                title="Adicionais"
                value={value ?? []}
                mensagemErro={errors?.adicionais?.message}
                disabled={!adicionaisHabilitados.length}
              />
            )}
          />
        </Col>
        <Col lg="2">
          <Controller
            control={control}
            name="uf"
            render={({ field: { onChange, value } }) => (
              <DropdownSelect
                options={ufs.map((uf) => {
                  return { value: uf.abreviacao, label: uf.abreviacao };
                })}
                onSelect={(e) => {
                  onChange(e);
                }}
                selectedValue={value}
                required={!verificarUsuarioAwLw()}
                title="UF"
                mensagemErro={errors?.uf?.message}
                maxHeight={200}
              />
            )}
          />
        </Col>
        <Col lg="2">
          <label>
            Placa <Required />
          </label>
          <Controller
            control={control}
            name="placa"
            render={({ field: { onChange, value } }) => (
              <CustomInput
                placeholder="Informe a placa"
                isInvalid={errors?.placa}
                isValid={isSubmitted && !errors?.placa}
                value={value}
                onChange={(event) => onChange(mInputPlaca(event))}
                mensagemErro={errors?.placa?.message}
              />
            )}
          />
        </Col>
        <Col lg="2">
          <label>Renavam {!desabilitarRenavamChassi && !verificarUsuarioAwLw() && <Required />}</label>
          <Controller
            control={control}
            name="renavam"
            render={({ field: { onChange, value } }) => (
              <CustomInput
                placeholder="Informe o Renavam"
                isInvalid={errors?.renavam}
                isValid={isSubmitted && !errors?.renavam}
                onChange={(event) => onChange(mInputRenavam(event))}
                value={value}
                mensagemErro={errors?.renavam?.message}
                disabled={desabilitarRenavamChassi}
              />
            )}
          />
        </Col>
      </Row>
    </Container>
  );
}
