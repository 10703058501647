import { IHttpResponse } from "_services/api/interface/HttpResponse";
import { toastAtencao, toastErro, toastSucesso } from "components/Toast";
import { useCount } from "containers/CountMoney/hook/useCount";
import { Page } from "containers/Page";
import { useExpandableRow } from "hooks/useExpandableRow";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Filtros } from "./Containers/Filtros";
import { IFiltro } from "./Containers/Filtros/Interface/IFiltro";
import { ListarDetalhes } from "./Containers/ListarDetalhes";
import { ModalConfirmacaoSolicitacao } from "./Containers/ModalConfirmacaoSolicitacao";
import { ModalInformacaoPagamento } from "./Containers/ModalInformacaoPagamento";
import { ModalReconsultarDebitos } from "./Containers/ModalReconsultarDebitos";
import { ModalSolicitarPagamento } from "./Containers/ModalSolicitarPagamento";
import { useDetalhes } from "./hook/useDetalhes";
import { useSolicitarPagamento } from "./hook/useSolicitarPagamento";
import { ModalReciboAdiantamento } from "./Containers/ModalReciboAdiantamento";
import { IDadosClienteReciboAdiantamento } from "./Containers/ListarDetalhes/interface/IDadosClienteReciboAdiantamento";
import { ModalSucessoSolicitarPagamento } from "./Containers/ModalSucessoSolicitarPagamento";
import { mergeArquivosParaPdf } from "_utils/pdf/MergeArquivosParaPdf";
import { FormaPgtoEnum } from "features/debito/pagamento/enum/FormaPgtoEnum";
import { IDadosBanco } from "../../interfaces/IDadosBanco";
import { LoadingScreen } from "components/LoadingScreen";
import { ModalGerarCNAB } from "./Containers/ModalGerarCNAB";
import { ModalInfoCNAB } from "./Containers/ModalInfoCNAB";
import { ModalVisualizarCNABs } from "./Containers/ModalVisualizarCNABs";
import { ModalJaExisteCNABVinculado } from "./Containers/ModalJaExisteCNABVinculado";
import { ModalListaCNABsVinculados } from "./Containers/ModalListaCNABsVinculados";
import { dadosCnabsVinculadosDefault } from "./Constants/dadosCnabsVinculadosDefault";
import { cnabsDaConsultaDefault } from "./Constants/cnabsDaConsultaDefault";
import { dadosInfoCnabDefault } from "./Constants/dadosInfoCnabDefault";
import { ModalConfirmacaoGerarCnab } from "./Containers/ModalConfirmacaoGerarCnab";
import { ModalDebitosCnabsUltimasHoras } from "./Containers/ModalDebitosCnabsUltimasHoras";
import { dadosModalDebitosCnabsUltimasHorasDefault } from "./Constants/dadosModalDebitosCnabsUltimasHorasDefault";

interface ISolicitarPagamento {
  idConsulta: any;
  idsDebitos: any[];
  autorizadoPagamento: boolean;
  filtros?: IFiltro;
  idReciboAdiantamento: number;
  formaPgto: FormaPgtoEnum;
  codigoPix?: string;
  urlBoleto?: string;
  cliente: IDadosClienteReciboAdiantamento;
}

export function DetalheConsulta() {
  const { id: idConsulta } = useParams();

  const [dadosBanco, setDadosBanco] = useState<IDadosBanco>({
    codigoBanco: "",
    nomeBanco: "",
    agencia: "",
    numeroConta: "",
  });
  const [loadingPdf, setLoadingPdf] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [showConfirmacao, setShowConfirmacao] = useState<boolean>(false);
  const [idSolicitacao, setIdSolicitacao] = useState<number>(0);
  const [todos, setTodos] = useState<boolean>(false);
  const [dadosReconsultar, setDadosReconsultar] =
    useState<IHttpResponse | null>(null);
  const [disableReconsultar, setDisableReconsultar] = useState<boolean>(false);
  const [showModalInformacaoPagamento, setShowModalInformacaoPagamento] =
    useState<boolean>(false);
  const [formaPgto, setFormaPgto] = useState<FormaPgtoEnum | undefined>();
  const { setRowExpandToggled, rowToExpand } = useExpandableRow();
  const [showModalReciboAdiantamento, setShowModalReciboAdiantamento] =
    useState<boolean>(false);
  const [maisOpcoes, setMaisOpcoes] = useState<boolean>(false);
  const [idReciboAdiantamento, setIdReciboAdiantamento] = useState<number>(0);
  const [dadosClienteReciboAdiantamento, setDadosClienteReciboAdiantamento] =
    useState<IDadosClienteReciboAdiantamento>({
      endereco: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      cnpj: "",
      razaoSocial: "",
      nomeFantasia: "",
      id: 0,
    });
  const [codigoPix, setCodigoPix] = useState<string>("");
  const [
    showModalSucessoSolicitacaoPagamento,
    setShowModalSucessoSolicitacaoPagamento,
  ] = useState<boolean>(false);
  const statusNotFound = 404;
  const [showModalGerarCNAB, setShowModalGerarCNAB] = useState<boolean>(false);
  const [erroMultiTextos, setErroMultiTextos] = useState<string>("");
  const [enviarArquivoCnabPara, setEnviarArquivoCnabPara] = useState<string[]>(
    []
  );
  const [selecaoBanco, setSelecaoBanco] = useState<string>("automatica");
  const [bancoSelecionado, setBancoSelecionado] = useState<number | string>(0);
  const [showModalInfoCNAB, setShowModalInfoCNAB] = useState<boolean>(false);
  const [showModalVisualizarCNABs, setShowModalVisualizarCNABs] =
    useState<boolean>(false);
  const [showModalJaExisteCNABVinculado, setShowModalJaExisteCNABVinculado] =
    useState<boolean>(false);
  const [showModalListaCNABsVinculados, setShowModalListaCNABsVinculados] =
    useState<boolean>(false);
  const [pesquisaModalCnab, setPesquisaModalCnab] = useState<string>("");
  const [showModalConfirmacaoGerarCnab, setShowModalConfirmacaoGerarCnab] =
    useState<boolean>(false);
  const [
    showModalDebitosCnabsUltimasHoras,
    setShowModalDebitosCnabsUltimasHoras,
  ] = useState<boolean>(false);

  const {
    setSelectedRows,
    total,
    selectedRows,
    limparSelectedRows,
    setTotal,
    quantidadeDebitos,
    quantidadeDebitosForaDeSla,
    setQuantidadeDebitos,
    setQuantidadeDebitosForaDeSla,
    autorizadoPagamento,
    setAutorizadoPagamento,
    setDadosPorTipoDebito,
    dadosPorTipoDebito,
  } = useCount();

  const { solicitarPagamento, loadingPagamento, buscarDadosClienteEIdRecibo } =
    useSolicitarPagamento();

  const {
    setDados,
    dados,
    loading,
    setFiltro,
    buscarDetalhes,
    setLimit,
    limit,
    setPage,
    filtro,
    page,
    totalRows,
    buscarTodosDebitosConsulta,
    reconsultarDebitos,
    buscarResumoConsultaV2,
    resumo,
    idCliente,
    listarDetalhes,
    gerarCNAB,
    loadingDark,
    dadosBancosGerarCnab,
    setDadosBancosGerarCnab,
    getDadosBancosGerarCNAB,
    dadosDebitosJaVinculadosCNAB,
    setDadosDebitosJaVinculadosCNAB,
    getDadosCnabsVinculadosAoDebito,
    dadosCnabsVinculados,
    setDadosCnabsVinculados,
    getCnabsPorConsulta,
    cnabsDaConsulta,
    setCnabsDaConsulta,
    dadosInfoCnab,
    setDadosInfoCnab,
    getInfoCnab,
    reenviarEmailCnab,
    reprocessarArquivoCnab,
    dadosModalDebitosCnabsUltimasHoras,
    setDadosModalDebitosCnabsUltimasHoras,
    getDadosModalDebitosCnabsUltimasHoras,
    getDadosBancosEscolhaManual,
    optionsBanco,
    setOptionsBanco,
    setLoadingDark,
  } = useDetalhes();

  async function buscarDetalhesSemPaginacao() {
    const paginacao = false;
    const { data, hasErro } = await listarDetalhes(idConsulta!, paginacao);

    if (hasErro || data.status == statusNotFound) {
      toastErro(data.message);
      return;
    }
    return data;
  }

  const handleClose = useCallback(() => {
    if (todos) {
      limparSelectedRows();
      setTodos(false);
    }
    setShow(false);
  }, [limparSelectedRows, setTodos, setShow, todos]);

  const buscarValorDebitos = useCallback(async () => {
    const { data, hasErro } = await buscarTodosDebitosConsulta(idConsulta!);

    if (hasErro || data.status == statusNotFound) {
      toastErro(data.message);
      handleClose();
      return;
    }
    setDadosPorTipoDebito([
      {
        descricao: "Pagamento IPVA",
        quantidade: Number(data.dadosPorTipoDebito.IPVA.quantidade),
        valor: Number(data.dadosPorTipoDebito.IPVA.valor),
      },
      {
        descricao: "Pagamento Licenciamento",
        quantidade: Number(data.dadosPorTipoDebito.Licenciamento.quantidade),
        valor: Number(data.dadosPorTipoDebito.Licenciamento.valor),
      },
      {
        descricao: "Pagamento Multa",
        quantidade: Number(data.dadosPorTipoDebito.Multa.quantidade),
        valor: Number(data.dadosPorTipoDebito.Multa.valor),
      },
      {
        descricao: "Pagamento DPVAT",
        quantidade: Number(data.dadosPorTipoDebito.DPVAT.quantidade),
        valor: Number(data.dadosPorTipoDebito.DPVAT.valor),
      },
    ]);
    setQuantidadeDebitos(data.totalDebitos);
    setTotal(data.valorTotal);
    setQuantidadeDebitosForaDeSla(data.totalForaDeSla);
    setAutorizadoPagamento(data.totalForaDeSla === 0);
    setIdsDebitos(data.idsDebitos);
  }, [filtro]);

  const [idsDebitos, setIdsDebitos] = useState<number[]>([]);

  useEffect(() => {
    buscarDetalhes(idConsulta);
    buscarResumoConsultaV2(idConsulta);
  }, [buscarDetalhes, filtro, idConsulta, page, limit]);

  const [clearRows, setClearRows] = useState<boolean>(false);

  useEffect(() => {
    setDisableReconsultar(false);
    if (selectedRows.length > 0) {
      setDisableReconsultar(true);
    }
  }, [selectedRows]);

  const handleShow = () => setShow(true);

  const handleCloseModalConfirmacao = () => {
    setShowConfirmacao(false);
  };

  const solicitarTodos = async () => {
    limparSelectedRows();
    setTodos(true);

    handleShow();
  };

  function handleShowModalInformacaoPagamento() {
    setShowModalInformacaoPagamento(true);
  }

  async function handleSolicitarPagamento() {
    if (selectedRows.length > 0) {
      handleShowModalInformacaoPagamento();
      return;
    }

    await solicitarTodos();
  }

  const getLinhas = (linhas: any[]) => {
    setSelectedRows(linhas);
    handleIdsDebitos(linhas);
  };

  function handleIdsDebitos(debitos: any[]) {
    setIdsDebitos(debitos.map((item) => item.id));
  }

  const getFiltro = (filtros: IFiltro) => {
    setFiltro(filtros);
  };

  const solicitaPagamentos = async () => {
    if (!formaPgto) {
      toastAtencao("É necessário escolher uma forma pagamento para prosseguir");
      return;
    }

    const dadosSolicitarPagamento: ISolicitarPagamento = {
      idConsulta: idConsulta!,
      idsDebitos,
      autorizadoPagamento,
      filtros: filtro,
      idReciboAdiantamento,
      formaPgto,
      cliente: dadosClienteReciboAdiantamento,
    };

    const { data, hasErro } = await solicitarPagamento(dadosSolicitarPagamento);
    if (hasErro) {
      handleCloseModalReciboAdiantamento();
      handleClearTable();
      return;
    }

    setIdSolicitacao(data.id);
    setCodigoPix(data.codigoPix);
    buscarDetalhes(idConsulta);

    const downloadElement = document.getElementById(
      `downloadPDF_${idReciboAdiantamento}`
    );

    try {
      setLoadingPdf(true);
      if (downloadElement && downloadElement instanceof HTMLAnchorElement) {
        const itensPdf = [downloadElement.href];
        if (formaPgto === FormaPgtoEnum.PIXBOLETO) {
          itensPdf.push(data.linkBoleto);
        }
        const blobPdf = await mergeArquivosParaPdf(itensPdf);
        const url = URL.createObjectURL(blobPdf);
        setShowModalSucessoSolicitacaoPagamento(true);
        window.open(url);
      } else {
        throw new Error("PDF não encontrado.");
      }
    } catch (e) {
      toastErro(
        "Não foi possível baixar o arquivo. Por favor, entre em contato com o suporte."
      );
    }

    setLoadingPdf(false);
    handleCloseModalReciboAdiantamento();
    handleClearTable();
  };

  const handleLimitChange = (limit: number) => {
    setLimit(limit);
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  function handleClearTable() {
    setClearRows(true);
  }

  function handleCheck(checked: boolean) {
    setAutorizadoPagamento(checked);
  }

  async function handleReconsultarDebitos() {
    const response = await reconsultarDebitos(idConsulta);
    setDadosReconsultar(response);
  }

  const onConfirmModalSolicitarPagamento = async () => {
    if (quantidadeDebitosForaDeSla > 0 && !autorizadoPagamento) {
      toastAtencao("É necessário autorizar o pagamento para prosseguir");
      return;
    }

    if (!idCliente) {
      toastErro("Dados o cliente não encontrados");
      return;
    }
    const { data, hasErro } = await buscarDadosClienteEIdRecibo(idCliente);

    if (hasErro) {
      toastErro(`${data.message}`);
      handleClose();
      return;
    }

    if (
      !data.cliente.endereco ||
      !data.cliente.numero ||
      !data.cliente.bairro ||
      !data.cliente.cidade ||
      !data.cliente.estado ||
      !data.cliente.cep ||
      !data.cliente.cnpj ||
      !data.cliente.razaoSocial ||
      !data.cliente.nomeFantasia
    ) {
      toastErro(
        "Não foi possível obter todos os dados necessários para o pagamento"
      );
      handleClose();
      return;
    }

    setDadosClienteReciboAdiantamento({
      endereco: data.cliente.endereco,
      numero: data.cliente.numero,
      bairro: data.cliente.bairro,
      cidade: data.cliente.cidade,
      estado: data.cliente.estado,
      cep: data.cliente.cep,
      cnpj: data.cliente.cnpj,
      razaoSocial: data.cliente.razaoSocial,
      nomeFantasia: data.cliente.nomeFantasia,
      id: idCliente,
    });
    setIdReciboAdiantamento(data.idReciboAdiantamento);
    setShowModalReciboAdiantamento(true);
    setShow(false);
    setDadosBanco(data.banco);
  };

  const handleCloseModalReciboAdiantamento = () => {
    setFormaPgto(undefined);
    setShowModalReciboAdiantamento(false);
    setMaisOpcoes(false);
    handleClose();
  };

  const closeModalDebitosCnabsUltimasHoras = () => {
    setShowModalDebitosCnabsUltimasHoras(false);
    setDadosModalDebitosCnabsUltimasHoras(
      dadosModalDebitosCnabsUltimasHorasDefault
    );
  };

  const onClickGerarCNAB = async () => {
    await getDadosModalDebitosCnabsUltimasHoras(idsDebitos, idConsulta);
  };

  useEffect(() => {
    if (!dadosModalDebitosCnabsUltimasHoras.totalDebitos) {
      setLoadingDark(false);
      return;
    }

    if (dadosModalDebitosCnabsUltimasHoras.quantidadeDuplicados) {
      setLoadingDark(false);
      setShowModalDebitosCnabsUltimasHoras(true);
      return;
    }

    onProsseguirGerarCNAB();
  }, [dadosModalDebitosCnabsUltimasHoras]);

  const onProsseguirGerarCNAB = async () => {
    if (dadosModalDebitosCnabsUltimasHoras.quantidadeDuplicados) {
      setLoadingDark(false);
      setShowModalDebitosCnabsUltimasHoras(false);
      setShowModalJaExisteCNABVinculado(true);
      return;
    }

    if (!idConsulta) {
      setLoadingDark(false);
      toastErro("Consulta não identificada");
      return;
    }

    const { hasErro } = await getDadosBancosGerarCNAB(
      idConsulta,
      idsDebitos.length ? idsDebitos : null
    );

    if (hasErro) {
      setLoadingDark(false);
      return;
    }

    const { hasErro: temErro } = await getDadosBancosEscolhaManual(idCliente);
    setLoadingDark(false);
    if (temErro) {
      return;
    }

    setShowModalGerarCNAB(true);
  };

  const onConfirmModalJaExisteCNABVinculado = async () => {
    setShowModalJaExisteCNABVinculado(false);

    if (!idConsulta) {
      toastErro("Consulta não identificada");
      return;
    }

    const { hasErro } = await getDadosBancosGerarCNAB(
      idConsulta,
      idsDebitos.length ? idsDebitos : null
    );

    if (hasErro) {
      setLoadingDark(false);
      return;
    }

    const { hasErro: temErro } = await getDadosBancosEscolhaManual(idCliente);

    setLoadingDark(false);
    if (temErro) {
      return;
    }

    setShowModalGerarCNAB(true);
  };

  const closeModalJaExisteCNABVinculado = () => {
    setShowModalJaExisteCNABVinculado(false);
    setDadosDebitosJaVinculadosCNAB([]);
    setDadosCnabsVinculados(dadosCnabsVinculadosDefault);
  };

  const closeModalGerarCNAB = () => {
    setShowModalGerarCNAB(false);
    setBancoSelecionado(0);
    setSelecaoBanco("automatica");
    setEnviarArquivoCnabPara([]);
    setDadosBancosGerarCnab([]);
    setOptionsBanco([]);
  };

  const onConfirmGerarCNAB = async () => {
    setShowModalGerarCNAB(false);
    const { hasErro } = await gerarCNAB(
      idsDebitos,
      idConsulta,
      enviarArquivoCnabPara,
      bancoSelecionado
    );

    if (hasErro) {
      setShowModalGerarCNAB(true);
      return;
    }

    closeModalGerarCNAB();
    setShowModalConfirmacaoGerarCnab(true);
    setSelectedRows([]);
    handleIdsDebitos([]);
    setClearRows(true);
  };

  const closeModalListaCNABsVinculados = () => {
    setShowModalListaCNABsVinculados(false);
    setShowModalJaExisteCNABVinculado(true);
  };

  const onClickVerCnabsVinculados = async (idDebito: number) => {
    setShowModalJaExisteCNABVinculado(false);

    const { hasErro } = await getDadosCnabsVinculadosAoDebito(idDebito);

    if (!hasErro) {
      setShowModalListaCNABsVinculados(true);
      return;
    }

    setShowModalJaExisteCNABVinculado(true);
  };

  const onChangeEnviarArquivoCnabPara = (emails: string[]) => {
    setEnviarArquivoCnabPara(emails);
    if (emails.some((email) => !email.includes("@"))) {
      setErroMultiTextos("Apenas e-mails são permitidos");
      return;
    }
    setErroMultiTextos("");
  };

  const onClickInfoCnab = async (idDebito: number) => {
    setShowModalJaExisteCNABVinculado(false);

    const { hasErro, data } = await getInfoCnab(idDebito);

    if (!hasErro) {
      if (data.cnabs.length) {
        setShowModalInfoCNAB(true);
        return;
      }
      toastErro("Nenhum CNAB encontrado");
      setShowModalJaExisteCNABVinculado(true);
    }
  };

  const handleCloseModalCnabInfo = () => {
    if (dadosDebitosJaVinculadosCNAB.length) {
      setShowModalJaExisteCNABVinculado(true);
    }
    setDadosInfoCnab(dadosInfoCnabDefault);
    setShowModalInfoCNAB(false);
  };

  const tentarNovamenteCnab = async (idDebito: number, idCnab: number) => {
    setShowModalInfoCNAB(false);
    let { hasErro } = await reprocessarArquivoCnab(idCnab);

    if (!hasErro) {
      onClickInfoCnab(idDebito);
    }
  };

  const onClickBtnVisualizarCnabs = async () => {
    if (!idConsulta) {
      toastErro("Consulta não identificada");
      return;
    }
    const { hasErro } = await getCnabsPorConsulta(idConsulta);

    if (!hasErro) {
      setShowModalVisualizarCNABs(true);
    }
  };

  const closeModalVisualizarCNABs = () => {
    setShowModalVisualizarCNABs(false);
    setCnabsDaConsulta(cnabsDaConsultaDefault);
  };

  const onClickReenviarEmailCnab = async (idCnab: number, nome: string) => {
    setShowModalVisualizarCNABs(false);
    const { hasErro } = await reenviarEmailCnab(idCnab);

    if (hasErro) {
      setShowModalVisualizarCNABs(true);
      return;
    }

    toastSucesso(`Arquivo ${nome} reenviado!`);
    if (!idConsulta) {
      toastErro("Consulta não identificada");
      return;
    }

    const { hasErro: temErro } = await getCnabsPorConsulta(idConsulta);

    if (!temErro) {
      setShowModalVisualizarCNABs(true);
    }
  };

  const onClickReprocessarArquivoCnab = async (idCnab: number) => {
    setShowModalVisualizarCNABs(false);
    const { hasErro } = await reprocessarArquivoCnab(idCnab);

    if (!hasErro && idConsulta) {
      getCnabsPorConsulta(idConsulta, pesquisaModalCnab);
      toastSucesso(
        <span style={{ fontSize: "14px" }}>
          <strong>Solicitação efetuada com sucesso!</strong>
          <br />O arquivo será reprocessado
        </span>
      );
      setShowModalVisualizarCNABs(true);
    }
  };

  let timeout: NodeJS.Timeout;
  const onChangePesquisarCnab = (pesquisa: string) => {
    const minimoCaracteresPesquisa = 3;
    const aguardarMilissegundos = 1500;
    clearTimeout(timeout);

    if (pesquisa.length < minimoCaracteresPesquisa) {
      return;
    }

    timeout = setTimeout(() => {
      if (!idConsulta) {
        return;
      }
      getCnabsPorConsulta(idConsulta, pesquisa);
      setPesquisaModalCnab(pesquisa);
    }, aguardarMilissegundos);
  };

  return (
    <Page title="Detalhes Consultar Débitos" redirectDoIcone="/debito/consulta">
      <Filtros handleSubmitFiltros={getFiltro} />
      <ModalSolicitarPagamento
        show={show}
        handleClose={handleClose}
        total={total}
        loading={loading}
        todos={todos}
        onConfirm={onConfirmModalSolicitarPagamento}
        loadingPagamento={loadingPagamento}
        idConsulta={idConsulta!}
        buscarValorTodosDebitos={buscarValorDebitos}
        setIdsDebitos={handleIdsDebitos}
        quantidadeDebitos={quantidadeDebitos}
        quantidadeDebitosForaDeSla={quantidadeDebitosForaDeSla}
        handleCheck={handleCheck}
      />
      <ListarDetalhes
        buscarDetalhesSemPaginacao={buscarDetalhesSemPaginacao}
        limit={limit}
        dados={dados}
        setDados={setDados}
        loading={loading}
        idConsulta={idConsulta}
        setRowExpandToggled={setRowExpandToggled}
        setSelectedRows={getLinhas}
        disableReconsultar={disableReconsultar}
        onClickReconsultar={handleReconsultarDebitos}
        handleShow={handleShow}
        rowToExpand={rowToExpand}
        solicitarTodos={handleSolicitarPagamento}
        onChangeRowsPerPage={handleLimitChange}
        onChangePage={handlePageChange}
        totalRows={totalRows}
        clearSelectedRows={clearRows}
        filtro={filtro}
        resumo={resumo}
        onClickGerarCNAB={onClickGerarCNAB}
        onClickVisualizar={onClickBtnVisualizarCnabs}
        idsDebitosSelecionados={idsDebitos}
        onClickInfoCnab={onClickInfoCnab}
      />
      <ModalReconsultarDebitos
        handleClose={() => {
          setDadosReconsultar(null);
          buscarDetalhes(idConsulta);
        }}
        dados={dadosReconsultar}
      />
      <ModalConfirmacaoSolicitacao
        idSolicitacao={idSolicitacao}
        handleClose={handleCloseModalConfirmacao}
        show={showConfirmacao}
      />
      <ModalInformacaoPagamento
        show={showModalInformacaoPagamento}
        handleClose={() => setShowModalInformacaoPagamento(false)}
        onConfirm={() => {
          setShowModalInformacaoPagamento(false);
          handleShow();
        }}
      />
      <ModalReciboAdiantamento
        show={showModalReciboAdiantamento}
        handleClose={handleCloseModalReciboAdiantamento}
        solicitarPagamento={solicitaPagamentos}
        loading={loadingPagamento || loadingPdf}
        setFormaPgto={setFormaPgto}
        formaPgto={formaPgto}
        dados={{
          dadosPorTipoDebito,
          valorTotal: total,
          idReciboAdiantamento,
          dadosClienteReciboAdiantamento,
          dadosBanco,
        }}
        maisOpcoes={maisOpcoes}
        setMaisOpcoes={setMaisOpcoes}
      />
      <ModalSucessoSolicitarPagamento
        show={showModalSucessoSolicitacaoPagamento}
        handleClose={() => {
          setIdSolicitacao(0);
          setCodigoPix("");
          setShowModalSucessoSolicitacaoPagamento(false);
        }}
        codigoPix={codigoPix}
        id={idSolicitacao}
      ></ModalSucessoSolicitarPagamento>
      <ModalGerarCNAB
        show={showModalGerarCNAB}
        handleClose={closeModalGerarCNAB}
        onConfirm={onConfirmGerarCNAB}
        loteInteiro={!selectedRows.length}
        enviarArquivoCnabPara={enviarArquivoCnabPara}
        onChangeEnviarArquivoCnabPara={onChangeEnviarArquivoCnabPara}
        setSelecaoBanco={setSelecaoBanco}
        setBancoSelecionado={setBancoSelecionado}
        selecaoBanco={selecaoBanco}
        dadosBancosGerarCnab={dadosBancosGerarCnab}
        setDadosBancosGerarCnab={setDadosBancosGerarCnab}
        bancoSelecionado={bancoSelecionado}
        erroMultiTextos={erroMultiTextos}
        bancosOptions={optionsBanco}
      />
      <ModalInfoCNAB
        handleClose={handleCloseModalCnabInfo}
        show={showModalInfoCNAB}
        dadosInfoCnab={dadosInfoCnab}
        tentarNovamente={tentarNovamenteCnab}
      />
      <ModalVisualizarCNABs
        show={showModalVisualizarCNABs}
        handleClose={closeModalVisualizarCNABs}
        dados={cnabsDaConsulta}
        reenviar={onClickReenviarEmailCnab}
        reprocessar={onClickReprocessarArquivoCnab}
        onChange={onChangePesquisarCnab}
      />
      <ModalJaExisteCNABVinculado
        handleClose={closeModalJaExisteCNABVinculado}
        show={showModalJaExisteCNABVinculado}
        dados={dadosDebitosJaVinculadosCNAB}
        onConfirm={onConfirmModalJaExisteCNABVinculado}
        onClickVerCnabsVinculados={onClickVerCnabsVinculados}
        onClickColunaCnab={onClickInfoCnab}
      />
      <ModalListaCNABsVinculados
        show={showModalListaCNABsVinculados}
        handleClose={closeModalListaCNABsVinculados}
        dados={dadosCnabsVinculados}
      />
      <ModalConfirmacaoGerarCnab
        show={showModalConfirmacaoGerarCnab}
        handleClose={() => setShowModalConfirmacaoGerarCnab(false)}
      />
      <ModalDebitosCnabsUltimasHoras
        onConfirm={onProsseguirGerarCNAB}
        show={showModalDebitosCnabsUltimasHoras}
        handleClose={closeModalDebitosCnabsUltimasHoras}
        dados={dadosModalDebitosCnabsUltimasHoras}
      />
      <LoadingScreen mostrar={loadingDark} />
    </Page>
  );
}
