export const dadosCnabsVinculadosDefault = {
  debito: {
    id: 0,
    placa: "",
    uf: "",
    vencimento: "",
    valor: 0,
    debito: 1,
  },
  cnabs: [],
};
