import { ModalCustom } from "components/ModalCustom";
import { FaCheck } from "react-icons/fa";
import { Container, IconeContainer, Texto, TextoContainer } from "./style";

interface IModalConfirmacaoPagamentoManual {
  show: boolean;
  handleClose: () => void;
}

export function ModalSucessoPagamentoManualLote({
  show,
  handleClose,
}: IModalConfirmacaoPagamentoManual) {
  return (
    <ModalCustom
      show={show}
      handleClose={handleClose}
      title="Informar pagamento manual"
      centered={true}
    >
      <Container>
        <IconeContainer>
          <FaCheck />
        </IconeContainer>
        <TextoContainer>
          <Texto>Pagamentos confirmados com sucesso!</Texto>
        </TextoContainer>
      </Container>
    </ModalCustom>
  );
}
