import { yupResolver } from "@hookform/resolvers/yup";
import { ButtonCuston } from "components/ButtonCuston/ButtonCuston";
import { CustomInput } from "components/CustomInput";
import { CustomDatePickerV2 } from "components/DatePickerV2";
import { DropdownSelect, IOptions } from "components/DropdownSelect";
import {
  TipoDebitos,
  TipoDebitosString,
} from "features/debito/consulta/enum/TipoDebitos";
import { ICadastrarDebitos } from "features/debito/consulta/interfaces/ICadastrarDebitos";
import { cadastrarDebitosSchema } from "features/debito/consulta/validate/validator";
import { useMaskInput } from "hooks/useMaskInput";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDebitos } from "../../../hook/useDebitos";
import { FormContainer, SpanTitle } from "./style";
import { IDetalhesConsulta } from "features/debito/consulta/interfaces/IDetalhesConsulta";

interface Props {
  close: Callback;
  consultaId: number;
  debitosId: number;
  placa?: string;
  chassi?: string;
  renavam?: string;
  uf?: string;
  disabled?: boolean;
  tipoDebito?: TipoDebitos;
  dados: IDetalhesConsulta[];
  setDados: (dados: IDetalhesConsulta[]) => void;
}

export function ConsultaManualUnitaria({
  close,
  consultaId,
  debitosId,
  placa,
  chassi,
  renavam,
  uf,
  tipoDebito,
  setDados,
  dados,
  disabled
}: Props) {
  const { mDinheiroInput, mInputPlaca, mInputRenavam } = useMaskInput();

  const { cadastroManual, loading } = useDebitos();
  const [optionsExercicio, setOptionsExercicio] = useState<IOptions[]>([]);

  const {
    control,
    formState: { errors, isSubmitted },
    handleSubmit,
    register,
    reset,
  } = useForm<ICadastrarDebitos>({
    resolver: yupResolver(cadastrarDebitosSchema),
  });

  async function submitFormulario(dadosForm: ICadastrarDebitos) {
    dadosForm.tipoDebito =
      TipoDebitos[dadosForm.tipoDebito as keyof typeof TipoDebitos];

    const { hasErro } = await cadastroManual({
      ...dadosForm,
      consultaId,
      debitosId,
    });

    if (!hasErro) {
      setDados(
        dados.map((item: IDetalhesConsulta) => {
          if (item.id === debitosId) {
            const statusConsultando = "0";

            return {
              ...item,
              statusDebito: statusConsultando,
              descricao: "",
              fonteDeConsulta: "MANUAL",
            };
          }
          return item;
        })
      );

      close();
    }
  }
  useEffect(() => {
    const anoAtual = new Date().getFullYear();
    reset({
      placa,
      chassi,
      renavam,
      uf,
      tipoDebito: TipoDebitosString.get(tipoDebito),
      exercicio: anoAtual,
    });

    setOptionsExercicio([
      {
        label: `${anoAtual}`,
        value: anoAtual,
      },
      {
        label: `${anoAtual - 1}`,
        value: anoAtual - 1,
      },
      {
        label: `${anoAtual - 2}`,
        value: anoAtual - 2,
      },
    ]);
  }, []);

  return (
    <FormContainer>
      <SpanTitle>Dados do veículo</SpanTitle>
      <Form noValidate onSubmit={handleSubmit(submitFormulario)}>
        <Row className="mt-4 mb-5">
          <Col>
            <Form.Group>
              <Form.Label>Placa</Form.Label>
              <CustomInput
                disabled={true}
                type="text"
                placeholder="Informe a placa"
                {...register("placa")}
                isValid={isSubmitted && !errors.placa}
                isInvalid={!!errors.placa}
                onChange={mInputPlaca}
                mensagemErro={errors.placa?.message}
                style={{ height: "2.2rem" }}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group>
              <Form.Label>Renavam</Form.Label>
              <CustomInput
                disabled={true}
                type="text"
                placeholder="Informe o renavam"
                {...register("renavam")}
                isValid={isSubmitted && !errors.uf}
                isInvalid={!!errors.renavam}
                onChange={mInputRenavam}
                mensagemErro={errors.renavam?.message}
                style={{ height: "2.2rem" }}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group>
              <Form.Label>UF</Form.Label>
              <CustomInput
                disabled={true}
                type="text"
                placeholder="Informe UF"
                {...register("uf")}
                isValid={isSubmitted && !errors.uf}
                isInvalid={!!errors.uf}
                mensagemErro={errors.uf?.message}
                style={{ height: "2.2rem" }}
              />
            </Form.Group>
          </Col>
        </Row>
        <SpanTitle>Dados do débito</SpanTitle>
        <Row className="mt-4">
          <Col>
            <Form.Group>
              <Form.Label>Debito</Form.Label>
              <CustomInput
                disabled={true}
                type="text"
                placeholder="Informe o tipo debitos"
                {...register("tipoDebito")}
                isValid={isSubmitted && !errors.tipoDebito}
                isInvalid={!!errors.tipoDebito}
                mensagemErro={errors.tipoDebito?.message}
                style={{ height: "2.2rem" }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Cota IPVA</Form.Label>
              <Controller
                name="cota"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    required
                    disabled={tipoDebito != TipoDebitos.IPVA}
                    mensagemErro={isSubmitted ? errors.cota?.message : null}
                    selectedValue={value}
                    options={[
                      {
                        label: "Cota uníca",
                        value: 0,
                      },
                      {
                        label: "1",
                        value: 1,
                      },
                      {
                        label: "2",
                        value: 2,
                      },
                      {
                        label: "3",
                        value: 3,
                      },
                      {
                        label: "4",
                        value: 4,
                      },
                      {
                        label: "5",
                        value: 5,
                      },
                      {
                        label: "6",
                        value: 6,
                      },
                      {
                        label: "7",
                        value: 7,
                      },
                      {
                        label: "8",
                        value: 8,
                      },
                      {
                        label: "9",
                        value: 9,
                      },
                      {
                        label: "10",
                        value: 10,
                      },
                    ]}
                    onSelect={onChange}
                  />
                )}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group>
              <Form.Label>Vencimento</Form.Label>
              <Controller
                control={control}
                name="vencimento"
                render={({ field: { onChange, value } }) => (
                  <CustomDatePickerV2
                    onChange={onChange}
                    selected={value}
                    placeholder="Informe vencimento"
                    mensagemErro={errors.vencimento?.message}
                    isValid={isSubmitted && !errors.vencimento}
                    isInvalid={!!errors.vencimento}
                    isClearable={false}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Valor total</Form.Label>
              <CustomInput
                type="text"
                placeholder="Informe valor"
                {...register("valor")}
                isValid={isSubmitted && !errors.valor}
                isInvalid={!!errors.valor}
                mensagemErro={errors.valor?.message}
                style={{ height: "2.2rem" }}
                onChange={mDinheiroInput}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Exercício</Form.Label>

              <Controller
                control={control}
                name="exercicio"
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    onSelect={(e) => {
                      onChange(e);
                    }}
                    disabled={disabled}
                    options={optionsExercicio}
                    selectedValue={value}
                    mensagemErro={errors.exercicio?.message}
                  />
                )}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Código de barras</Form.Label>
              <CustomInput
                type="text"
                placeholder="Informe código de barras"
                {...register("linhaDigitavel")}
                isValid={isSubmitted && !errors.linhaDigitavel}
                isInvalid={!!errors.linhaDigitavel}
                mensagemErro={errors.linhaDigitavel?.message}
                style={{ height: "2.2rem" }}
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="mt-4 mb-3 d-flex justify-content-end gap-4">
          <ButtonCuston variant="primary" onClick={close} outline={true}>
            Cancelar
          </ButtonCuston>
          <ButtonCuston variant="primary" loading={loading}>
            Salvar
          </ButtonCuston>
        </div>
      </Form>
    </FormContainer>
  );
}
