import { yupResolver } from "@hookform/resolvers/yup";
import { PrimaryButton } from "components/Button/style";
import { CustomInput } from "components/CustomInput";
import { DropdownSelect, IOptions } from "components/DropdownSelect";
import { Multiselect } from "components/Multiselect";
import { Required } from "components/Required";
import { Consultar } from "features/debito/consulta/enum/Consultar";
import { useSolicitacaoConsulta } from "features/debito/consulta/hooks/useSolicitacaoConsulta";
import { IConsultarVeiculo } from "features/debito/consulta/interfaces/IConsultaVeiculo";
import { consultarVeiculoUnitarioSchema } from "features/debito/consulta/validate/validator";
import { Cobrar, MotivosIsencaoOptions } from "features/licenciamento/emissao/constants";
import {
  InputsContainer,
  RadioContainer,
  SpanErro,
} from "features/licenciamento/emissao/pages/Listar/containers/ModalEmitirLicenciamento/containers/BodySP/containers/SelecionarEmpresa/style";
import { ContextPermissao } from "hooks/ContextPermissao";
import { useEmpresas } from "hooks/useEmpresas";
import { useMaskInput } from "hooks/useMaskInput";
import { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FormContainer, InfoContainer, InfoSwitchContainer, InputTitle } from "./style";
import { Tooltip } from "components/Tooltip";
import { Question } from "components/Icons/Question";
import { InfoTooltip } from "components/InfoTooltip";
import { OpcaoCota } from "features/debito/consulta/enum/OpcaoCota";
import { IOptionsCheck } from "features/debito/consulta/interfaces/IOptionsCheck";
import { Informacao } from "components/Informacao";
import { Switch } from "components/Switch";
import { AlertaToolTip } from "components/AlertaToolTip";

interface Props {
  close: Callback;
  listarConsultasSolicitada: Callback;
  handleChangeEmpresa: (idEmpresa: number | string) => void;
  optionsUf: IOptions[];
  setTipoDebitoEscolhido: (e: Array<"IPVA" | "LICENCIAMENTO"> | null) => void;
  setUfEscolhida: (array: string[]) => void;
  optionsCotaIpva: IOptions[];
  setOpcaoCotaEscolhida: (e: OpcaoCota.PARCELADA | OpcaoCota.UNICA | null) => void;
  setShowModalValor: (show: boolean) => void;
  setLoadingModalValor: (loading: boolean) => void;
  setValorConsulta: (valor: number) => void;
  setQtdLinhas: (quantidade: number) => void;
  setOnConfirmModalValor: (funcao: () => void) => void;
  optionsTipoDebito: IOptionsCheck[];
  listarConfigServicos: (empresa: number | string) => void;
}

export function BodyUnitario({
  close,
  listarConsultasSolicitada,
  handleChangeEmpresa,
  optionsUf,
  setTipoDebitoEscolhido,
  setUfEscolhida,
  optionsCotaIpva,
  setOpcaoCotaEscolhida,
  setShowModalValor,
  setLoadingModalValor,
  setValorConsulta,
  setQtdLinhas,
  setOnConfirmModalValor,
  optionsTipoDebito,
  listarConfigServicos,
}: Props) {
  const { mInputPlaca, mInputRenavam } = useMaskInput();
  const { solicitarConsulta } = useSolicitacaoConsulta();
  const [disableCota, setDisableCota] = useState(true);
  const [cobranca, setCobranca] = useState<string | null>(null);
  const { criarOptionsAgrupamento, agrupamentoOptions, empresasAgrupamento } = useEmpresas();
  const { verificarUsuarioAwLw } = useContext(ContextPermissao);
  const [cobrado, setCobrado] = useState<boolean>(true);

  function buscarNomeEmpresa(idCliente: NullableString) {
    return empresasAgrupamento.find((item) => item.idClienteAgrupamento === Number(idCliente))?.razaoSocial;
  }

  useEffect(() => {
    criarOptionsAgrupamento();
  }, [criarOptionsAgrupamento]);

  const {
    control,
    formState: { errors, isSubmitted },
    handleSubmit,
    register,
    setError,
    getValues,
    setValue,
  } = useForm<IConsultarVeiculo>({
    resolver: yupResolver(consultarVeiculoUnitarioSchema),
    defaultValues: {
      comCodigoDeBarras: false,
    }
  });

  async function solicitarConsultaUnitaria() {
    let data: any = getValues();

    if (!data.cotaIpva) {
      data.cotaIpva = 1;
    }

    data.placa = data.placa.replace(/-/g, "");
    if (data.placa.length > 7) {
      return setError("placa", { message: "Placa inválida" });
    }

    let ipva = Consultar.SIM;
    let lic = Consultar.SIM;

    if (data.tipoDebito.length > 0) {
      ipva = data.tipoDebito.includes("IPVA") ? Consultar.SIM : Consultar.NAO;
      lic = data.tipoDebito.includes("LICENCIAMENTO") ? Consultar.SIM : Consultar.NAO;
    }

    await solicitarConsulta({
      dados: [
        {
          placa: data.placa,
          renavam: data.renavam,
          uf: data.uf,
        },
      ],
      cotaIpva: data.cotaIpva,
      ipva,
      licenciamento: lic,
      cobrar: data.cobrar,
      empresa: buscarNomeEmpresa(data.empresa),
      idEmpresa: data.empresa,
      motivoIsencao: data.motivoIsencao,
      descricao: data.descricao,
      comCodigoDeBarras: data.comCodigoDeBarras,
    });

    listarConsultasSolicitada();
  }

  const tooltipBottom = -3.8;
  const tooltipWidth = 205;
  const maxHeightListaEmpresas = 250;

  useEffect(() => {
    if (optionsUf && optionsUf.length === 1) {
      setValue("uf", `${optionsUf[0].value}`);
      setUfEscolhida([`${optionsUf[0].value}`]);
      return;
    };
    setValue("uf", "");
    setUfEscolhida([]);
  }, [optionsUf]);

  useEffect(() => {
    if (optionsTipoDebito && optionsTipoDebito.length === 1) {
      optionsTipoDebito[0].checked = true;
      setValue("tipoDebito", [`${optionsTipoDebito[0].value}`]);
      setDisableCota(false);
      setTipoDebitoEscolhido([`${optionsTipoDebito[0].value}` as "LICENCIAMENTO" | "IPVA"]);
      return;
    };
    setValue("tipoDebito", []);
    setTipoDebitoEscolhido(null);
    setDisableCota(true);
  }, [optionsTipoDebito]);

  useEffect(() => {
    if (optionsCotaIpva && optionsCotaIpva.length === 1) {
      setValue("cotaIpva", `${optionsCotaIpva[0].value}`);
      setOpcaoCotaEscolhida(Number(optionsCotaIpva[0].value));
      return;
    };
    setValue("cotaIpva", "");
    setOpcaoCotaEscolhida(null);
  }, [optionsCotaIpva]);


  useEffect(() => {
    if (agrupamentoOptions && agrupamentoOptions.length === 1) {
      setValue("empresa", `${agrupamentoOptions[0].value}`);
      listarConfigServicos(agrupamentoOptions[0].value);
    };
  }, [agrupamentoOptions]);

  const handleCustoConsulta = () => {
    setQtdLinhas(1);
    setLoadingModalValor(false);
  }

  const handleSubmitComCusto = () => {
    setShowModalValor(false);
    setValorConsulta(0);
    setQtdLinhas(0);
    solicitarConsultaUnitaria();
  }

  return (
    <FormContainer>
      <span>Preencha as informações abaixo para realizar a consulta de débitos do veículo.</span>
      <Form
        noValidate
        onSubmit={handleSubmit(() => {
          close();
          if (cobrado) {
            setShowModalValor(true);
            setLoadingModalValor(true);
            setOnConfirmModalValor(() => handleSubmitComCusto);
            handleCustoConsulta();
            return;
          }
          solicitarConsultaUnitaria();
        })}
      >
        <Row className="mt-4">
          <Col md={5}>
            <Form.Group>
              <Form.Label>Descrição</Form.Label>
              <Controller
                name="descricao"
                control={control}
                render={({ field: { onChange } }) => (
                  <CustomInput onChange={onChange} placeholder="Informe a descrição" />
                )}
              />
            </Form.Group>
          </Col>
          <Col>
            <InputsContainer>
              <Form.Group style={{ width: verificarUsuarioAwLw() ? "70%" : "100%" }}>
                <Controller
                  name="empresa"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DropdownSelect
                      onSelect={(e) => {
                        onChange(e);
                        handleChangeEmpresa(e);
                      }}
                      options={agrupamentoOptions}
                      searchable={true}
                      selectedValue={value}
                      title="Empresa"
                      required
                      mensagemErro={errors.empresa?.message}
                      maxHeight={maxHeightListaEmpresas}
                    />
                  )}
                />
              </Form.Group>
              {verificarUsuarioAwLw() && (
                <RadioContainer>
                  <span>Gerar recibo de cobrança</span>
                  <div>
                    <div>
                      <Controller
                        name="cobrar"
                        control={control}
                        render={({ field: { onChange } }) => (
                          <input
                            id="radioSim"
                            type="radio"
                            value={Cobrar.SIM}
                            onChange={(event: any) => {
                              setCobranca("SIM");
                              onChange(event);
                              setCobrado(true);
                            }}
                            checked={cobranca === "SIM"}
                          />
                        )}
                      />
                      <label htmlFor="radioSim">Sim</label>
                    </div>
                    <div>
                      <Controller
                        name="cobrar"
                        control={control}
                        render={({ field: { onChange } }) => (
                          <input
                            id="radioNao"
                            type="radio"
                            value={Cobrar.NAO}
                            onChange={(event: any) => {
                              setCobranca("NAO");
                              onChange(event);
                              setCobrado(false);
                            }}
                            checked={cobranca === "NAO"}
                          />
                        )}
                      />
                      <label htmlFor="radioNao">Não</label>
                    </div>
                  </div>
                  <SpanErro>{errors.cobrar?.message}</SpanErro>
                </RadioContainer>
              )}
            </InputsContainer>
          </Col>
        </Row>
        {cobranca === "NAO" && (
          <Row className="mt-3">
            <Col>
              <Controller
                control={control}
                name="motivoIsencao"
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    onSelect={onChange}
                    options={MotivosIsencaoOptions}
                    selectedValue={value}
                    title="Motivo da isenção da cobrança"
                    mensagemErro={errors.motivoIsencao?.message}
                  />
                )}
              />
            </Col>
          </Row>
        )}
        <Row className="mt-3 mb-4">
          <Col>
            <Form.Group>
              <Controller
                name="tipoDebito"
                control={control as any}
                render={({ field: { onChange, value } }) => (
                  <Multiselect
                    options={optionsTipoDebito}
                    onChange={(e) => {
                      setDisableCota(true);
                      setTipoDebitoEscolhido(e);

                      if ([...e].includes("IPVA")) {
                        setDisableCota(false);
                      }

                      onChange(e);
                    }}
                    title={"Tipo de débito"}
                    value={value ?? []}
                    required
                    mensagemErro={errors.tipoDebito?.message}
                    disabled={optionsTipoDebito && !optionsTipoDebito.length}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col md={2} className="mt-1">
            <Form.Group>
              <Controller
                name="cotaIpva"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    disabled={disableCota}
                    title="Cota IPVA"
                    selectedValue={value}
                    required
                    options={optionsCotaIpva}
                    onSelect={(e) => {
                      onChange(e);
                      setOpcaoCotaEscolhida(e);
                    }}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>
                <InputTitle desativado={optionsUf && !optionsUf.length}>UF</InputTitle> <Required />{" "}
                <Tooltip bottom={tooltipBottom} width={tooltipWidth} trigger={<Question />}>
                  <InfoTooltip
                    mensagem={"UF de emplacamento"}
                  ></InfoTooltip>
                </Tooltip>
              </Form.Label>
              <Controller
                name="uf"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    required
                    selectedValue={value}
                    options={optionsUf}
                    mensagemErro={errors.uf?.message}
                    disabled={optionsUf && !optionsUf.length}
                    onSelect={(e) => {
                      onChange(e);
                      setUfEscolhida([e]);
                    }}
                    maxHeight={270}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>
                Placa <Required />
              </Form.Label>
              <CustomInput
                type="text"
                placeholder="Informe a placa"
                {...register("placa")}
                isValid={isSubmitted && !errors.placa}
                isInvalid={!!errors.placa}
                onChange={mInputPlaca}
                mensagemErro={errors.placa?.message}
                style={{ height: "2.2rem" }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>
                Renavam <Required />
              </Form.Label>
              <CustomInput
                type="text"
                placeholder="Informe o renavam"
                {...register("renavam")}
                isValid={isSubmitted && !errors.placa}
                isInvalid={!!errors.renavam}
                onChange={mInputRenavam}
                mensagemErro={errors.renavam?.message}
                style={{ height: "2.2rem" }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={12}>
            <InfoContainer>
              <Form.Group>
                <Controller
                  name="comCodigoDeBarras"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InfoSwitchContainer>
                      <Switch
                        texto="Buscar código de barras"
                        status={value}
                        setStatus={(event) => onChange(event)}
                      />
                      <div style={{
                        marginLeft: '12px',
                      }}>
                        <AlertaToolTip posicao='bottom' texto="Ative esta opção caso necessite do código de barras para pagamento do débito via arquivo CNAB." />
                      </div>
                    </InfoSwitchContainer>
                  )}
                />
              </Form.Group>
            </InfoContainer>
          </Col>
        </Row>
        <div>
          <Informacao
            type="azul"
            mensagem="Serão listadas na tela as UFs contratadas para o(s) tipo(s) de débito(s) selecionado(s)."
            showBotaoFechar={false}
            showLabel={false}
            style={{ boxShadow: "none", padding: "16px" }}
          />
        </div>
        <div className="mt-4 mb-3 d-flex justify-content-end">
          <PrimaryButton>Consultar</PrimaryButton>
        </div>
      </Form>
    </FormContainer>
  );
}
