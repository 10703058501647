import { Informacao } from "components/Informacao";
import { ContainerAvisoSelecaoManualBanco } from "../../styles";

export function AvisoSelecaoManualBanco() {
  return (
    <ContainerAvisoSelecaoManualBanco>
      <Informacao
        mensagem="Caso o débito seja vinculado a um banco não credenciado ao Detran, o pagamento não será realizado. Nesse caso, você poderá gerar um novo arquivo CNAB selecionando outro banco para pagamento."
        showBotaoFechar={false}
        showLabel={false}
        type="azul"
        className="informacao"
        style={{ boxShadow: "none", margin: "10px auto", width: "100%" }}
        fontSize="14px"
        widthSvg="1rem"
        marginTopSvg="-20px"
      />
    </ContainerAvisoSelecaoManualBanco>
  );
}
