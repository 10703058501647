import styled from "styled-components";

export const ContainerCinza = styled.div`
  padding: 24px 16px;
  background-color: #f5f5f5;
  border-radius: 10px;
`;

export const ContainerCinzaInfoArquivoCnab = styled.div`
  padding: 24px 16px;
  background-color: #f5f5f5;
  border-radius: 10px;
  margin-bottom: 24px;
  display: flex;
`;

export const IconeArquivoContainer = styled.span`
  background-color: ${({ theme }) => theme.colors.project.bgColor};
  padding: 8px;
  border-radius: 5px;

  svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.colors.project.main};
  }
`;

export const DivInfoArquivoCnab = styled.div`
  display: flex;
  alignitems: center;
`;

export const DivInfoArquivoCnabNomeArquivo = styled.div`
  color: ${({ theme }) => theme.colors.neutral.neutral80};
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 8px;
`;

export const DivInfoArquivoCnabDataEnvio = styled.div`
  color: ${({ theme }) => theme.colors.neutral.neutral70};
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 8px;
`;

export const DivInfoArquivoCnabIcones = styled.div``;

interface IContainerInfoArquivoCnabIcon {
  ativo: boolean;
}
export const ContainerInfoArquivoCnabIcone = styled.span<IContainerInfoArquivoCnabIcon>`
  svg {
    width: 14px;
    height: 14px;
    color: ${({ ativo, theme }) =>
      ativo ? theme.colors.neutral.neutral80 : theme.colors.neutral.neutral30};
    ${({ ativo }) => (ativo ? "cursor: pointer" : "")}
  }
`;

export const ContainerListaArquivos = styled.div`
  max-height: 500px;
  overflow: auto;
  margin-top: 24px;
`;

export const DivPesquisarContainer = styled.div`
  margin: 24px 0 8px 0;
  padding: 0 1px;
  display: flex;
  justify-content: space-between;
`;

export const DivPesquisarTitulo = styled.div`
  font-weight: 600;
  height: 32px;
  align-items: center;
  display: flex;
`;

export const DivPesquisarInputContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #999999;
  border-radius: 5px;
  padding: 5px 10px;
  width: 191px;
  height: 32px;
  background-color: white;
  min-height: 33px;

  input {
    width: 150px;
    height: 30px;
    font-size: 14px;
    color: #999999;
    border: none;
    outline: none;
  }

  svg {
    height: 18px;
    color: #999999;
    margin-right: 10px;
  }
`;
