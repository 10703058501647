import { ModalCustom } from "components/ModalCustom";
import { Container, DadosContainer, DivModal, ResumoContainer, TextoNegrito, Titulo } from "./style";
import { TextoClaro } from "components/TextoClaro";
import { Col, Row } from "react-bootstrap";
import { useListarExtratoVeicular } from "features/extrato-veicular/consultar-veiculos/pages/Listar/hooks/useListarExtratoVeicular";
import { useCallback, useEffect, useState } from "react";
import { LoadingCirculo } from "components/LoadingCirculo";
import { converterDataHoraBr } from "_utils/Data";

export function ModalResumoLote({
  idLoteExtratoVeicular,
}: Readonly<{
  idLoteExtratoVeicular: number | null;
}>) {
  const { buscarResumoExtratoVeicular, loading } = useListarExtratoVeicular();
  const [dadosResumo, setDadosResumo] = useState<any>(null);

  const handleBuscarResumo = useCallback(async () => {
    if (!idLoteExtratoVeicular) {
      setDadosResumo(null);
      return;
    }
    const { data, hasErro } = await buscarResumoExtratoVeicular(idLoteExtratoVeicular);

    if (hasErro) {
      return;
    }

    setDadosResumo(data);
  }, [idLoteExtratoVeicular, buscarResumoExtratoVeicular]);

  useEffect(() => {
    if (idLoteExtratoVeicular) {
      handleBuscarResumo();
    }
  }, [handleBuscarResumo, idLoteExtratoVeicular]);

  return (
   <DivModal>
      <Titulo>Extrato veicular</Titulo>
      <Container>
      <TextoClaro>Dados da consulta</TextoClaro>
      {loading ? (
        <LoadingCirculo mostrar={loading} />
      ) : (
        <ResumoContainer>
          <Row>
            <Col>
              <DadosContainer>
                <span>ID Consulta</span>
                <TextoNegrito>{dadosResumo?.id ?? "-"}</TextoNegrito>
              </DadosContainer>
            </Col>
            <Col>
              <DadosContainer>
                <span>Tipo de consulta</span>
                <TextoNegrito>{dadosResumo?.tipoConsulta ?? "-"}</TextoNegrito>
              </DadosContainer>
            </Col>
            <Col>
              <DadosContainer>
                <span>Data / hora solicitação</span>
                <TextoNegrito>{converterDataHoraBr(dadosResumo?.dataHora) ?? "-"}</TextoNegrito>
              </DadosContainer>
            </Col>
          </Row>
          <Row>
            <Col>
              <DadosContainer>
                <span>Solicitante</span>
                <TextoNegrito>{dadosResumo?.nomeEmpresa ?? "-"}</TextoNegrito>
              </DadosContainer>
            </Col>
            <Col>
              <DadosContainer>
                <span>Empresa</span>
                <TextoNegrito>{dadosResumo?.nomeCliente ?? "-"}</TextoNegrito>
              </DadosContainer>
            </Col>
            <Col>
              <DadosContainer>
                <span>Usuário</span>
                <TextoNegrito>{dadosResumo?.nomeUsuario ?? "-"}</TextoNegrito>
              </DadosContainer>
            </Col>
          </Row>
        </ResumoContainer>
      )}
    </Container>
    </DivModal>
  );
}

