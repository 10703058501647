import styled from "styled-components";

interface IContainerProps {
  type: "atencao" | "erro" | "info" | "sucesso" | "branco" | "azul" | "atencaoRedondo";
  mostrar?: boolean;
  showLabel?: boolean;
  marginTopSvg?: string;
  widthSvg?: string;
}

const bgColorStrategy = {
  atencao: "#FFEFCC",
  sucesso: "#DBFFF6",
  erro: "#FFE4E4",
  info: "#E9E9FE",
  branco: "#FFFFFF",
  azul: "#EDF4FF",
  atencaoRedondo: "#FFEFCC",
};

const textColorStrategy = {
  atencao: "#FF9F42",
  sucesso: "#10AC85",
  erro: "#EE5353",
  info: "#2529F4",
  branco: "#FFFFFF",
  azul: "#0D6EFD",
  atencaoRedondo: "#FF9F42",
};

export const Container = styled.div<IContainerProps>`
  background-color: ${({ type }) => bgColorStrategy[type]};
  width: 100%;
  padding: 10px 20px;
  border-radius: 0.5rem;
  display: ${({ mostrar }) => !mostrar && "none !important"};
  display: ${({ showLabel }) => !showLabel && "flex"};
  align-items: center;
  margin-bottom: 2rem;
  box-shadow: 5px 3px 5px 2px rgba(0, 0, 0, 0.15);

  .btnFechar:hover {
    cursor: pointer;
  }
`;

export const TitleContainer = styled.div<IContainerProps>`
  width: ${({ showLabel }) => !showLabel && "5%"};
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.neutral.neutral90};
    display: flex;
    align-items: center;
    padding: 6px 0;

    svg {
      width: ${({ widthSvg }) => widthSvg ?? "1.2rem"};
      height: ${({ widthSvg }) => widthSvg ?? "1.2rem"};
      color: ${({ type }) => textColorStrategy[type]};
      margin-right: 10px;
      ${({ marginTopSvg }) =>
        marginTopSvg ? `margin-top: ${marginTopSvg};` : ""}
    }
  }
`;

interface ITextoContainer {
  fontSize?: string;
}

export const TextoContainer = styled.div<ITextoContainer>`
  ${({ fontSize }) => (fontSize ? `font-size: ${fontSize};` : "")}
  width: 100%;
  color: ${({ theme }) => theme.colors.neutral.neutral70};
`;

TitleContainer.defaultProps = {
  showLabel: true,
};
