import { Informacao } from "components/Informacao";
import { ContainerAvisoCnabFalha } from "../../style";

export function AvisoCnabComFalha() {
  return (
    <ContainerAvisoCnabFalha>
      <Informacao
        mensagem="Falha na geração do arquivo CNAB."
        showBotaoFechar={false}
        showLabel={false}
        type="erro"
        className="informacao"
        style={{ boxShadow: "none", margin: "10px auto", width: "100%" }}
        fontSize="14px"
      />
    </ContainerAvisoCnabFalha>
  );
}
