import { CompositeContainer } from "components/CompositeContainer";
import { useEffect, useRef, useState } from "react";
import { FaTrash, FaUpload } from "react-icons/fa";
import { FaArrowRotateLeft } from "react-icons/fa6";
import { useSolicitacaoUpload } from "../../hooks/useSolicitacaoUpload";
import {
  IconeArquivo,
  ArquivoUpado,
  ContainerArquivosUpados,
  ContainerUpload,
  ContainerUploadDragAndDrop,
  ContainerUploadIcone,
  HeaderArquivoUpado,
  IconesArquivoUpado,
} from "./style";
import { Uploads } from "./types/upload.type";
import { UploadStatus } from "./enum/uploadStatus.enum";
import { Cobrar } from "features/licenciamento/emissao/constants";

interface Props {
  tipoArquivo: string;
  idCliente: string;
  razaoCliente: string;
  cobrar: Cobrar | null;
  motivoIsencao?: string;
  uf: string;
  identificadorLote: string;
  setUploadsComecaram: (status: boolean) => void;
}

export function CompartimentoUpload({
  tipoArquivo,
  idCliente,
  razaoCliente,
  cobrar,
  uf,
  motivoIsencao,
  setUploadsComecaram,
  identificadorLote,
}: Props) {
  const { uparDocumento, registrarContador } = useSolicitacaoUpload();

  const [arquivosUpados, setArquivosUpados] = useState<Uploads[]>([]);

  const arquivosEmProesso = useRef(new Set());

  const abrirDialogoUpload = () => {
    const componenteDeInput = document.getElementById("imgupload");
    if (componenteDeInput) {
      componenteDeInput.click();
    }
  };

  const handleDrag = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleUpload = (arquivos: FileList) => {
    setUploadsComecaram(true);

    if (!razaoCliente || !uf || !tipoArquivo ) return;

    if (cobrar === Cobrar.NAO && !motivoIsencao) return;

    const arrayDeArquivos = Array.from(arquivos);

    const arquivosMapeados: Uploads[] = arrayDeArquivos.map((arquivo) => ({
      arquivo: arquivo,
      status: UploadStatus.PROCESSANDO,
      id: (Date.now() + Math.random()).toString(),
      porcentagemUpload: 0,
      nomeArquivo: arquivo.name,
      descricao: 'Processando',
    }));

    setArquivosUpados((prevArquivos) => {
      return [...prevArquivos, ...arquivosMapeados];
    });
  };

  useEffect(() => {
    const arquivosParaProcessar = [];
    for (const arquivo of arquivosUpados) {
      if (
        arquivosEmProesso.current.has(arquivo.id) ||
        arquivo.status !== UploadStatus.PROCESSANDO
      ) {
        continue;
      }

      arquivosEmProesso.current.add(arquivo.id);
      arquivosParaProcessar.push(arquivo);
    }

    if (!arquivosParaProcessar.length) return;

    realizarDisparoDeArquivos(arquivosParaProcessar);
  }, [arquivosUpados]);

  const realizarDisparoDeArquivos = async (arquivos: Uploads[]) => {
    const [arquivo, ...resto] = arquivos;
    const primeiroItem = await uparArquivo(arquivo);

    const outrosItens = await Promise.all(resto.map((arquivo) => uparArquivo(arquivo)));

    const resultados = [primeiroItem, ...outrosItens];

    for (const resultado of resultados) {
      arquivosEmProesso.current.delete(resultado.id);
    }

    setArquivosUpados((prevArquivos) => {
      return prevArquivos.map((arquivo) => {
        const resultado = resultados.find((resultado) => resultado.id === arquivo.id);
        if (!resultado) return arquivo;

        return {
          ...arquivo,
          nomeArquivo: resultado.status === UploadStatus.CONCLUIDO ? String(resultado.nomeArquivo) : arquivo.nomeArquivo,
          status: resultado.status,
          descricao: resultado.mensagemErro,
          porcentagemUpload: resultado.status === UploadStatus.CONCLUIDO ? 100 : 0,
        };
      });
    });
  
    await registrarContador(identificadorLote);
  }

  const handleResetarArquivo = (id: string) => {
    const arquivosCopiados = [...arquivosUpados];
    const indexAlterado = arquivosCopiados.findIndex(
      (arquivo) => arquivo.id === id
    );
    arquivosCopiados[indexAlterado] = {
      ...arquivosCopiados[indexAlterado],
      status: UploadStatus.PROCESSANDO,
      descricao: "Processando",
      porcentagemUpload: 0,
    };
    setArquivosUpados(arquivosCopiados);

    const arquivo = arquivosCopiados[indexAlterado];

    arquivosEmProesso.current.add(arquivo.id);
    uparArquivo(arquivo).then((resultado) => {
      setArquivosUpados((prevArquivos) => {
        return prevArquivos.map((arquivo) => {
          if (arquivo.id === resultado.id) {
            return {
              ...arquivo,
              status: resultado.status,
              nomeArquivo: resultado.status === UploadStatus.CONCLUIDO ? String(resultado.nomeArquivo) : arquivo.nomeArquivo,
              descricao: resultado.mensagemErro,
              porcentagemUpload: arquivo.status === UploadStatus.CONCLUIDO ? 100 : 0,
            };
          }
          return arquivo;
        });
      });
    });
  }

  async function uparArquivo(arquivo: Uploads): Promise<{
    id: string,
    status: UploadStatus,
    mensagemErro: string,
    nomeArquivo?: string,
  }> {
    try {
      const data = await uparDocumento({
        arquivo: arquivo.arquivo,
        usuario: "",
        handleProgressoDeDownload,
        id: arquivo.id,
        idCliente,
        razaoCliente,
        cobrar: cobrar as Cobrar,
        uf,
        motivoIsencao,
        identificadorLote,
      });

      if (!data.nomeArquivo || !data.sucesso) {
        if (data.status === 400) {
          return {
            id: arquivo.id,
            status: UploadStatus.INVALIDO,
            mensagemErro: String(data.message),
          }
        }

        return {
          id: arquivo.id,
          status: UploadStatus.ERRO,
          mensagemErro: "Falha no upload",
        };
      }

      return {
        nomeArquivo: data.nomeArquivo,
        id: arquivo.id,
        status: UploadStatus.CONCLUIDO,
        mensagemErro: "Concluído",
      }
    } catch (error) {
      return {
        id: arquivo.id,
        status: UploadStatus.ERRO,
        mensagemErro: "Falha no upload",
      };
    }
  }

  const handleProgressoDeDownload = (progress: number, id: string) => {
    const arquviosCopiados = [...arquivosUpados];

    const indexAlterado = arquviosCopiados.findIndex(
      (arquivo) => arquivo.id === id
    );
    const arquivoEspecifico = arquviosCopiados[indexAlterado];
    arquivoEspecifico.porcentagemUpload = progress;
    arquviosCopiados[indexAlterado] = arquivoEspecifico;

    setArquivosUpados(arquviosCopiados);
  };

  const handleUploadDeArquivo = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      handleUpload(event.target.files);
    }
    event.target.value = "";
  };


  const handleCorDoCardPorStatus = (status: string): string => {
    switch (status) {
      case UploadStatus.CONCLUIDO:
        return "#10AC85";
      case UploadStatus.ERRO:
        return "#E41324";
      case UploadStatus.INVALIDO:
        return "#E41324";
      case UploadStatus.PROCESSANDO:
        return "orange";
      default:
        return "gray";
    }
  };

  const handleDescricaoPorStatus = (status: string): string => {
    switch (status) {
      case UploadStatus.CONCLUIDO:
        return "Concluído";
      case UploadStatus.ERRO:
        return "Falha no upload";
      case UploadStatus.INVALIDO:
        return "Arquivo inválido";
      case UploadStatus.PROCESSANDO:
        return "";
      default:
        return "Status desconhecido";
    }
  };

  const validarInferiorEsquerdo = (tamanho: number): number => {
    if (tamanho % 3 === 0) return tamanho - 3;
    if (tamanho % 3 === 1) return tamanho - 1;
    if (tamanho % 3 === 2) return tamanho - 2;
    return 0;
  };

  const validarSuperiorDireito = (tamanho: number): number => {
    if (tamanho > 2) return 2;
    if (tamanho === 1) return 0;
    return 1;
  };

  const handleTamanhoDaBarraDeProgresso = (id: string): number => {
    return Number(
      arquivosUpados.find((arquivo) => arquivo.id === id)?.porcentagemUpload
    );
  };

  const handleExcludeFileFromUpload = (id: string) => {
    const arquivosCopiados = arquivosUpados.filter(
      (arquivo) => arquivo.id !== id
    );
    setArquivosUpados(arquivosCopiados);
  };

  return (
    <CompositeContainer.Root>
      <CompositeContainer.Header>
        <CompositeContainer.Titulo text="Upload de Arquivos" />
      </CompositeContainer.Header>
      <CompositeContainer.Body>
        <ContainerUpload>
          <ContainerUploadDragAndDrop
            onDragOver={handleDrag}
            onDragLeave={handleDrag}
            onDragEnter={handleDrag}
            onDrop={(event) => {
              event.preventDefault();
              const files = event.dataTransfer.files;
              handleUpload(files);
            }}
          >
            <ContainerUploadIcone>
              <FaUpload size={22} color="#009933" />
            </ContainerUploadIcone>

            <div>
              <span
                style={{
                  color: "#009933",
                  cursor: "pointer",
                }}
                onClick={abrirDialogoUpload}
              >
                Clique ou solte aqui
              </span>{" "}
              e faça o upload instantâneo
              <input
                type="file"
                multiple
                id="imgupload"
                style={{
                  display: "none",
                }}
                onChange={handleUploadDeArquivo}
              />
            </div>
            <div>Máx. 5MB | PDF</div>
          </ContainerUploadDragAndDrop>

          <ContainerArquivosUpados>
            {arquivosUpados.map((arquivo, index) => (
              <ArquivoUpado
                esquerdaCima={index === 0}
                esquerdaBaixo={
                  validarInferiorEsquerdo(arquivosUpados.length) === index
                }
                direitaBaixo={index === arquivosUpados.length - 1}
                direitaCima={
                  validarSuperiorDireito(arquivosUpados.length) === index
                }
              >
                <HeaderArquivoUpado>
                  <div>
                    {arquivo.nomeArquivo.length < 35
                      ? arquivo.nomeArquivo
                      : arquivo.nomeArquivo.slice(0, 35).concat("...")}
                  </div>
                  {arquivo.status === UploadStatus.PROCESSANDO && (
                    <div
                      style={{
                        width: "90%",
                        height: "3px",
                        backgroundColor: "gray",
                      }}
                    >
                      <div
                        style={{
                          height: "100%",
                          backgroundColor: "green",
                          width: `${handleTamanhoDaBarraDeProgresso(
                            String(arquivo.id)
                          )}%`,
                        }}
                      />
                    </div>
                  )}
                  <div
                    style={{
                      color: `${handleCorDoCardPorStatus(arquivo.status)}`,
                    }}
                  >
                    {arquivo.descricao}
                  </div>
                </HeaderArquivoUpado>
                <IconesArquivoUpado>
                  <IconeArquivo>
                    {arquivo.status === UploadStatus.ERRO && (
                      <FaArrowRotateLeft
                        onClick={() => handleResetarArquivo(String(arquivo.id))}
                      />
                    )}
                  </IconeArquivo>
                  <IconeArquivo>
                    <FaTrash
                      onClick={() => {
                        handleExcludeFileFromUpload(String(arquivo.id));
                      }}
                    />
                  </IconeArquivo>
                </IconesArquivoUpado>
              </ArquivoUpado>
            ))}
          </ContainerArquivosUpados>
        </ContainerUpload>
      </CompositeContainer.Body>
    </CompositeContainer.Root>
  );
}
