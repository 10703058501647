import { Informacao } from "components/Informacao";
import { ContainerAvisoHaVinculo } from "../../style";

export function AvisoHaVinculo() {
  return (
    <ContainerAvisoHaVinculo>
      <Informacao
        mensagem="Este débito está vinculado em outros arquivos CNAB podendo gerar pagamentos duplicados."
        showBotaoFechar={false}
        showLabel={false}
        type="atencao"
        className="informacao"
        style={{ boxShadow: "none", margin: "10px auto", width: "100%" }}
        fontSize="14px"
      />
    </ContainerAvisoHaVinculo>
  );
}
