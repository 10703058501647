import api from "_services/api";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import {
  IData,
  IHttpError,
  IHttpResponse,
} from "_services/api/interface/HttpResponse";
import { Data } from "_utils/Data";
import { HttpStatusCode } from "axios";
import { toastErro } from "components/Toast";
import {
  StatusSolicitacaoPagamentoEnum,
  StatusSolicitacaoPagamentoEnumV2,
} from "features/debito/consulta/enum/StatusSolicitacaoPagamento";
import { IDetalhesConsulta } from "features/debito/consulta/interfaces/IDetalhesConsulta";
import { useRequest } from "hooks/useRequest";
import { useCallback, useEffect, useState } from "react";
import { IFiltro } from "../Containers/Filtros/Interface/IFiltro";
import { IDadosBancosGerarCnab } from "../Containers/ModalGerarCNAB/Interfaces/IDadosBancosGerarCnab";
import { DadosDebitosJaVinculadosCNAB } from "../Containers/ModalJaExisteCNABVinculado/interfaces/DadosDebitosJaVinculadosCNAB";
import { DadosModalCnabsVinculados } from "../Containers/ModalListaCNABsVinculados/Interfaces/DadosModalCnabsVinculados";
import { dadosCnabsVinculadosDefault } from "../Constants/dadosCnabsVinculadosDefault";
import { DadosVisualizarCnabs } from "../Containers/ModalVisualizarCNABs/Interfaces/DadosVisualizarCnabs";
import { cnabsDaConsultaDefault } from "../Constants/cnabsDaConsultaDefault";
import { DadosInfoCnab } from "../Containers/ModalInfoCNAB/Interfaces/DadosInfoCnab";
import { dadosInfoCnabDefault } from "../Constants/dadosInfoCnabDefault";
import { IDadosModalDebitosCnabsUltimasHoras } from "../Containers/ModalDebitosCnabsUltimasHoras/Interfaces/IDadosModalDebitosCnabsUltimasHoras";
import { dadosModalDebitosCnabsUltimasHorasDefault } from "../Constants/dadosModalDebitosCnabsUltimasHorasDefault";
import { IOptions } from "components/DropdownSelect";

export function useDetalhes() {
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState<IDetalhesConsulta[]>([]);
  const [resumo, setResumo] = useState();
  const [tiposDebitos, setTipoDebitos] = useState([]);
  const [filtro, setFiltro] = useState<IFiltro>();
  const [error, setError] = useState<IHttpResponse<IData | any>>();
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(100);
  const [totalRows, setTotalRows] = useState<number>(0);
  const { patch, get, post, put } = useRequest("consulta");
  const [idCliente, setIdCliente] = useState<number>(0);

  const [loadingDark, setLoadingDark] = useState(false);
  const [dadosBancosGerarCnab, setDadosBancosGerarCnab] = useState<
    IDadosBancosGerarCnab[]
  >([]);
  const [dadosDebitosJaVinculadosCNAB, setDadosDebitosJaVinculadosCNAB] =
    useState<DadosDebitosJaVinculadosCNAB[]>([]);
  const [dadosCnabsVinculados, setDadosCnabsVinculados] =
    useState<DadosModalCnabsVinculados>(dadosCnabsVinculadosDefault);
  const [cnabsDaConsulta, setCnabsDaConsulta] = useState<DadosVisualizarCnabs>(
    cnabsDaConsultaDefault
  );
  const [dadosInfoCnab, setDadosInfoCnab] =
    useState<DadosInfoCnab>(dadosInfoCnabDefault);
  const [
    dadosModalDebitosCnabsUltimasHoras,
    setDadosModalDebitosCnabsUltimasHoras,
  ] = useState<IDadosModalDebitosCnabsUltimasHoras>(
    dadosModalDebitosCnabsUltimasHorasDefault
  );
  const [optionsBanco, setOptionsBanco] = useState<IOptions[]>([]);

  useEffect(() => {
    if (error) {
      if (error.hasErro) return toastErro(`${error.data.message}`);
    }
  }, [error]);

  useEffect(() => {
    listarTiposDebitos();
  }, []);

  const reconsultarDebitos = useCallback(
    async (idConsulta: any) => {
      return patch(`/consultas/${idConsulta}/reconsultar`, {}, {}, false);
    },
    [patch]
  );

  const buscarResumoConsultaV2 = useCallback(
    async (id: any) => {
      try {
        const listaParams = [
          filtro?.placa ? `placa=${filtro.placa}` : null,
          filtro?.idTipoDebito ? `tipoDebito=${filtro.idTipoDebito}` : null,
          filtro?.vencimento ? `vencimento=${filtro.vencimento}` : null,
          filtro?.statusPagamento || filtro?.statusPagamento === 0
            ? `status=${filtro.statusPagamento}`
            : null,
          filtro?.renavam ? `renavam=${filtro.renavam}` : null,
          filtro?.finalPlaca ? `finalPlaca=${filtro.finalPlaca}` : null,
          filtro?.cotaIpva ? `cotaIpva=${filtro.cotaIpva}` : null,
          filtro?.dataInicio && filtro?.dataFim
            ? `dataInicio=${new Data(
                filtro.dataInicio
              ).getDataEua()}&dataFim=${new Data(filtro.dataFim).getDataEua()}`
            : null,
          filtro?.idRecibo ? `idRecibo=${filtro.idRecibo}` : null,
          filtro?.uf ? `uf=${filtro.uf}` : null,
          filtro?.statusCnab ? `statusCnab=${filtro.statusCnab}` : null,
          filtro?.arquivoCnab ? `arquivoCnab=${filtro.arquivoCnab}` : null,
        ];

        const param = listaParams.filter((item) => item !== null).join("&");
        const { status, data, hasErro } = await get(
          `/consultas/${id}/resumoV2?${param}`
        );

        if (hasErro) {
          return;
        }
        setResumo(data);
        setIdCliente(data.idEmpresa);
      } catch (error: IHttpError<IData | any> | any) {
        const response = new HttpError<IData>(true, error);
        if (error.status !== HttpStatusCode.NotFound) {
          setError(response);
        }
        return response;
      } finally {
        setLoading(false);
      }
    },
    [filtro]
  );

  const buscarDetalhes = useCallback(
    async (idConsulta: any) => {
      const listaParams = [
        filtro?.placa ? `placa=${filtro.placa}` : null,
        filtro?.idTipoDebito ? `tipoDebito=${filtro.idTipoDebito}` : null,
        filtro?.vencimento ? `vencimento=${filtro.vencimento}` : null,
        filtro?.statusPagamento || filtro?.statusPagamento === 0
          ? `status=${filtro.statusPagamento}`
          : null,
        filtro?.renavam ? `renavam=${filtro.renavam}` : null,
        filtro?.finalPlaca ? `finalPlaca=${filtro.finalPlaca}` : null,
        filtro?.cotaIpva ? `cotaIpva=${filtro.cotaIpva}` : null,
        filtro?.dataInicio && filtro?.dataFim
          ? `dataInicio=${new Data(
              filtro.dataInicio
            ).getDataEua()}&dataFim=${new Data(filtro.dataFim).getDataEua()}`
          : null,
        filtro?.idRecibo ? `idRecibo=${filtro.idRecibo}` : null,
        filtro?.uf ? `uf=${filtro.uf}` : null,
        filtro?.statusCnab ? `statusCnab=${filtro.statusCnab}` : null,
        filtro?.arquivoCnab ? `arquivoCnab=${filtro.arquivoCnab}` : null,
      ];

      const param = listaParams.filter((item) => item !== null).join("&");

      setLoading(true);
      const { hasErro, data } = await get(
        `consultas/${idConsulta}/detalhes?${param}&limit=${limit}&page=${page}&order=DESC`,
        {},
        false
      );

      if (hasErro) {
        setDados([]);
        setLoading(false);
        return;
      }

      setDados(data.items);
      setTotalRows(data.meta.totalItems);
      setLoading(false);
    },
    [filtro, limit, page, get]
  );

  const buscarValorTodosDebitos = useCallback(
    async (idConsulta: any) => {
      const listaParams = [
        filtro?.placa ? `placa=${filtro.placa}` : null,
        filtro?.idTipoDebito ? `tipoDebito=${filtro.idTipoDebito}` : null,
        filtro?.vencimento ? `vencimento=${filtro.vencimento}` : null,
        filtro?.statusPagamento || filtro?.statusPagamento === 0
          ? `status=${filtro.statusPagamento}`
          : null,
        filtro?.renavam ? `renavam=${filtro.renavam}` : null,
        filtro?.finalPlaca ? `finalPlaca=${filtro.finalPlaca}` : null,
        filtro?.cotaIpva ? `cotaIpva=${filtro.cotaIpva}` : null,
        filtro?.dataInicio && filtro?.dataFim
          ? `dataInicio=${new Data(
              filtro.dataInicio
            ).getDataEua()}&dataFim=${new Data(filtro.dataFim).getDataEua()}`
          : null,
        filtro?.statusCnab ? `statusCnab=${filtro.statusCnab}` : null,
        filtro?.arquivoCnab ? `arquivoCnab=${filtro.arquivoCnab}` : null,
      ];

      const param = listaParams.filter((item) => item !== null).join("&");

      setLoading(true);
      try {
        const { status, data } = await get(
          `consultas/${idConsulta}/detalhes?${param}&paginate=0`,
          {},
          false
        );

        const debitos = data
          .map((item: any) => {
            if (
              Number(item.statusDebito) ===
              StatusSolicitacaoPagamentoEnum["Pendente"]
            ) {
              return item;
            }
            return null;
          })
          .filter((item: any) => item !== null);

        const response = new HttpResponse(false, status, debitos);
        setError(response);
        return response;
      } catch (error: IHttpError<IData, any> | any) {
        const response = new HttpError<IData>(true, error);
        if (response.status !== HttpStatusCode.NotFound) {
          setError(response);
        }
        return response;
      } finally {
        setLoading(false);
      }
    },
    [filtro, get]
  );

  const listarDetalhes = useCallback(
    async (idConsulta: any, paginate: boolean = true) => {
      const listaParams = [
        filtro?.placa ? `placa=${filtro.placa}` : null,
        filtro?.idTipoDebito ? `tipoDebito=${filtro.idTipoDebito}` : null,
        filtro?.vencimento ? `vencimento=${filtro.vencimento}` : null,
        filtro?.statusPagamento || filtro?.statusPagamento === 0
          ? `status=${filtro.statusPagamento}`
          : null,
        filtro?.renavam ? `renavam=${filtro.renavam}` : null,
        filtro?.finalPlaca ? `finalPlaca=${filtro.finalPlaca}` : null,
        filtro?.cotaIpva ? `cotaIpva=${filtro.cotaIpva}` : null,
        filtro?.dataInicio && filtro?.dataFim
          ? `dataInicio=${new Data(
              filtro.dataInicio
            ).getDataEua()}&dataFim=${new Data(filtro.dataFim).getDataEua()}`
          : null,
      ];

      const param = listaParams.filter((item) => item !== null).join("&");

      setLoading(true);
      try {
        const { status, data } = await get(
          `consultas/${idConsulta}/detalhes?${param}${
            !paginate ? "&paginate=0" : ""
          }`,
          {},
          false
        );

        const response = new HttpResponse(false, status, data);

        return response;
      } catch (error: IHttpError<IData, any> | any) {
        const response = new HttpError<IData>(true, error);

        return response;
      } finally {
        setLoading(false);
      }
    },
    [filtro, get]
  );

  async function listarTiposDebitos() {
    try {
      const { status, data } = await api.get("/debito/tipos");
      setTipoDebitos(data);
      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      if (response.status !== HttpStatusCode.NotFound) {
        setError(response);
      }
      return response;
    }
  }

  async function buscarTodosDebitosConsulta(idConsulta: string) {
    setLoading(true);
    try {
      const listaParams = [
        filtro?.placa ? `placa=${filtro.placa}` : null,
        filtro?.idTipoDebito ? `tipoDebito=${filtro.idTipoDebito}` : null,
        filtro?.vencimento ? `vencimento=${filtro.vencimento}` : null,
        filtro?.statusPagamento || filtro?.statusPagamento === 0
          ? `status=${filtro.statusPagamento}`
          : null,
        filtro?.renavam ? `renavam=${filtro.renavam}` : null,
        filtro?.finalPlaca ? `finalPlaca=${filtro.finalPlaca}` : null,
        filtro?.cotaIpva ? `cotaIpva=${filtro.cotaIpva}` : null,
        filtro?.dataInicio && filtro?.dataFim
          ? `dataInicio=${new Data(
              filtro.dataInicio
            ).getDataEua()}&dataFim=${new Data(filtro.dataFim).getDataEua()}`
          : null,
        filtro?.statusCnab ? `statusCnab=${filtro.statusCnab}` : null,
        filtro?.arquivoCnab ? `arquivoCnab=${filtro.arquivoCnab}` : null,
      ];
      const param = listaParams.filter((item) => item !== null).join("&");

      const { status, data } = await get(
        `consultas/${idConsulta}/debitos/disponiveis?${param}`,
        {},
        false
      );
      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      if (response.status !== HttpStatusCode.NotFound) {
        setError(response);
      }
      return response;
    } finally {
      setLoading(false);
    }
  }

  const getDadosModalDebitosCnabsUltimasHoras = async (
    idsDebitos: number[],
    idConsulta: any
  ) => {
    setLoadingDark(true);
    const listaParams = {
      placa: filtro?.placa ? filtro.placa : null,
      tipoDebito: filtro?.idTipoDebito ? filtro.idTipoDebito : null,
      vencimento: filtro?.vencimento ? filtro.vencimento : null,
      renavam: filtro?.renavam ? filtro.renavam : null,
      finalPlaca: filtro?.finalPlaca ? filtro.finalPlaca : null,
      cotaIpva: filtro?.cotaIpva ? filtro.cotaIpva : null,
      dataInicio:
        filtro?.dataInicio && filtro?.dataFim
          ? new Data(filtro.dataInicio).getDataEua()
          : null,
      dataFim:
        filtro?.dataInicio && filtro?.dataFim
          ? new Data(filtro.dataFim).getDataEua()
          : null,
      statusCnab: filtro?.statusCnab ? filtro.statusCnab : null,
      arquivoCnab: filtro?.arquivoCnab ? filtro.arquivoCnab : null,
      idConsulta,
      idsDebitos,
    };

    const { status, data, hasErro } = await post(`/cnab/validarDuplicidade`, {
      ...listaParams,
    });
    const response = new HttpResponse(hasErro, status, data);

    if (hasErro) {
      setLoadingDark(false);
      setDadosModalDebitosCnabsUltimasHoras(
        dadosModalDebitosCnabsUltimasHorasDefault
      );
      setDadosDebitosJaVinculadosCNAB([]);
      return response;
    }

    setDadosModalDebitosCnabsUltimasHoras({
      totalDebitos:
        (data as any).quantidadeDisponiveis +
        (data as any).quantidadeDuplicados,
      quantidadeDuplicados: (data as any).quantidadeDuplicados,
    });
    setDadosDebitosJaVinculadosCNAB((data as any).debitos);
    return response;
  };

  const gerarCNAB = async (
    idsDebitos: number[],
    idConsulta: any,
    enviarArquivoCnabPara: string[],
    bancoSelecionado: string | number
  ) => {
    setLoadingDark(true);
    const listaParams = {
      placa: filtro?.placa ? filtro.placa : null,
      tipoDebito: filtro?.idTipoDebito ? filtro.idTipoDebito : null,
      vencimento: filtro?.vencimento ? filtro.vencimento : null,
      renavam: filtro?.renavam ? filtro.renavam : null,
      finalPlaca: filtro?.finalPlaca ? filtro.finalPlaca : null,
      cotaIpva: filtro?.cotaIpva ? filtro.cotaIpva : null,
      dataInicio:
        filtro?.dataInicio && filtro?.dataFim
          ? new Data(filtro.dataInicio).getDataEua()
          : null,
      dataFim:
        filtro?.dataInicio && filtro?.dataFim
          ? new Data(filtro.dataFim).getDataEua()
          : null,
      statusCnab: filtro?.statusCnab ? filtro.statusCnab : null,
      arquivoCnab: filtro?.arquivoCnab ? filtro.arquivoCnab : null,
      emails: enviarArquivoCnabPara,
      codigoBanco: bancoSelecionado ? bancoSelecionado : null,
    };

    const { status, data, hasErro } = await post(`/cnab`, {
      ...listaParams,
      idsDebitos,
      idConsulta,
    });
    const response = new HttpResponse(hasErro, status, data);
    setLoadingDark(false);
    return response;
  };

  const getDadosBancosGerarCNAB = async (
    idConsulta: string | number,
    idsDebitos: number[] | null
  ) => {
    setLoadingDark(true);
    const listaParams = {
      placa: filtro?.placa ? filtro.placa : null,
      tipoDebito: filtro?.idTipoDebito ? filtro.idTipoDebito : null,
      vencimento: filtro?.vencimento ? filtro.vencimento : null,
      renavam: filtro?.renavam ? filtro.renavam : null,
      finalPlaca: filtro?.finalPlaca ? filtro.finalPlaca : null,
      cotaIpva: filtro?.cotaIpva ? filtro.cotaIpva : null,
      dataInicio:
        filtro?.dataInicio && filtro?.dataFim
          ? new Data(filtro.dataInicio).getDataEua()
          : null,
      dataFim:
        filtro?.dataInicio && filtro?.dataFim
          ? new Data(filtro.dataFim).getDataEua()
          : null,
      statusCnab: filtro?.statusCnab ? filtro.statusCnab : null,
      arquivoCnab: filtro?.arquivoCnab ? filtro.arquivoCnab : null,
      idConsulta,
      idsDebitos,
    };
    const { status, data, hasErro } = await post(`/cnab/associarDebitos`, {
      ...listaParams,
    });
    const response = new HttpResponse(hasErro, status, data);
    setLoadingDark(false);

    if (hasErro) {
      setDadosBancosGerarCnab([]);
      return response;
    }

    setDadosBancosGerarCnab(
      (data as any[]).map((banco) => {
        return { ...banco, visivel: true };
      })
    );
    return response;
  };

  const getDadosBancosEscolhaManual = async (idCliente: string | number) => {
    setLoadingDark(true);
    const { status, data, hasErro } = await get(
      `/cnab/bancosDisponiveis/${idCliente}`
    );
    const response = new HttpResponse(hasErro, status, data);

    if (hasErro) {
      setOptionsBanco([]);
      setLoadingDark(false);
      return response;
    }

    setOptionsBanco(
      data.map((item: any) => {
        return { value: item.codigoBanco, label: item.nomeBanco };
      })
    );
    return response;
  };

  const getDadosCnabsVinculadosAoDebito = async (idDebito: number) => {
    setLoadingDark(true);
    const { status, data, hasErro } = await get(`/cnab/debito/${idDebito}`);
    const response = new HttpResponse(hasErro, status, data);
    setLoadingDark(false);

    if (hasErro) {
      setDadosCnabsVinculados(dadosCnabsVinculadosDefault);
      return response;
    }
    setDadosCnabsVinculados(data);
    return response;
  };

  const getCnabsPorConsulta = async (
    idConsulta: number | string,
    pesquisa?: string
  ) => {
    setLoadingDark(true);
    const listaParams = {
      placa: filtro?.placa ? filtro.placa : null,
      tipoDebito: filtro?.idTipoDebito ? filtro.idTipoDebito : null,
      vencimento: filtro?.vencimento ? filtro.vencimento : null,
      renavam: filtro?.renavam ? filtro.renavam : null,
      finalPlaca: filtro?.finalPlaca ? filtro.finalPlaca : null,
      cotaIpva: filtro?.cotaIpva ? filtro.cotaIpva : null,
      dataInicio:
        filtro?.dataInicio && filtro?.dataFim
          ? new Data(filtro.dataInicio).getDataEua()
          : null,
      dataFim:
        filtro?.dataInicio && filtro?.dataFim
          ? new Data(filtro.dataFim).getDataEua()
          : null,
      statusCnab: filtro?.statusCnab ? filtro.statusCnab : null,
      arquivoCnab: filtro?.arquivoCnab ? filtro.arquivoCnab : null,
    };

    const { status, data, hasErro } = await post(`/cnab/listar`, {
      ...listaParams,
      idConsulta,
      pesquisa,
    });
    const response = new HttpResponse(hasErro, status, data);
    setLoadingDark(false);

    if (hasErro) {
      setCnabsDaConsulta(cnabsDaConsultaDefault);
      return response;
    }

    setCnabsDaConsulta({
      ...(data as any),
      qtdDebitosDisponiveis: (resumo as any).qtdDebitosDisponiveis,
      debitosVinculados: (resumo as any).quantidadeDebitosCnab,
    });
    return response;
  };

  const getInfoCnab = async (idDebito: number) => {
    setLoadingDark(true);
    const { status, data, hasErro } = await get(`/cnab/debito/${idDebito}`);
    const response = new HttpResponse(hasErro, status, data);
    setLoadingDark(false);

    if (hasErro) {
      setDadosInfoCnab(dadosInfoCnabDefault);
      return response;
    }
    setDadosInfoCnab(data);
    return response;
  };

  const reenviarEmailCnab = async (idCnab: number) => {
    setLoadingDark(true);
    const { status, data, hasErro } = await post(`/cnab/reenviarEmails`, {
      idsLotes: [idCnab],
    });
    const response = new HttpResponse(hasErro, status, data);
    setLoadingDark(false);
    return response;
  };

  const reprocessarArquivoCnab = async (idCnab: number) => {
    setLoadingDark(true);
    const { status, data, hasErro } = await put(
      `/cnab/reprocessar/${idCnab}`,
      {}
    );
    const response = new HttpResponse(hasErro, status, data);
    setLoadingDark(false);
    return response;
  };

  return {
    dados,
    tiposDebitos,
    loading,
    filtro,
    setFiltro,
    buscarDetalhes,
    setLimit,
    setLoading,
    buscarValorTodosDebitos,
    limit,
    setPage,
    page,
    totalRows,
    buscarTodosDebitosConsulta,
    reconsultarDebitos,
    buscarResumoConsultaV2,
    resumo,
    idCliente,
    listarDetalhes,
    gerarCNAB,
    loadingDark,
    dadosBancosGerarCnab,
    setDadosBancosGerarCnab,
    getDadosBancosGerarCNAB,
    dadosDebitosJaVinculadosCNAB,
    setDadosDebitosJaVinculadosCNAB,
    getDadosCnabsVinculadosAoDebito,
    dadosCnabsVinculados,
    setDadosCnabsVinculados,
    getCnabsPorConsulta,
    cnabsDaConsulta,
    setCnabsDaConsulta,
    dadosInfoCnab,
    setDadosInfoCnab,
    getInfoCnab,
    reenviarEmailCnab,
    reprocessarArquivoCnab,
    dadosModalDebitosCnabsUltimasHoras,
    setDadosModalDebitosCnabsUltimasHoras,
    getDadosModalDebitosCnabsUltimasHoras,
    getDadosBancosEscolhaManual,
    optionsBanco,
    setOptionsBanco,
    setLoadingDark,
    setDados
  };
}
