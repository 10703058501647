import styled from "styled-components";

export const ComponenteRelatorioContainer = styled.div`
    width: 100%;
    min-height: 40px;
    display: flex;
    align-items: center;
    padding: 5px 0;
    line-height: 0;
`;

export const ComponenteComprovantesContainer = styled.div`
    width: 100%;
    display: flex;
    min-height: 40px;
    align-items: center;
    padding: 5px 0;
    line-height: 0;
`

export const SpanDescricao = styled.span`
    color: #7F7F7F;
    margin-left: 2px;
`