import { Informacao } from "components/Informacao";
import { ContainerAvisoCnabLote } from "../../styles";

export function AvisoNaoHaBancoGerarCnab() {
  return (
    <ContainerAvisoCnabLote>
      <Informacao
        mensagem="Não há bancos cadastrados para este cliente. Realize o cadastro do(s) banco(s) para gerar arquivos CNAB para pagamento."
        showBotaoFechar={false}
        showLabel={false}
        type="erro"
        className="informacao"
        style={{ boxShadow: "none", margin: "10px auto", width: "100%" }}
        fontSize="14px"
      />
    </ContainerAvisoCnabLote>
  );
}
