import { useRequest } from "hooks/useRequest"
import { IRequisicaoEmissaoCliente } from "../interfaces/IRequisicaoEmissaoCliente";
import { toastErroCustom } from "components/Toast";
import BotaoFecharClaro from "components/Toast/Botoes/BotaoFecharClaro";
import ToastErroCrlv from "components/Toast/Toasts/ToastErroCrlv";


export function useDownloadCrlvs() {
    const { postWithCustomToast } = useRequest("consulta");

    const downloadCrlvs = async (valoresForm: IRequisicaoEmissaoCliente, captcha: string) => {
        const { data, hasErro } = await postWithCustomToast("/licenciamento/externo/download", 
            { ...valoresForm, captcha }, {});

        if (hasErro) {
            toastErroCustom(ToastErroCrlv, BotaoFecharClaro);
        }

        const response: any = data;
        return { response, hasErro };
    }

    return {
        downloadCrlvs,
    }
}