import styled from "styled-components";

export const ContainerAvisoPodeGerarPgtosDuplicados = styled.div`
  margin-bottom: 24px;
`;

export const ContainerCinza = styled.div`
  padding: 24px 16px;
  background-color: #f5f5f5;
  border-radius: 10px;
`;

export const TituloInfoCnabsVinculados = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
  margin-top: 24px;
`;

export const ContainerTabelaCnabsVinculados = styled.div`
  padding: 16px 16px 16px 8px;
`;