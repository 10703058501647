import { ModalCustom } from "components/ModalCustom";
import { FaCheck, FaExclamation } from "react-icons/fa";
import { Container, IconeContainer, Texto, TextoContainer } from "./style";
import { useRequest } from "hooks/useRequest";
import { useState } from "react";

interface IModalConfirmacaoPagamentoManual {
  show: boolean;
  handleClose: () => void;
  onConfirm: () => void;
  dataPagamento: Date | null;
  idsSelecionados: string[];
  codigoBanco: string;
  handleClearRows(): void;
  dados: any[];
}

export function ModalConfirmacaoPagamentoManualLote({
  show,
  handleClose,
  onConfirm,
  dataPagamento,
  idsSelecionados,
  codigoBanco,
  handleClearRows,
  dados,
}: IModalConfirmacaoPagamentoManual) {
  const { post } = useRequest("consulta");
  const [loading, setLoading] = useState<boolean>(false);

  async function handleEnviarPagamentoManual() {
    setLoading(true);
    const { hasErro } = await post(
      '/pagamento/debito/manual/lote', {
        ids: idsSelecionados,
        dataPagamento,
        codigoBanco,
      }
    );
    setLoading(false);

    if (!hasErro) {
      for (const id of idsSelecionados) {
        const indexLinha = dados.findIndex((item) => item.id === id);
        dados[indexLinha].status = "6";
        dados[indexLinha].selectable = false;
      }

      handleClearRows();
      onConfirm();
    }
  }

  return (
    <ModalCustom
      footer
      footerConfirmButton
      confirmButtonTitle="Confirmar"
      disabledConfirmButton={loading}
      onConfirm={() => {
        handleEnviarPagamentoManual();
      }}
      show={show}
      handleClose={handleClose}
      title="Pagamento manual"
      centered={true}
    >
      <Container>
        <IconeContainer>
          <FaExclamation color="#FF9F42"/>
        </IconeContainer>
        <TextoContainer>
          <Texto>Deseja confirmar o pagamento dos débitos selecionados?</Texto>
        </TextoContainer>
      </Container>
    </ModalCustom>
  );
}
